import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Article } from '../../../models/article';
import { first } from 'rxjs/operators';
import Utils from '../../../shared/utils';
import { ApiService } from '../../../shared/services/api/api.service';

interface Image {
  src?: string;
  caption?: string;
}

@Component({
  selector: 'app-image-carousel-smart',
  templateUrl: './image-carousel-smart.component.html',
  styleUrls: ['./image-carousel-smart.component.scss']
})
export class ImageCarouselSmartComponent implements OnInit, OnDestroy {
  @Input() article!: Article;
  @Input() guestDisplay = false;

  images: Array<Image> = [];
  articleHtml?: string;
  zoom = false;

  constructor(
    private apiService: ApiService,
    private utils: Utils
  ) {
  }

  ngOnInit(): void {
    this.fetchData().then();
  }

  ngOnDestroy(): void {
    document.body.style.overflow = 'auto';
  }

  async retrieveArticleHtml(): Promise<string|undefined> {
    if (this.article.html?.htmlKey && this.article.html.nbImages) {
      try {
        return await this.apiService.article.getArticleHtml(this.article.html.htmlKey).pipe(first()).toPromise();
      } catch (e) {
        console.log('error retrieving html of article');
        return;
      }
    }
    return;
  }

  async fetchData() {
    this.articleHtml = await this.retrieveArticleHtml();
    if (this.articleHtml) {
      this.images = this.utils.parseImages(this.articleHtml);
    }
  }

  handleZoom(value:boolean) {
    this.zoom = value;
    if (this.zoom) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }

}
