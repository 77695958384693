<app-header></app-header>

<div class="container-fluid topic-container pt-0 ">
  <div class="row w-100 filtered-ar">
    <div class="col-10 text-uppercase d-flex align-items-center">
      <div class="label-filtered me-3">{{'alert.search' | translate}}</div>
      <div>
        <span class="filtered-input-container">
          <input [formControl]="filterTerritoryWatchNameFormControl" type="text" placeholder="{{'alert.alert-name' | translate}}"
                 class="filtered-input">
          <img (click)="clearText()" *ngIf="showClearIcon" src="assets/images/close-circle.svg" alt="close"
               class="cursor-pointer">
        </span>
      </div>
    </div>
  </div>

  <div class="container-fluid territory-watches-container">
    <div class="row">
      <div class="territory-watches-container col-12 ">
        <app-list-template
          [territoryWatches]="filteredTerritoryWatches"
          [alertMode]="true"
          [sortTextBoolean]="false"
          (itemRenamed)="renameTerritoryWatch($event)"
          (itemDeleted)="deleteTerritoryWatch($event)"
          (alertActiveChanged)="onSelectChange($event)">
        </app-list-template>
      </div>
    </div>
    <app-toast-message
      createMessage="alert.toast-content"
      editMessage="alert.toast-edit-content"
      duplicationMessage="alert.toast-duplication-content"
    ></app-toast-message>
  </div>
</div>
