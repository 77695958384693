<div *ngIf="!resetSuccess" class="container-fluid">
  <div class="row">
    <div class="col-sm-6 left-side"></div>
    <div class="col-sm-6 right-side d-flex">
      <div class="content">
        <div class="fw-bold title">{{"new-password.title" | translate }}</div>
        <div class="sub-title pb-4">{{"new-password.sub-title"  | translate}}</div>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label for="password"
                   class="login-label-title fw-bold"> {{"new-password.enter-new-password" | translate | uppercase}}</label>

            <div class="input-group">
              <input id="password" [type]="fieldTextType1 ? 'text' : 'password'" formControlName="password"
                     class="form-control"
                     [ngClass]="{ 'is-invalid': submitted && f['password'].errors }"/>
              <div class="input-group-append-hide-show">
              <span *ngIf="fieldTextType1" (click)="toggleFieldTextType1()" class="hide-show">
                <img class="hide-show-icon"
                     src="assets/images/hide-password-icon.svg" alt="hide-password">
              </span>
                <span *ngIf="!fieldTextType1" (click)="toggleFieldTextType1()" class="hide-show">
                <img class="hide-show-icon"
                     src="assets/images/show-password-icon.svg" alt="show-password">
              </span>
              </div>
            </div>

            <!-- case with errors -->
            <div *ngIf="f['password'].errors" class="password-validators">

              <div class="validator">
                <div *ngIf="f['password'].errors?.['minlength'] || !f['password'].value"><img
                  src="assets/images/icon-radio-button-off.svg" alt="icon-radio-button-off"></div>
                <div *ngIf="!f['password'].errors?.['minlength'] && f['password'].value"><img
                  src="assets/images/icon-green-checkmark-circle.svg" alt="icon-radio-button-on"></div>
                <div
                  [ngClass]="{ 'validator-text': true, 'validator-is-valid': !f['password'].errors?.['minlength'] && f['password'].value}">
                  {{'new-password.validator1' | translate}}</div>
              </div>
              <div class="validator">
                <div *ngIf="f['password'].errors?.['hasSpecialCharacters'] || !f['password'].value"><img
                  src="assets/images/icon-radio-button-off.svg" alt="icon-radio-button-off"></div>
                <div *ngIf="!f['password'].errors?.['hasSpecialCharacters'] && f['password'].value"><img
                  src="assets/images/icon-green-checkmark-circle.svg" alt="icon-radio-button-on"></div>
                <div
                  [ngClass]="{ 'validator-text': true, 'validator-is-valid': !f['password'].errors?.['hasSpecialCharacters'] && f['password'].value}">
                  {{'new-password.validator2' | translate}}</div>
              </div>
              <div class="validator">
                <div *ngIf="f['password'].errors?.['hasSmallCase'] || !f['password'].value"><img
                  src="assets/images/icon-radio-button-off.svg" alt="icon-radio-button-off"></div>
                <div *ngIf="!f['password'].errors?.['hasSmallCase'] && f['password'].value"><img
                  src="assets/images/icon-green-checkmark-circle.svg" alt="icon-radio-button-on"></div>
                <div
                  [ngClass]="{ 'validator-text': true, 'validator-is-valid': !f['password'].errors?.['hasSmallCase'] && f['password'].value}">
                  {{'new-password.validator3' | translate}}</div>
              </div>
              <div class="validator">
                <div *ngIf="f['password'].errors?.['hasNumber'] || !f['password'].value"><img
                  src="assets/images/icon-radio-button-off.svg" alt="icon-radio-button-off"></div>
                <div *ngIf="!f['password'].errors?.['hasNumber'] && f['password'].value"><img
                  src="assets/images/icon-green-checkmark-circle.svg" alt="icon-radio-button-on"></div>
                <div
                  [ngClass]="{ 'validator-text': true, 'validator-is-valid': !f['password'].errors?.['hasNumber'] && f['password'].value }">
                  {{'new-password.validator4' | translate}}</div>
              </div>
              <div class="validator">
                <div *ngIf="f['password'].errors?.['hasCapitalCase'] || !f['password'].value"><img
                  src="assets/images/icon-radio-button-off.svg" alt="icon-radio-button-off"></div>
                <div *ngIf="!f['password'].errors?.['hasCapitalCase'] && f['password'].value"><img
                  src="assets/images/icon-green-checkmark-circle.svg" alt="icon-radio-button-on"></div>
                <div
                  [ngClass]="{ 'validator-text': true, 'validator-is-valid': !f['password'].errors?.['hasCapitalCase'] && f['password'].value}">
                  {{'new-password.validator5' | translate}}</div>
              </div>

            </div>

            <!-- case without errors (Else there an issue because f['password'].erros = null -->
            <div *ngIf="!f['password'].errors" class="password-validators">

              <div class="validator">
                <div><img src="assets/images/icon-green-checkmark-circle.svg" alt="check-ok"></div>
                <div class="validator-text validator-is-valid">
                  {{'new-password.validator1' | translate}}</div>
              </div>
              <div class="validator">
                <div><img src="assets/images/icon-green-checkmark-circle.svg" alt="check-ok"></div>
                <div class="validator-text validator-is-valid">
                  {{'new-password.validator2' | translate}}</div>
              </div>
              <div class="validator">
                <div><img src="assets/images/icon-green-checkmark-circle.svg" alt="check-ok"></div>
                <div class="validator-text validator-is-valid">
                  {{'new-password.validator3' | translate}}</div>
              </div>
              <div class="validator">
                <div><img src="assets/images/icon-green-checkmark-circle.svg" alt="check-ok"></div>
                <div class="validator-text validator-is-valid">
                  {{'new-password.validator4' | translate}}</div>
              </div>
              <div class="validator">
                <div><img src="assets/images/icon-green-checkmark-circle.svg" alt="check-ok"></div>
                <div class="validator-text validator-is-valid">
                  {{'new-password.validator5' | translate}}</div>
              </div>

            </div>

          </div>
          <div class="form-group">
            <label for="confirmPassword"
                   class="login-label-title fw-bold"> {{"new-password.confirm-new-password" | translate | uppercase}}</label>

            <div class="input-group">
              <input appClickOutside id="confirmPassword" [type]="fieldTextType2 ? 'text' : 'password'" formControlName="confirmPassword" class="form-control"
                     [ngClass]="{ 'is-invalid': confirmationPasswordIsWrong() && userLeftInputConfirmPassword }"
                     (blur)="userLeftInputConfirmPassword = true"
                     (focus)="userLeftInputConfirmPassword = false"
                     (clickOutside)="userLeftInputConfirmPassword = true"

              />
              <div class="input-group-append-hide-show">
                <span *ngIf="fieldTextType2" (click)="toggleFieldTextType2()" class="hide-show">
                  <img class="hide-show-icon"
                       alt="icon-hide"
                       src="assets/images/hide-password-icon.svg">
                </span>
                  <span *ngIf="!fieldTextType2" (click)="toggleFieldTextType2()" class="hide-show">
                  <img class="hide-show-icon"
                       alt="icon-show"
                       src="assets/images/show-password-icon.svg">
                </span>
              </div>
            </div>
            <div *ngIf="confirmationPasswordIsWrong() && userLeftInputConfirmPassword" class="error-div">
              <div class="no-match-error-message">
                <img class="no-match-icon" alt="alert-icon" src="assets/images/icon-alert-triangle.svg"/>
                <div class="no-match-text">{{ 'update-password.invalid-confirmation-password' | translate }} </div>
              </div>
            </div>
          </div>

          <div class="login-btn-container form-group">
            <button [disabled]="loading "
                    class="fw-bold btn btn-login">{{'new-password.reset' | translate | uppercase}}</button>
            <div [routerLink]="['/login']"
                 class="btn btn-password-forgotten">{{'new-password.back-to-login' | translate}}</div>
          </div>

        </form>
      </div>
      <img class="logo" alt="explain-banner" src="assets/images/banner-explain.png"/>
    </div>
  </div>
</div>

<!--Partie dans le cas d'une réussite de changement de mot de passe-->
<app-reset-password-success *ngIf="resetSuccess"></app-reset-password-success>


