import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { AutoUnsubscribe } from '../../common-explain/decorators/auto-unsubscribe';
import { filter, map } from "rxjs/operators";
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
@AutoUnsubscribe
export class MenuComponent implements OnInit {

  public displayPersonalSettings: boolean;
  isTendersPage = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
  ) {
    this.displayPersonalSettings = false;
  }

  ngOnInit() {
    /** Sera utile pour masque les liens à gauche lorsqu'on le souhaitera */
    this.isTendersPage = this.router.url.includes('/tenders');
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd),
        map(() => {
          let routeToSubscribe = this.route.snapshot;
          while (routeToSubscribe.firstChild) {
            routeToSubscribe = routeToSubscribe.firstChild;
          }
          return routeToSubscribe;
        })
      )
      .subscribe(() => {
        this.isTendersPage = this.router.url.includes('/tenders');
      });
  }

  onLogOut() {
    this.authenticationService.logout();
    this.router.navigate(['/login']).then();
    this.displayPersonalSettings = false;
  }
}
