import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { Folder } from '../../models/folder';
import { ApiService } from '../../shared/services/api/api.service';

@Component({
  selector: 'app-modal-creation',
  templateUrl: './modal-creation.component.html',
  styleUrls: ['./modal-creation.component.scss']
})
export class ModalCreationComponent implements OnInit, AfterViewInit {
  @Input() translationFileSection!: string;
  @ViewChild('checkIfExistingInput') checkIfExistingInput!: ElementRef;

  newItemNameForm = new FormControl('',
    [Validators.required,
      Validators.minLength(1),
      Validators.maxLength(40)]
  );
  submitted = false;
  displayAlreadyExists = false;
  displayNameNotAllowed = false;

  constructor(
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private apiService: ApiService
  ) {
  }

  ngOnInit(): void {
    this.newItemNameForm.valueChanges.subscribe(_ => {
      if (this.displayAlreadyExists) {
        this.displayAlreadyExists = false;
      }
      if (this.displayNameNotAllowed) {
        this.displayNameNotAllowed = false;
      }
    });
  }

  ngAfterViewInit() {
    this.checkIfExistingInput.nativeElement.focus();
  }

  async createItem() {
    try {
      this.submitted = true;
      const name = this.f.value?.trim() || '';
      const response = await this.apiService.bookmark.createBookmarkFolder(name).pipe(first()).toPromise();
      if(response){
        this.activeModal.close(
          new Folder({
            bookmark_entity_id: response.id,
            entity_label: name,
            documents_count: 0,
            admin_docs_count: 0,
            articles_count: 0,
            last_modified: new Date()
          }));
      }
    } catch (e) {
      if (e === 'Entity name not allowed!') {
        this.displayNameNotAllowed = true;
      }
      if (e === 'Entity already exist!') {
        this.displayAlreadyExists = true;
      } else {
        console.log('@modal-creation.component.ts error creating item', e);
      }
    }
  }

  dismissModal() {
    this.activeModal.dismiss();
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.newItemNameForm;
  }
}
