import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FunnelContextEnum, StepsService, StyleLists } from '../../shared/services/steps.service';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { StepModel } from '../../core/step/step.model';
import { DbTerritoryWatchWithUsers } from '../../models/territory-watch';

@Component({
  selector: 'app-step-header',
  templateUrl: './step-header.component.html',
  styleUrls: ['./step-header.component.scss']
})
export class StepHeaderComponent implements OnInit, OnDestroy {
  @Input() stepTitle?: string;
  @Input() step?: StepModel;
  @Input() stepSubTitle?: string;
  @Input() stepSubTitleEmptySelection?: string;
  @Input() alertId?: string;
  @Input() content?: number;
  @Input() myClass?: string;
  @Input() warning?: boolean;
  @Input() error?: boolean;

  @Input() valueName = '';
  @Input() maxSelectionValue = 0;
  @Input() currentSelectionValue = 0;
  @Input() selectionComplexityLimitationValue? = 0;
  @Input() otherUserAlerts: DbTerritoryWatchWithUsers[] = [];
  public progressBarWidth = 160;
  styleLists: StyleLists;
  validationButtonText = 'button.create-alert';
  subscription = new Subscription();
  @Input() awaitingSubmission = false;

  constructor(private stepsService: StepsService) {
    this.styleLists = new StyleLists();
  }

  ngOnInit(): void {
    // Management of text displayed on validation button.
    this.attributeValidationButtonText();

    // Custom management of the progressbar width.
    this.subscription.add(
      this.stepsService.getCurrentStep$()
      .subscribe(step => {
        if (step.stepIndex === 0) {
          this.progressBarWidth = 160;
        }
        if (step.stepIndex === 1) {
          this.progressBarWidth = 122;
        }
      })
    );

    this.subscription.add(
      this.stepsService.styleListsReplaySubject
        .pipe(debounceTime(300))
        .subscribe((styleLists) => {
          this.styleLists = styleLists;
        })
    );
  }

  attributeValidationButtonText() {
    switch (this.stepsService.funnelContext) {
      case FunnelContextEnum.MODIFICATION:
        this.validationButtonText = 'button.save-modification';
        break;
      case FunnelContextEnum.DUPLICATION:
        this.validationButtonText = 'button.save-duplication';
        break;
      default:
        this.validationButtonText = 'button.create-alert';
        break;
    }
  }

  get currentStep() {
    return this.stepsService.getCurrentStep$();
  }

  onNextStep() {
    this.stepsService.moveToNextStep();
  }

  onPrevStep() {
    this.stepsService.moveToPrevStep();
  }

  isOneOfTwoLastSteps() {
    return this.stepsService.isOneOfTwoLastSteps();
  }

  isFirstStep() {
    return this.stepsService.isFirstStep();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
