<div class="bg-white border-radius-16px associated-panel-container">

    <div class="d-flex align-middle icon-and-title-section">
        <mat-icon svgIcon="folder-outline-basic-600"></mat-icon>
        <div class="panel-title fw-700 color-basic-600 text-limited-2-lines">
            {{panelTitle | uppercase}}
        </div>
    </div>

    <div class="data-section">
        <ng-content></ng-content>
    </div>

    <!--Opening of external link into a new tab-->
    <a class="bg-basic-600 border-radius-8px d-flex external-link-section"
       [href]="externalLinkUrl"
       target="_blank">
        <mat-icon svgIcon="ext-link-basic-800"></mat-icon>
        <div class="external-link-title align-self-center">
            {{externalLinkSectionTitle | translate | uppercase}}
        </div>
    </a>

</div>
