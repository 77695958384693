<div class="metadata">
  <!--Doc admin only-->
  <div *ngIf="documentType === 'admin_doc'" class="displayed-publication-type">
    <ng-container *ngIf="displayedPublicationType === DisplayedPublicationType.NON_DETECTED">
      <mat-icon class="fill-basic-600 icon-20 fill-basic-600 strict me-2" svgIcon="docs-metadata"></mat-icon>
      <span class="color-basic-600" [style.font-style]="'italic'">{{displayedPublicationType}}</span>
    </ng-container>

    <ng-container *ngIf="displayedPublicationType !== DisplayedPublicationType.NON_DETECTED">
      <mat-icon svgIcon="docs" class="icon-20 fill-basic-600 strict me-2"></mat-icon>
      <span class="fw-bold">{{'document.issuer-kind.' + issuerKind | translate }}</span>
      <span class="tiret">-</span>
      <span class="">{{displayedPublicationType && (displayedPublicationType | translate)}}</span>
    </ng-container>
  </div>

  <div class="publication-date">
    <mat-icon svgIcon="calendar" class="icon-20 strict fill-basic-600 me-2"></mat-icon>
    <span *ngIf="!publicationDate; else publicationDateDisplay">{{'content.date_not_detected' | translate}}</span>
  </div>

  <div *ngIf="territoryNames?.length" class="territory-names">
    <mat-icon svgIcon="pin" class="icon-20 strict fill-basic-600 me-2"></mat-icon>
    <div #territoryNamesContainer class="territory-names-container truncate-text"
         overflowTooltip
         ngbTooltip="{{territoryNames?.join(', ')}}"
         [placement]="['bottom', 'left']"
         tooltipClass="territory-names-tooltip">
      <span>
        {{territoryNames?.join(', ')}}
      </span>
    </div>
  </div>

  <div *ngIf="source" class="source">
    <mat-icon svgIcon="article" class="icon-20 strict fill-basic-600 me-2"></mat-icon>
    <span>{{source}}</span>
  </div>

  <div *ngIf="website" class="website">
    <mat-icon svgIcon="website" class="icon-20 strict fill-basic-600 me-2"></mat-icon>
    <span>{{website}}</span>
  </div>
</div>

<ng-template #publicationDateDisplay>
    <span *ngIf="source">{{publicationDate | date: 'dd MMMM yyyy':'':'fr'}}</span>
    <span *ngIf="website">{{publicationDate | date: 'dd MMMM yyyy':'':'fr' | titlecase}}</span>
</ng-template>
