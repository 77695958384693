<mat-expansion-panel [ngClass]="{
                        'right-border-entity': true,
                        'margin-bottom-8': true
                     }"
                     class="no-select"
                     expanded="false"
                     hideToggle="true"
                     (afterExpand)="togglePanel()"
                     (afterCollapse)="togglePanel()"
                     #savePanel
>
  <mat-expansion-panel-header
    class="right-border-entity-header"
    [style.background-color]="databaseBookmarkPaths.length > 0 ? 'var(--login-green-validation)' : 'var(--primary-500)'"
    data-cy="save-panel-header"
  >
    <mat-panel-title>
      <div class="header-content">
        <div *ngIf="databaseBookmarkPaths.length === 0" class="header-left-content">
          <mat-icon class="fill-white icon-folder" svgIcon="save-folder-icon"></mat-icon>
          <div class="title color-white">{{'folder.panel-header-save' | translate | uppercase}}</div>
        </div>
        <div *ngIf="databaseBookmarkPaths.length > 0" class="header-left-content">
          <mat-icon class="fill-white icon-folder" svgIcon="saved-folder-icon-white"></mat-icon>
          <div class="title color-white">{{'folder.panel-header-saved' | translate | uppercase}}</div>
          <div class="title color-white"> ({{databaseBookmarkPaths.length}})</div>
        </div>
      </div>
    </mat-panel-title>

  </mat-expansion-panel-header>
  <div *ngIf="savePanel.expanded" class="expansion-content">
    <ng-container *ngIf="folderList">
      <div id="new-folder-button" data-cy="new-folder" (click)="openModalCreationFolder()">
        <img src="/assets/images/icon-plus-outline.svg" alt="new-folder-icon" class="new-folder-icon">
        <span class="checkbox-label">{{'folder.modal-new-item-title' | translate }}</span>
      </div>
      <div class="ignore-parent-padding folder-list">
        <p class="placeholder-no-folder" *ngIf="!folderList.length">
          <span>{{'folder.no-folder-yet' | translate}}</span>
          <span>{{'folder.no-folder-create-first' | translate}}</span>
        </p>
        <div data-cy="save-panel-folder-line"
             class="d-flex" *ngFor="let folder of folderList" [id]="'folder-'+folder.id">
          <label class="container d-flex">
            <span class="checkbox-label">{{folder.name}}</span>
            <input data-cy="save-panel-folder-line-checkbox" type="checkbox" class="form-check-input"
                   [checked]="folder.containsDocument"
                   (change)="toggleCheckbox($event, folder)"
            >
            <span class="checkmark"></span>
            <span *ngIf="folder.id === folderId"
                  class="this-folder-badge">{{'folder.this-folder-badge' | translate}}</span>
          </label>
        </div>
      </div>
      <mat-divider class="ignore-parent-padding color-basic-500"></mat-divider>
      <div class="footer-padding d-table">
        <span class="folder-separator d-table-cell align-content-center"></span>
        <div class="cancel-button d-table-cell align-content-center"
             (click)="onClosePanel()">{{'folder.cancel-button' | translate | titlecase}}</div>
        <button class="button-save d-table-cell align-content-center" onclick="this.blur()"
                (click)="updateBookmarks()" [disabled]="!hasUnsavedChanges()" data-cy="folder-save-button"> {{'folder.save' | translate | uppercase}}
        </button>
      </div>
    </ng-container>
  </div>
</mat-expansion-panel>
