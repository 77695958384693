export class ExportAdminDocMetadata {
  private document_uid: string;
  private open_source: string;

  constructor(documentUid: string, openSource: string) {
    this.document_uid = documentUid;
    this.open_source = openSource;
  }

  get documentUid(): string {
    return this.document_uid;
  }

  set documentUid(value: string) {
    this.document_uid = value;
  }

  get openSource(): string {
    return this.open_source;
  }

  set openSource(value: string) {
    this.open_source = value;
  }
}
