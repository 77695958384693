import { Injectable } from '@angular/core';
import { MatchStem, MatchStemsSearchInterface } from '../../models/match-stem';

/**
 * Service to help discharge component admin-doc-pdf from some of its logic
 */
@Injectable({
  providedIn: 'root'
})
export class KeywordsPdfSearchService {

  public nbPages = 0;

  constructor() { }

  /** Method taking a matrix of type MatchStemsSearchInterface[] and updating its
   * occurrencePages and distinctOccurrencePages attributes in regard of a certain matchesColor Object.
   * */
  updateSearchMatrix(matchesColor: Array<any>, matrix: MatchStemsSearchInterface[]): void {
    // Exit if matchesColor not completed
    if (matchesColor?.length === this.nbPages) {
      matrix?.forEach((matchStemSearchInterfaceObject) => {
        // donne le numéro de page contenant une occ
        const matchingPageIndexes: Array<number> = [];
        // could be useful for later
        const distinctPageIndexes: Array<number> = [];
        matchStemSearchInterfaceObject.itemsIndexesAmongSearchList?.forEach((color: number) => {
          matchesColor?.forEach((page, index) => {
            if (page?.includes(color)) {
              page?.forEach((value: number) => {
                if (value === color) {
                  matchingPageIndexes.push(index + 1);
                }
              });
              // could be useful for later
              if (!distinctPageIndexes.includes(index + 1)) {
                distinctPageIndexes.push(index + 1);
              }
            }
          });
        });
        matchStemSearchInterfaceObject.occurrencePages = matchingPageIndexes;
        matchStemSearchInterfaceObject.distinctOccurrencePages = distinctPageIndexes.sort((a, b) => a - b);
      });
    }
  }

  /** Method to add itemsIndexesAmongSearchList */
  setUpMatrixWithCurrentSearchTermList(matrix: MatchStemsSearchInterface[]) {
    let index = 0;
    matrix?.forEach((matchStemsSearchInterface) => {
      const itemsIndexes: Array<number> = [];
      matchStemsSearchInterface.items?.forEach(() => {
        // Finding index of item among to SearchList. It'll help counting occurrences.
        itemsIndexes.push(index);
        index++;
      });
      matchStemsSearchInterface.itemsIndexesAmongSearchList = itemsIndexes;
    });
  }

  /** Method to generate stemMatrix and list of words for multisearch and returns them*/
  generateStemMatrixAndItemsToSearch(matchStems: MatchStem[])
    : { matchTermMatrix: MatchStemsSearchInterface[], initialTermsToSearch: string[] } {
    const matrix: Array<any> = [];
    const itemsToSearch: Array<any> = [];
    let index = 0;

    // Part affecting stem items indexes among searchList to each stem member of matchStemMatrix.
    // Il will be needed for the occurrence count after reception of updateMatchCounters event.
    matchStems?.forEach((matchStem) => {

      // Finding index of stem items among to SearchList. It'll help counting occurrences.
      const itemsIndexes = [];
      for (const item of matchStem.items) {
        itemsIndexes.push(index);
        index++;
      }

      // SET up de la matrix
      matrix.push(
        {
          stem: matchStem.stem,
          items: matchStem.items,
          itemsIndexesAmongSearchList: itemsIndexes,
          occurrencePages: null,
          distinctOccurrencePages: null
        }
      );

      // SET up string list for the upcoming findMultiple.
      matchStem.items?.forEach((item) => {
        itemsToSearch.push(item);
      });
    });
    return { matchTermMatrix : matrix, initialTermsToSearch : itemsToSearch };
  }


}
