<ng-template #header let-opened="opened" let-images="images" let-texts="texts" let-count="count">
  <span class="d-flex align-items-center">
    <img class="header-icon align-items-center filter-basic-600" src="{{images['header']}}" alt="header-icon">
    <span class="header-text align-items-center color-basic-600">
      <span>{{count}} </span>
      <span *ngIf="count > 1">{{texts['many'] | translate}}</span>
      <span *ngIf="count <= 1">{{texts['one'] | translate}}</span>
    </span>
  </span>
</ng-template>

<ng-template #nocontent let-images="images" let-texts="texts">
  <div class="empty-content">
    <img class="filter-basic-500" src="{{images['empty']}}" alt="empty-icon">
    <span class="color-basic-600">{{texts['empty'] | translate}}</span>
  </div>
</ng-template>

<div ngbAccordion *ngIf="article">
  <div ngbAccordionItem [collapsed]="false">
    <div ngbAccordionHeader>
      <button ngbAccordionButton>
        <ng-container class="no-select"
                      [ngTemplateOutlet]="header"
                      [ngTemplateOutletContext]="{images: imCtx.images, texts: imCtx.texts, count: imCtx.count}"
        ></ng-container>
      </button>
    </div>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <ng-template>
          <app-impacters-panel *ngIf="article.impacters.length"
                               [impacters]="article.impacters"
                               data-cy="impacters-panel"/>
          <ng-container *ngIf="!article.impacters.length"
                        [ngTemplateOutlet]="nocontent"
                        [ngTemplateOutletContext]="imCtx"
          ></ng-container>
        </ng-template>
      </div>
    </div>
  </div>
</div>
