import { Component, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { filter, pairwise } from 'rxjs/operators';
import { ActivatedRoute, ActivationStart, NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { UpdateModalService, UpdateModalTypeEnum } from './shared/services/update-modal.service';
import { UrlService } from './shared/services/url.service';
import { environment } from '../environments/environment';
import { LocalStorageService } from './shared/services/local-storage.service';
import { StoredCollectionNames } from './models/stored-collection-names';
import { DisplayNpsModalService } from './shared/services/display-nps-modal.service';
import { Subscription } from 'rxjs';
import { IpService } from './shared/services/ip.service';
import { ApiExStatisticsService } from './shared/services/api/ex-statistics/api-ex-statistics.service';

import MEPConfiguration from 'src/assets/mep-configuration/version.json';
import { AuthenticationService } from "./shared/services/authentication.service";
import { UserSessionService } from "./shared/services/user-session.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  private subscriptions: Array<Subscription> = [];
  canDisplayMenu!: boolean;
  displayGuestMenu = false;

  constructor(private translate: TranslateService,
              private router: Router,
              private route: ActivatedRoute,
              private updateModalService: UpdateModalService,
              private localStorageService: LocalStorageService,
              private urlService: UrlService,
              private displayNpsModalService: DisplayNpsModalService,
              private ipService: IpService,
              private apiExStatisticsService: ApiExStatisticsService,
              private authenticationService: AuthenticationService,
              private userSessionService: UserSessionService) {

    this.promptForPassword()
    this.translate.setDefaultLang('fr');

    this.router.events.pipe(
      filter(event => event instanceof ActivationStart)
    ).subscribe((event: any) => {
      this.canDisplayMenu = true;
      if (undefined !== event['snapshot'].data.displayNavBar) {
        this.canDisplayMenu = event['snapshot'].data.displayNavBar;
      }
      if (undefined !== event['snapshot'].data.guestDisplay) {
        this.displayGuestMenu = event['snapshot'].data.guestDisplay;
      }
    });

    /** settings previous and current urls inside UrlService */
    this.subscriptions.push(this.router.events
      .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
      .subscribe((events: RoutesRecognized[]) => {
        this.urlService.PreviousUrl = events[0].urlAfterRedirects;
        this.urlService.CurrentUrl = events[1].urlAfterRedirects;
      }));

    /** Part needed for displaying components depending on navigation changes.
     * e.g: help widget should not be shown on login page,
     * this code allow to display the widget after user successfully log in. **/
    this.subscriptions.push(this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe(() => {
      this.displayNpsModalService.displayNpsModalIfNeeded().then();
    }));

    /** Part for update modal displaying */
    if (environment.production) {
      this.updateModalService.setUpdateDetectionTimer(5000); // Second modal is displayed 2 minutes after first
    }

    /** Part for sanitizing localStorage - stored_contexts && stored_match_stems */
    this.localStorageService.sanitizeLocalStorage(StoredCollectionNames.STORED_CONTEXTS);
    this.localStorageService.sanitizeLocalStorage(StoredCollectionNames.STORED_MATCH_STEMS);

    /** Part for resolving user's IP and storing it into IpService */
    this.ipService.resolveUserIp();

    /** ___ Management of new feature explained modal ___
     * This new modale can only be displayed if legacy Update modale is set to be displayed too */
    if (localStorage.getItem('api_token') == null) { // user NEED to be connected
      return;
    } else {
      /** Part settings Explain Statistics (nb documents, articles, webstites, etc...) */
      this.apiExStatisticsService.setExplainStatistics();

      /** Part settings user's Account Info (mainly territories) */
      this.apiExStatisticsService.setAccountInfos();

      /** Part setting user's session Info*/
      if (!this.route.snapshot.data['guestDisplay']) { // not setting for guests users.
        this.userSessionService.setUserSessionFromLocalStorage();
      }

      if (
        MEPConfiguration.display_update_modal && // display M1
        MEPConfiguration.display_new_feature_explained_modal && // display M2
        !(localStorage.getItem(MEPConfiguration.new_feature_localstorage_item_name) === 'true') // never seen the modal M2
      ) {
        console.log('new feature modale displayed - it should occur after classic update modale display.');
        this.updateModalService.openUpdateModal(true, UpdateModalTypeEnum.NEW_FEATURE_EXPLAINED);
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  promptForPassword(): void {
    if (!(environment as any)['promptAccessPassword']) return;
    const timestamp = Date.now()
    const enigma = (timestamp - (timestamp % (24 * 60 * 60 * 1000))) % 999999
    if (localStorage.getItem('accessPassword') === enigma.toString()) return;
    const password = window.prompt('Enter Alpha Access Password');
    if (password !== enigma.toString()) {
      alert('Incorrect password. Access denied.');
      this.promptForPassword(); // Re-prompt for password
    }
    else localStorage.setItem('accessPassword', password);
  }

  urlIsAllowedToDisplayHelp() {
    return (
      !this.router.url.includes('login') &&
      !this.router.url.includes('guest') &&
      !this.router.url.includes('tenders')
    );
  }

  logout() {
    this.authenticationService.logout();
    location.reload();
  }
}
