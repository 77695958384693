<a data-cy="article-card-folder-view-component"
  [routerLink]="allowOnClick ? docId : null"
   (contextmenu)="onClickCard($event); trackClickedArticle()"
   (middleclick)="onClickCard($event); trackClickedArticle()"
   (click)="onClickCard($event); trackClickedArticle()"
   [state]="{context: text}"
   data-cy="article-card"
>
  <div id="card-{{docId}}" [ngClass]="{'preview': isPreview}" class="card-board">
    <div class="first-row">
      <div class="card-info d-flex">
        <div class="d-flex flex-grow-1 align-self-center">
          <div class="icon icon--article"></div>
          <div class="fw-600 color-basic-800">{{source}}</div>
        </div>
        <div class="d-flex align-self-center fw-700">
          <div class="icon icon--calendar"></div>
          <div *ngIf="publicationDate" class="color-basic-800">{{publicationDate | date:'dd MMMM yyyy':'':'fr' }}</div>
          <div *ngIf="!publicationDate" class="color-basic-800">{{'content.date_not_detected' | translate}}</div>
        </div>
      </div>
    </div>

    <!--line containing folder icon, title and occurrence badge-->
    <div class="second-line">
      <div class="left-part d-flex">
        <app-occurrence-and-folder-icon-badge
                class="d-flex"
                [folderId]="folderId"
                [nbOccurrences]="nbOccurrences"
                [showOccurrences]="false"
                [showCreationDate]="false"
                [bookmarkPaths]="bookmarkPaths"
                [showFolder]="true">
        </app-occurrence-and-folder-icon-badge>
        <div class="card-title d-table-cell align-middle position-static text-truncate"
             data-cy="article-card-title"
             [innerHTML]="title | articleTitleCase">
        </div>
      </div>
      <div class="right-part d-table h-100">
        <app-occurrence-and-folder-icon-badge
                class="d-table-cell align-middle"
                [folderId]="folderId"
                [nbOccurrences]="nbOccurrences"
                [showOccurrences]="showOccurrences"
                [bookmarkPaths]="bookmarkPaths"
                [showFolder]="false">
        </app-occurrence-and-folder-icon-badge>
      </div>
    </div>

    <div class="territory-section"
         [ngClass]="displayReadMore ? 'split' : ''"
         id="territory-section-{{docId}}">
      <div *ngIf="territories && territories.length > 0"
           class="territories d-flex align-self-center">
        <mat-icon *ngIf="isPreview" svgIcon="pin" class="icon-16 fill-primary-500 me-1"></mat-icon>
        <mat-icon *ngIf="!isPreview" svgIcon="pin-basic-600" class="icon-16 me-1"></mat-icon>
        <div id="territory-list-{{docId}}" class="territory-list text-truncate-territories">
          <span *ngFor="let territory of territories; let i = index">
            <span *ngIf="isPreview" class="territory-name fw-bold color-primary-500">{{territory['name']}}</span>
            <span *ngIf="!isPreview" class="territory-name fw-bold color-basic-800">{{territory['name']}}</span>
            <span *ngIf="territories && i < territories.length - 1"
                  class="separator color-basic-600 fw-light"> | </span>
            <span *ngIf="territories && i === territories.length - 1" class="fantom-space">...</span>
          </span>
        </div>
      </div>
      <div *ngIf="territories?.length === 0" class="territories d-flex align-self-center">
        <mat-icon svgIcon="pin-basic-600" class="icon-16 fill-basic-600 me-1"></mat-icon>
        <span class="fst-italic territory-name fw-bold color-basic-600">{{'content.non-detected-territory' | translate}}</span>
      </div>
      <div *ngIf="displayReadMore" class="fading-color-box">

      </div>
      <div *ngIf="displayReadMore"
           id="read-more-{{docId}}"
           class="read-more d-flex"
           (click)="onReadMoreClick($event)"
           [ngbTooltip]="territoryTooltip"
           tooltipClass="custom-tooltip-article-card-territories"
           placement="bottom-right">
        <mat-icon svgIcon="plus-circle-icon-fill" class="icon-16 fill-basic-600 me-1 align-self-center align-middle"></mat-icon>
        <div class="text color-basic-600 fw-bold align-self-center">{{"content.see-more"| translate}}</div>
      </div>
    </div>

    <div class="card-text" data-cy="article-card-text" [innerHTML]="text"></div>
  </div>
</a>

<ng-template #territoryTooltip class="territory-tooltip">
      <span *ngFor="let territory of territories; let i = index">
            <span class="territory-name fw-bold color-white">{{territory['name']}}</span>
            <span *ngIf="territories && i < territories.length - 1"
                  class="separator color-basic-600 fw-light"> | </span>
      </span>
</ng-template>
