import { Component, EventEmitter, Input, Output, ElementRef, ViewChild, TemplateRef } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DisplayedTerritoryWatch } from '../../models/territory-watch';
import { Router } from '@angular/router';
import { ApiService } from '../../shared/services/api/api.service';
import { first } from 'rxjs/operators';
import { TerritoryWatchSettings } from '../../models/territory-watch-settings';
import { FunnelContextEnum } from '../../shared/services/steps.service';
import { ToastMessageStackService } from '../../shared/services/toast-message-stack.service';
import { TerritoryWatchStatus } from '../../models/territory-watch/territory-watch';
import { DbListItem } from '../../models/generic/list-item';

@Component({
  selector: 'app-list-template',
  templateUrl: './list-template.component.html',
  styleUrls: ['./list-template.component.scss']
})
export class ListTemplateComponent {
  @Input() territoryWatches!: Array<DisplayedTerritoryWatch>;
  @Input() isTopic = false;
  @Input() alertMode!: boolean;
  @Input() sortTextBoolean!: boolean;
  @Output() itemRenamed = new EventEmitter<DisplayedTerritoryWatch>();
  @Output() itemDeleted = new EventEmitter<string>();
  @Output() alertActiveChanged = new EventEmitter<{ isActive: boolean, itemId: string }>();
  public indexItemSelected!: number;
  public itemIdSelected!: string;
  @ViewChild('duplicationModale') duplicationModale!: ElementRef;
  currentOrder = {
    orderBy: 'name',
    reverse: false
  };
  forceUpdate = 0;
  // Getting template ref of error on duplication.
  @ViewChild('errorInDuplication', {read: TemplateRef}) errorInDuplication!: TemplateRef<any>;
  awaitingDuplicationResponse = false;
  modalOptions = { backdrop: 'static', keyboard: false, ariaLabelledBy: 'modal-basic-title', centered: true } as NgbModalOptions;

  constructor(private modalService: NgbModal,
              private apiService: ApiService,
              private router: Router,
              private toastMessageStackService: ToastMessageStackService) {
  }

  openDeleteModal(content: TemplateRef<any>, itemId: string, itemIndex: number) {
    this.modalService.open(content, this.modalOptions);
    this.indexItemSelected = itemIndex;
    this.itemIdSelected = itemId;
  }

  onRename(event: DbListItem): void {
    this.territoryWatches[event.index].name = event.name;
    this.itemRenamed.emit({id: event.id, name: event.name} as DisplayedTerritoryWatch);
    setTimeout(() => {
      const lastButtonClicked = document.activeElement as HTMLButtonElement;
      if (lastButtonClicked && lastButtonClicked.tagName.toLowerCase() === 'button') {
        lastButtonClicked.blur();
      }
    }, 10);
  }

  onDelete(): void {
    this.itemDeleted.emit(this.itemIdSelected);
    this.territoryWatches.splice(this.indexItemSelected, 1);
    this.modalService.dismissAll();
  }

  onSelectChange(event: any, itemId: string): void {
    const isActive = event.target.checked;
    this.alertActiveChanged.emit({isActive, itemId});
  }

  changeOrder(value: string) {
    if (this.currentOrder.orderBy === value) {
      this.currentOrder.reverse = !this.currentOrder.reverse;
    } else {
      this.currentOrder = {
        orderBy: value,
        reverse: false
      };
      this.forceUpdate++;
    }
  }

  async duplicateAlert(alertId: string) {
    // Disabling the duplication button during process.
    this.awaitingDuplicationResponse = true;
    // Definition of the 2 seconds timer.
    const twoSecondTimerPromise = new Promise((resolve) => {
      setTimeout(resolve, 2000);
    });
    // Opening of the duplication modale.
    this.modalService.open(this.duplicationModale, this.modalOptions);

    // retrieving alert settings.
    const alertSettings = await this.retrieveAlertInfos(Number(alertId)) as TerritoryWatchSettings;

    // Adding ' (copy)' to the alert name.
    alertSettings.name += ' (Copie)';

    // creating new alert with those settings.
    const newAlertIdResponsePromise = this.createAlertFromTerritoryWatchSettings(alertSettings);

    // We wait the promises to be resolved to dismiss the modal and navigate to the duplication form.
    Promise.all([twoSecondTimerPromise, newAlertIdResponsePromise as Promise<TerritoryWatchStatus>])
      .then((values) => {
        const newAlertId = values[1]?.id;
        this.modalService.dismissAll();
        this.router.navigate([`/alert-form/`], {
          queryParams:
            {
              alertId: newAlertId,
              funnelContext: FunnelContextEnum.DUPLICATION,
              fromAlertId: alertId
            }
        });
      })
      .catch(() => {
        this.awaitingDuplicationResponse = false;
        this.modalService.dismissAll();
        this.toastMessageStackService.show(this.errorInDuplication, {autohide: true, classname: 'error-toast toast-shape'});
      });

  }

  retrieveAlertInfos(alertId: number) {
    return this.apiService.territoryWatch.getTerritoryWatchSetting(alertId)
      .pipe(first())
      .toPromise()
      .catch((e) => {
        console.log(`error on retrieving alert infos - ${e}`);
        throw e;
      });
  }

  createAlertFromTerritoryWatchSettings(alertSettings: TerritoryWatchSettings) {
    return this.apiService.territoryWatch.createOrUpdateTerritoryWatch(alertSettings.toStringForPostNewTerritoryWatch())
      .pipe(first())
      .toPromise()
      .catch((e) => {
        console.log(`error on creating new alert from territory watch settings - ${e}`);
        throw e;
      });
  }

  onCreateAlert() {
    this.router.navigate(['/alert-form/']);
  }

}
