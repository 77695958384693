import { PipeTransform, Pipe } from '@angular/core';
import { ImpacterForArticle } from '../../models/impacter/impacter';

@Pipe({ name: 'reference' })
export class ReferencePipe implements PipeTransform {
  transform(text: string, impacters: ImpacterForArticle[]): any {
    // Return the final transformed text
    return impacters.reduce((accumulatedImpactersResult, impacter) => {
      // For each unique mention, replace the mention by a referenced link
      return [...new Set(impacter.mentions.map((mention) => mention.name))].reduce((
        accumulatedMentionsResult,
        mention
      ) => accumulatedMentionsResult.replace(
        new RegExp(mention, 'g'),
        (value) => `<a target="_blank" href="${impacter.url}" class="mention im-${impacter.id}">${value}</a>`
      ), accumulatedImpactersResult);
    }, text);
  }
}
