import { TerritoryWatchIssuerKindEnum } from '../../app/models/territory-watch-settings';
import { TreeviewConfig, TreeviewItem } from '../../app/lib/ngx-treeview';
import { AdminDocSourceEnum } from '../../app/models/admin-document';

export interface DbAdminDocSourceIssuerGroupData {
  name: string;
  publicationTypes: Array<TerritoryWatchIssuerKindEnum>;
  title: string;
  config: TreeviewConfig;
  checked: boolean;
  issuerTemplateClassName: string;
  rank: number;
}

/** Classe pour pouvoir utiliser un constructeur */
export class AdminDocSourceIssuerGroupData implements DbAdminDocSourceIssuerGroupData {
  name: string;
  publicationTypes: Array<TerritoryWatchIssuerKindEnum>;
  title: string;
  private _treeviewItems: Array<TreeviewItem>;
  config: TreeviewConfig;
  checked: boolean;
  issuerTemplateClassName: string;
  rank: number;
  storedValues: Array<string>;


  constructor(CONSTANT: DbAdminDocSourceIssuerGroupData,
              checked: boolean,
              treeviewItems: Array<TreeviewItem>
  ) {
    this.name = CONSTANT.name;
    this.publicationTypes = CONSTANT.publicationTypes;
    this.title = CONSTANT.title;
    this.issuerTemplateClassName = CONSTANT.issuerTemplateClassName;
    this.config = CONSTANT.config;

    this.checked = checked || false;
    this._treeviewItems = treeviewItems || [];
    this.rank = CONSTANT.rank;
    this.storedValues = treeviewItems[0]?.getSelection().checkedItems.map(x => x.value) || [];
  }

  set treeviewItems(value: Array<TreeviewItem>) {
    this._treeviewItems = value;
    this.storedValues = value[0].getSelection().checkedItems.map(x => x.value);
  }

  get treeviewItems(): Array<TreeviewItem> {
    return this._treeviewItems;
  }
}

export const DEFAULT_PREF_AND_MRAE_ISSUER_GROUP_DATA = {
  name: 'pref-and-mrae',
  publicationTypes: [TerritoryWatchIssuerKindEnum.PREFECTURE, TerritoryWatchIssuerKindEnum.MRAE],
  title: 'alert.issuer-kind.pref-and-mrae-title',
  config: TreeviewConfig.create(),
  checked: true,
  issuerTemplateClassName: 'list-element-first',
  rank: 1
};

export const DEFAULT_SYNDICATES_ISSUER_GROUP_DATA = {
  name: 'syndicates',
  publicationTypes: [TerritoryWatchIssuerKindEnum.SYNDICAT],
  title: 'alert.issuer-kind.syndicates-title',
  config: TreeviewConfig.create(),
  checked: true,
  issuerTemplateClassName: 'list-element',
  rank: 2
};

export const DEFAULT_COMM_AND_EPCI_ISSUER_GROUP_DATA = {
  name: 'comm-and-epci',
  publicationTypes: [TerritoryWatchIssuerKindEnum.FRCOMM, TerritoryWatchIssuerKindEnum.FREPCI],
  title: 'alert.issuer-kind.comm-and-epci-title',
  config: TreeviewConfig.create(),
  checked: true,
  issuerTemplateClassName: 'list-element-last',
  rank: 3
};

export const EptTreeviewItem = {
  infos: {
    type: 'comm-and-epci',
    subtype: 'last-elem'
  },
  isRoot: false,
  checked: true,
  collapsed: false,
  disabled: false,
  text: 'Établissements publics territoriaux',
  value: AdminDocSourceEnum.EPTP
};

