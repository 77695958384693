import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { EventTypeName } from '../../../models/user-tracker';
import { auditTime } from 'rxjs/operators';
import { UserTrackerService } from '../../services/tracking/user-tracker.service';

export enum TenderEventType {
  TENDER_SEARCH = 'tender-search'
}

enum TrackingAction {
  CLICK = 'click',
  MOUSEENTER = 'mouseenter',
  MOUSELEAVE = 'mouseleave',
  HOVER = 'hover',
  KEYDOWN = 'keydown',
  KEYPRESSED = 'keypressed'
}

@Directive({
  selector: '[track]'
})
export class TrackingDirective implements OnInit, OnDestroy {
  @Input() eventAction!: string;
  @Input() eventType!: EventTypeName | TenderEventType;
  @Input() eventLocation!: string;
  @Input() eventName!: string;
  @Input() eventValue: any;
  @Input() eventDetail: any;

  event = new EventEmitter<string>();

  constructor(
    private userTrackerService: UserTrackerService,
  ) { }

  ngOnInit() {
    this.event.pipe(auditTime(100)).subscribe(
      () => {
        this.userTrackerService.trackEvent(
          this.eventType,
          this.eventName,
          this.eventLocation,
          this.eventValue,
          this.eventDetail,
        );
      }
    );
  }

  ngOnDestroy(): void {
    this.event.unsubscribe();
  }

  @HostListener('click', ['$event'])
  @HostListener('hover', ['$event'])
  onClick(event: PointerEvent) {
    if (event.type === this.eventAction as TrackingAction) {
      this.event.emit(event.type);
    }
  }
}
