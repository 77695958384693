import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EntityService } from '../../../shared/services/entity.service';
import { ControlFService } from '../../../shared/services/control-f.service';
import { Article } from '../../../models/article';
import { first } from 'rxjs/operators';
import Utils from '../../../shared/utils';
import { ApiService } from '../../../shared/services/api/api.service';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-article-guest-view',
  templateUrl: './article-guest-view.component.html',
  styleUrls: ['./article-guest-view.component.scss']
})
export class ArticleGuestViewComponent implements OnInit, OnDestroy {
  @ViewChild('articleGuestView') articleGuestViewElement!: ElementRef;
  articleId!: string;
  article!: Article;
  shareToken!: string;
  public documentProviderId = '';

  inSearch = false;
  searchNotFound = false;
  show = false;

  constructor(
    private route: ActivatedRoute,
    private entityService: EntityService,
    private apiService: ApiService,
    private _controlFService: ControlFService,
    private utils: Utils
  ) {
  }

  ngOnInit(): void {
    // To prevent bugs in case a user with a license opened a document in guest mode, then go back to the app.
    if (localStorage.getItem('api_token')) {
      localStorage.removeItem('api_token');
    }

    this._controlFService.getLoaderAttributes().subscribe((loader: any) => {
      this.show = loader['show'];
      this.inSearch = loader['inSearch'];
      this.searchNotFound = loader['searchNotFound'];
    });
    this._controlFService.setSearchTimeout();

    this.controlFService.resetInputSearch();
    this.route.params.subscribe((params) => {
      this.articleId = params['articleId'];
      this.shareToken = params['shareToken'];
      const openSourceId = params['openSourceId'] || null;
      this.entityService.sendEntityId(this.articleId);
      this.fetchData(openSourceId).then();
    });
  }

  ngOnDestroy() {
    this.controlFService.resetInputSearch();
    this._controlFService.clearInterval();
  }

  get controlFService() {
    return this._controlFService;
  }

  getParamsAsObject(openSourceId: string): any {
    if (openSourceId) {
      return {
        'open_source': 'email',
        'open_source_id': openSourceId
      };
    } else {
      return { 'open_source': 'web_app' };
    }
  }

  async retrieveArticle(openSourceId: string) {
    const params = new HttpParams({ fromObject: this.getParamsAsObject(openSourceId)});

    try {
      return await this.apiService.article.getSharedArticle(`${this.articleId}`, params, this.shareToken).pipe(first()).toPromise();
    } catch (e) {
      console.log(`error when retrieving article: ${e}`);
      return;
    }
  }

  async fetchData(openSourceId: string) {
    const article = await this.retrieveArticle(openSourceId);
    if(article){
      this.article = article;
      this.article.text = this.utils.updateArticleText(this.article);
      this.entityService.sendDocument(this.article);
      this.documentProviderId = this.article.dataProviderDocumentId?.toString();
    }
  }

}
