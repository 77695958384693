<div class="container-fluid">
  <div class="row">
    <div class="col-sm-6 left-side"></div>
    <div class="col-sm-6 right-side d-flex">
      <div class="content">
        <div class="fw-bold title">{{"reset-password.title-incorrect" | translate }}</div>
        <div class="sub-title pb-4">
          {{"reset-password.sub-title-incorrect" | translate}} <br>
          {{"reset-password.sub-title-incorrect-l2" | translate}} <br>
          {{"reset-password.sub-title-incorrect-l3" | translate}} <br>
          {{"reset-password.sub-title-incorrect-l4" | translate}} <br>
          {{"reset-password.sub-title-incorrect-l5" | translate}} <br>
        </div>
        <div class="login-btn-container form-group">
          <button [routerLink]="['/login/forgot-password']"
                  class="fw-bold btn btn-login">{{'reset-password.new-reset' | translate | uppercase}}</button>
          <div [routerLink]="['/login']"
               class="btn btn-password-forgotten">{{'reset-password.back-to-login' | translate}}</div>
        </div>
      </div>
    </div>
  </div>
</div>


