import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TerritoryWatchSettingService } from '../../shared/services/territory-watch-setting.service';
import { ApiService } from '../../shared/services/api/api.service';
import { TerritoryWatchDocumentPreview, TerritoryWatchDocumentPreviewPeriod } from '../../models/territory-watch-document-preview';
import { StepsService } from '../../shared/services/steps.service';
import { first } from 'rxjs/operators';
import { TerritoryWatchOverviewParams as TerritoryWatchOverviewParams } from '../../models/territory-watch/territory-watch-overview-params';
import { AdminDocSourceEnum } from '../../models/admin-document';
import { affectDisplayHelperObjectsToEntity } from '../admin-docs-page/admin-docs-page.component';

@Component({
  selector: 'app-overview-step-template',
  templateUrl: './overview-step-template.component.html',
  styleUrls: ['./overview-step-template.component.scss']
})
export class OverviewStepTemplateComponent implements OnInit, AfterViewInit, OnDestroy {
  // Note that 138px is the height of the thin abstract overview panel.
  @Input() isScrolledDownToShowTheAbstractHeader = false;
  @Input() previewsListScrollValue = 0;
  @Output() nbPreviewedDocuments = new EventEmitter<number>();
  @Output() overviewStepIsDestroyed = new EventEmitter<null>();

  private params: TerritoryWatchOverviewParams;
  public territoryWatchDocumentPreviewPeriod?: TerritoryWatchDocumentPreviewPeriod;
  public territoryWatchDocumentPreviews: Array<TerritoryWatchDocumentPreview> = [];
  public totalCount: number = 0;
  public displayError500Panels = false;

  constructor(private territoryWatchSettingService: TerritoryWatchSettingService,
              private apiService: ApiService,
              private stepsService: StepsService) {
    this.params = new TerritoryWatchOverviewParams();
  }

  /** Initializing params and get documents preview */
  ngOnInit(): void {
    this.params = this.territoryWatchSettingService.getOverviewParams();
    this.getAlertDocumentPreviews();
  }

  ngAfterViewInit() {
    this.stepsService.updateStepCompletionState(4, false);
  }

  ngOnDestroy() {
    this.overviewStepIsDestroyed.emit(null);
  }

  get alertName(): string | undefined {
    return this.territoryWatchSettingService.name;
  }

  getAlertDocumentPreviews(): void {
    this.stepsService.arePreviewsRetrieved = false;
    this.stepsService.updateStepCompletionState(4, false);

    const settings = this.territoryWatchSettingService.getSetting();
    const overviewParams = new TerritoryWatchOverviewParams(
      settings.day_of_week,
      settings.issuer_kind,
      settings.project_publication_type,
      settings.topics_ids,
      settings.territories,
      settings.syndicates,
      this.params.limit,
      this.params.territory_watch_offset,
      this.params.offset
    );

    this.apiService.territoryWatch.getTerritoryWatchDocumentPreviews(overviewParams)
      .pipe(first())
      .subscribe(
        (response) => {
          this.stepsService.arePreviewsRetrieved = true;
          this.territoryWatchDocumentPreviews = [];
          this.territoryWatchDocumentPreviewPeriod = response.territoryWatchPreviewPeriod;
          this.nbPreviewedDocuments.emit(this.territoryWatchDocumentPreviewPeriod.nbArticle + this.territoryWatchDocumentPreviewPeriod.nbAdminDoc);
          this.territoryWatchDocumentPreviews = response.territoryWatchDocumentPreviews;

          // affectation of alertPreviewDocument type, that can be either article or adminDoc, or pref-and-mrae-doc
          // very usefull information for correct display of the previews
          this.territoryWatchDocumentPreviews.forEach((alertDocumentPreview) => {
            // 1. case admin doc
            if (Object.values(AdminDocSourceEnum).includes(alertDocumentPreview.source as AdminDocSourceEnum)) {
              alertDocumentPreview.cardType = 'admin-doc';
              // 2. case pref and mrae
            } else if (alertDocumentPreview.projectUid) {
              alertDocumentPreview.cardType = 'pref-and-mrae';
              // 3. case article
            } else if (alertDocumentPreview.dataProvider) {
              alertDocumentPreview.cardType = 'article';
            }
          });

          // Setting required data for display helper objects.
          // WIP en cours
          this.territoryWatchDocumentPreviews.forEach((item: any) => {
            item.extra_data = {
              data_provider: item.dataProvider,
              project_uid: item.projectUid,
              source: item.source,
            };
          });
          this.territoryWatchDocumentPreviews.forEach((item: any) => {
            affectDisplayHelperObjectsToEntity(item.extra_data);
          });
          // end WIP

          this.totalCount = response.totalCount;
          this.stepsService.updateStepCompletionState(4, true);
        },
        () => {
          // Manage data in case of error.
          this.displayError500Panels = true;
          // We allow completion of alert creation even in case of broken preview (timeout)
          this.stepsService.updateStepCompletionState(4, true);
          this.stepsService.arePreviewsRetrieved = true;
          // Emit random value to let previous/creation buttons displayed on header.
          this.nbPreviewedDocuments.emit(undefined);
        });
  }

  onPrevPreview(): void {
    if (this.params.territory_watch_offset && this.params.territory_watch_offset > 0) {
      this.params.territory_watch_offset--;
      this.params.offset = 0;
      this.getAlertDocumentPreviews();
    }
  }

  onNextPreview(): void {
    if (this.params.territory_watch_offset !== undefined) {
      this.params.territory_watch_offset++;
      this.params.offset = 0;
      this.getAlertDocumentPreviews();
    }
  }

  get Params(): TerritoryWatchOverviewParams {
    return this.params;
  }

  get fetchingComplete() {
    return this.stepsService.arePreviewsRetrieved;
  }

  getPartialUpLoaderToValueInPx() {
    return 16 + this.previewsListScrollValue;
  }
}
