import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: 'img[appImageFallback]'
})
export class ImageFallbackDirective {

  @Input() appImgFallback!: string;

  constructor(private eRef: ElementRef) {
  }

  @HostListener('error')
  loadFallbackOnError() {
    const element: HTMLImageElement = <HTMLImageElement> this.eRef.nativeElement;
    element.src = this.appImgFallback || '/assets/images/placeholder-default.png';
    element.srcset = '/assets/images/placeholder-default.png, /assets/images/placeholder-default@2x.png 2x';
  }

}
