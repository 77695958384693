import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { CustomValidators } from '../../shared/helpers/custom-validators.validator';
import { MustMatch } from '../../shared/helpers/must-match.validator';
import { finalize, first } from 'rxjs/operators';
import { ToastMessageService } from '../../shared/services/toast-message.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  public form!: FormGroup;
  public formOldPassword!: FormGroup;
  public oldPasswordIsWrong = false;
  public userLeftInputConfirmPassword = false;
  public userLeftInputPreviousPassword = false;
  public loading = false;
  public submitted = false;
  public fieldTextTypeList: boolean[];

  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private toastMessageService: ToastMessageService,
  ) {
    this.fieldTextTypeList = [false, false, false];
  }

  ngOnInit(): void {

    this.oldPasswordIsWrong = false;
    this.userLeftInputConfirmPassword = false;
    this.userLeftInputPreviousPassword = false;

    this.formOldPassword = this.formBuilder.group({
      oldPassword: ['', Validators.required],
    });

    this.form = this.formBuilder.group({
      newPassword: [null, [
        // 1\. Password is required
        Validators.required,
        // 2\. Min length of 8 characters
        Validators.minLength(8),
        // 3\. At least one special character
        CustomValidators.patternValidator(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, { hasSpecialCharacters: true}),
        // 4\. At least one Lower case
        CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true}),
        // 5\. At least one number
        CustomValidators.patternValidator(/\d/, { hasNumber: true}),
        // 6\. At least one Upper case
        CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true})
      ]
      ],
      confirmPassword: [null, Validators.required],
    }, {
      validator: MustMatch('newPassword', 'confirmPassword')
    });
  }

  // convenience getter for easy access to first form field
  get fOld() {
    return this.formOldPassword.controls;
  }

  // convenience getter for easy access to second form field
  get f() {
    return this.form.controls;
  }

  // Toggle to visualize old password
  toggleFieldTextType(index: number) {
    this.fieldTextTypeList[index] = !this.fieldTextTypeList[index];
  }

  // Submission of old password form
  onSubmitOldPassword() {

    this.fieldTextTypeList[0] = false;

    if (this.formOldPassword.invalid || this.fOld['oldPassword'].value === '') {
      return;
    }

    this.authenticationService.checkCurrentPassword(this.fOld['oldPassword'].value)
      // .subscribe((_) => {
      .then((_) => {
          this.oldPasswordIsWrong = false;
        })
      .catch(
        (_) => {
          this.oldPasswordIsWrong = true;
          this.userLeftInputPreviousPassword = true;
        }
      );
  }

  confirmationPasswordIsWrong() {
    return (
      (this.f['newPassword'].value !== this.f['confirmPassword'].value) &&
      this.f['confirmPassword'].value !== '' &&
      this.f['newPassword'].value !== ''
    );
  }

  // Submission of new password and confirmation password
  onSubmit() {
    this.submitted = true;
    this.fieldTextTypeList[1] = false;
    this.fieldTextTypeList[2] = false;

    // STOP HERE if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.loading = true;

    this.authenticationService.updatePassword(this.f['newPassword'].value, this.fOld['oldPassword'].value)
      .pipe(first())
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: () => {
          this.toastMessageService.setFinished(true, 'Le mot de passe a bien été modifié');
          this.authenticationService.checkCurrentPassword(this.f['newPassword'].value).then();
          this.ngOnInit();
        },
        error: (error) => {
          console.log(error);
          switch (error) {
            // Error 403 - token is present but expired
            case 'FORBIDDEN' : {
              break;
            }
            case 'NOT FOUND' : {
              break;
            }
            case 'Access unauthorized' : { // case 401
              break;
            }
            // Error 404 (not found) / 500 or others - token is absent
            default : {
              break;
            }
          }
        }
      });

  }
}
