import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SearchService } from '../../shared/services/search.service';
import { ArticleImSearchService } from '../../shared/services/article-im-search.service';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnChanges {
  @Input() nbPages!: number;
  @Input() stats!: boolean;
  @Input() inImpacterView!: boolean;
  currentPage: number;
  listOfPage!: Array<number>;

  maxNbPagesShow = 7;
  maxNbPagesConsecutive = 5;
  constructor(private searchService: SearchService, private articleImSearchService: ArticleImSearchService) {
    this.currentPage = 1;
  }

  ngOnInit(): void {
    this.currentPage = this.searchService.currentPage;

    this.createPageList();

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['nbPages']) {
      this.nbPages = changes['nbPages'].currentValue;
      this.createPageList();
    }
    this.currentPage = this.inImpacterView ? this.articleImSearchService.currentPage : this.searchService.currentPage;
  }

  onPageClick(pageIndex: number): void {
    if (pageIndex === this.currentPage || pageIndex < 1 || pageIndex > this.nbPages) {
      return;
    }
    this.currentPage = pageIndex;
    if (this.inImpacterView) {
      this.articleImSearchService.sentPageIndex(this.currentPage);
    } else {
      this.searchService.sentPageIndex(this.currentPage);
    }
  }

  showNextPrevPage(pageIndex: number): boolean {
    return this.currentPage <= pageIndex + 1 && this.currentPage >= pageIndex - 1;
  }

  showWhenInFirstPages(pageIndex: number): boolean {
    return this.currentPage <= 4 && pageIndex <= this.maxNbPagesConsecutive;
  }

  showWhenInLastPages(pageIndex: number): boolean {
    return this.currentPage >= this.nbPages - 3 && pageIndex >= this.nbPages - this.maxNbPagesConsecutive + 1;
  }

  notShowFirstLastPage(index: number): boolean {
    return index > 0 && index < this.nbPages - 1;
  }

  createPageList(): void {
    this.listOfPage = Array.from({ length: this.nbPages }, (_, i) => i + 1);
  }
}
