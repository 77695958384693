import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
@Directive({
  selector: '[appClickOrEnterConfirmation]'
})
export class ClickOrEnterConfirmationDirective {
  @Output() clickMouseOrPressEnter = new EventEmitter<void>();
  @Output() mouseEnters = new EventEmitter<number>();
  @Output() arrowUpOrDown = new EventEmitter<boolean>();

  @Input() index!: number; // Element index among list.
  @Input() itemsList!: Array<any>;
  @Input() activeIndex!: number;
  mouseTarget!: ElementRef;

  @Output() clickOrPressDebounceDueTimeOutput = new EventEmitter<number>();
  clickOrPressDebounce$: Subject<string> = new Subject<string>();
  clickOrPressDebounceDueTime = 200;

  constructor(private elementRef: ElementRef) {
    this.clickOrPressDebounceDueTimeOutput.emit(this.clickOrPressDebounceDueTime);

    this.clickOrPressDebounce$
      .pipe(debounceTime(this.clickOrPressDebounceDueTime))
      .subscribe((_) => {
        this.clickMouseOrPressEnter.emit();
      });
  }

  @HostListener('click', ['$event.target'])
  public clickByMouse(target: HTMLElement) {
    const isMouseInsideElement = this.elementRef.nativeElement.contains(target);
    if (isMouseInsideElement) {
      this.clickOrPressDebounce$.next('click');
    }
  }

  @HostListener('mouseenter', ['$event'])
  public mouseEnter() {
    this.mouseEnters.emit(this.index);
  }

  @HostListener('document:keydown.enter', ['$event.target'])
  public keydownEnter() {
    const isMouseInsideElement = this.elementRef.nativeElement.contains(this.mouseTarget);
    if (isMouseInsideElement || this.activeIndex === this.index) {
      this.clickOrPressDebounce$.next('keydownEnter');
    }
  }

  @HostListener('document:keydown', ['$event'])
  onArrowKeydown(event: any): void {
    if ((event.code === 'ArrowUp' || event.code === 'ArrowDown') && this.activeIndex === this.index) {
      if (event.code === 'ArrowDown') {
        this.arrowUpOrDown.emit(true); // ArrowDown => indexIncreases ? => true
      } else if (event.code === 'ArrowUp' ) {
        this.arrowUpOrDown.emit(false); // ArrowUp => indexIncreases ? => false
      }
    }
  }
}
