import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EntityService } from '../../../shared/services/entity.service';
import { first } from 'rxjs/operators';
import Utils from '../../../shared/utils';
import { ContextService } from '../../../shared/services/context.service';
import { AdminDocument, ProjectDocumentSourceEnum } from '../../../models/admin-document';
import { ApiService } from '../../../shared/services/api/api.service';
import { EventTypeName } from '../../../models/user-tracker';
import { HttpParams } from '@angular/common/http';
import { ExportAdminDocMetadata } from '../../../models/export-admin-doc-metadata';
import { SharedDocument } from '../../../models/share/share';
import { DocumentSharingTypeEnum, DocumentType } from '../../../models/document-entity';
import { MatchStemsService } from '../../../shared/services/match-stems.service';
import { firstValueFrom, ReplaySubject } from 'rxjs';
import { Project } from '../../../models/territory-watch/project';
import { TerritoryWatchProjectPublicationTypeEnum } from '../../../models/territory-watch-settings';

@Component({
  selector: 'app-admin-doc-view',
  templateUrl: './admin-doc-view.component.html',
  styleUrls: ['./admin-doc-view.component.scss']
})

export class AdminDocViewComponent implements OnInit, OnDestroy {
  @ViewChild('pdfViewContainer') pdfView!: ElementRef;

  adminDocId!: string;
  adminDoc?: AdminDocument;
  adminDocRouteParams = {};
  documentMetaData?: ExportAdminDocMetadata;
  type = DocumentType.ADMIN_DOC;
  trackingEventName: EventTypeName | '' = '';

  isSharingPanelOpen = false;                                            // Managing sharing & export panels
  documentToBeShared!: SharedDocument;

  time!: number;
  currentNavigationState?: { [k: string]: any; };
  project = new Project();
  TerritoryWatchProjectPublicationTypeEnum = TerritoryWatchProjectPublicationTypeEnum;

  constructor(private route: ActivatedRoute,
              private entityService: EntityService,
              private apiService: ApiService,
              private contextService: ContextService,
              private matchStemsService: MatchStemsService,
              private router: Router,
              private utils: Utils
  ) {
    this.currentNavigationState = this.router.getCurrentNavigation()?.extras?.state;
  }

  async ngOnInit() {
    const openSource = this.route.snapshot.data['openSource'];
    const routeQueryParams = this.route.snapshot.queryParams;
    // Set trackingEventName value
    this.setTrackingEventName(openSource);
    // Retrieve all params from activatedRoute
    const params : any = this.utils.getAllParamsFromRoute(this.route);
    this.adminDocRouteParams = params;
    this.adminDocId = params['adminDocId'] || '';
    this.project.id = routeQueryParams['project-uid']; // case alert
    this.documentMetaData = new ExportAdminDocMetadata(                 // adminDocMetadata for export panel
      this.adminDocId,
      openSource
    );
    // Retrieving of document information.
    if (this.adminDocId) {
      this.entityService.sendEntityId(this.adminDocId);
      this.time = (new Date()).getTime();
      await this.fetchData(openSource, params['openSourceId'], params['collectiveOrderId'], params['exportId']).then();
    }
    // To store context on alert / share
    if (params['openSourceId']) {
      // Alert / share management of snippet.
      this.storeSnippet(openSource, params['openSourceId'], params['collectiveOrderId']).then();
    }
    if (params['openSourceId'] || params['exportId']) {
      // Alert / share / export management of match stems.
      this.storeMatchStems(openSource, params['openSourceId'], params['collectiveOrderId'], params['exportId']).then();
    }
  }

  setTrackingEventName(openSource: string): void {
    const routeQueryParams = this.route.snapshot.queryParams;
    // Management of trackingEventName in function of all possible openSource value.
    if (openSource === 'digest' && routeQueryParams['rank']) {
      this.trackingEventName = EventTypeName.DOCUMENT_OPENING_FROM_ALERT;
    }
    if (openSource === 'share') {
      this.trackingEventName = EventTypeName.DOCUMENT_OPENING_FROM_SHARE;
    }
    if (openSource === 'web_app') {
      this.trackingEventName = EventTypeName.DOCUMENT_OPENING_FROM_WEB_APP;
    }
    if (openSource === 'export') {
      this.trackingEventName = EventTypeName.DOCUMENT_OPENING_FROM_EXPORT;
    }
  }

  sharingPanelChangeExpandedState(isSharingPanelOpen: boolean) {
    this.isSharingPanelOpen = isSharingPanelOpen;
  }

  ngOnDestroy(): void {
    this.entityService.sendEntityId(null);
    this.entityService.sendDocument(null);
  }

  retrieveProjectInformations(openSource: string, projectUid: string) {
    const validOpenSource = this.setOpenSourceWebAppOrEmail(openSource);
    const query = `${projectUid}`;
    const params = new HttpParams();
    params.append('open_source', validOpenSource);
    return this.apiService.project.getProjectByUid(query, params).pipe(first()).toPromise();
  }

  async retrieveDocAdmin(openSource: string, openSourceId: string, collectiveOrderId: number) {
    const validOpenSource = this.setOpenSourceWebAppOrEmail(openSource);
    const query = `${this.adminDocId}`;
    let params = new HttpParams();
    params = params.append('open_source', validOpenSource);
    const routeQueryParams = this.route.snapshot.queryParams;
    if (this.trackingEventName === EventTypeName.DOCUMENT_OPENING_FROM_ALERT) { // case alert
      params = params.append('rank', routeQueryParams['rank']);
      params = params.append('article_count', routeQueryParams['article_count']);
      params = params.append('admin_doc_count', routeQueryParams['admin_doc_count']);
      params = params.append('document_type', 'admin_doc');
      params = params.append('collective_order_id', collectiveOrderId);
    }
    if (openSourceId) {  // case alert & share
      params = params.append('open_source_id', openSourceId);
    }
    try {
      return await firstValueFrom(this.apiService.adminDoc.getAdminDoc(query, params, this.trackingEventName));
    } catch (e) {
      console.log(`error when retrieving doc admin: ${e}`);
      return;
    }
  }

  // for tracking purpose
  setOpenSourceWebAppOrEmail(openSource: string): string {
    if (openSource === 'export') {
      openSource = 'web_app';
    }
    if (openSource !== 'web_app') {
      openSource = 'email';
    }
    return openSource;
  }

  async fetchData(openSource: string, openSourceId: string, collectiveOrderId: number, exportId: number) {
    this.adminDoc = await this.retrieveDocAdmin(openSource, openSourceId, collectiveOrderId);
    if (!this.adminDoc) {
      return;
    }
    if (!this.project.id) {
      const storedProjectUid = this.contextService.getStoredProjectUids()?.find((item: any) => item.id === this.adminDocId);
      this.project.id = storedProjectUid ? storedProjectUid['projectUid'] : '';
    }
    // Retrieving inquirers in case or pref-and-mrae project document
    if (this.project.id) {
      const projectInfo = await this.retrieveProjectInformations(openSource, this.project.id)
        .then((value) => {
          return value;
        })
        .catch((e) => {
          console.log(`error when retrieving project informations: ${e}`);
          return undefined;
        });
      if (projectInfo) this.project = projectInfo;
      if (this.adminDoc.publicationType.includes(TerritoryWatchProjectPublicationTypeEnum.ARRETE)) {
        const projectDocuments = await firstValueFrom(this.apiService.project.findProjectDocumentsRelatedToPrefectoralDecree(this.project.id))
          .then((value) => {
            return value;
          })
          .catch((e) => {
            console.log(`error when retrieving project documents related to prefectoral decree: ${e}`);
            return null;
          });
        this.project.updatedAt = projectDocuments?.data[0].publication_date;
        this.project.totalDocumentsCount = projectDocuments?.total_count;
      }
    }
    this.entityService.sendDocument(this.adminDoc);

    const snippet = await this.contextService
      .getContext(this.currentNavigationState, this.adminDocId, openSource, openSourceId, collectiveOrderId);
    if (snippet) {
      this.adminDoc['snippet'] = snippet;
    }

    // Retrieving of match stems & update of subscription.
    const matchStems = await this.matchStemsService
      .getMatchStems(this.currentNavigationState, this.adminDocId, openSource, openSourceId, collectiveOrderId, exportId);
    if(matchStems){
      this.adminDoc.matchStems = matchStems;
    }
    // Checking if document is a project document or not
    const shareType = Object.values(ProjectDocumentSourceEnum).includes(this.adminDoc.source as ProjectDocumentSourceEnum) ?
      DocumentSharingTypeEnum.PROJECT_DOCUMENT : DocumentSharingTypeEnum.ADMIN_DOC;

    const dbSharedDocument: any = {
      document_uid: this.adminDoc.id,
      type: shareType,
      title: this.adminDoc.title,
      snippet: this.adminDoc.snippet,
      publication_date: this.adminDoc.publicationDate,
      displayed_publication_type: this.adminDoc.displayedPublicationType,
      match_stems: this.adminDoc.matchStems,
      territories: this.adminDoc.territories ?? []
    };
    // Add inquirers if mrae-and-pref project document
    if (this.project.id) {
      dbSharedDocument['inquirers'] = this.project.inquirers ?? []; // default value of inquirers is [] as needed by the endpoint
    }

    this.documentToBeShared = new SharedDocument(dbSharedDocument);
  }

  /** Management of contexte - retrieving and persisting */
  async storeSnippet(openSource: string, openSourceId: string, collectiveOrderId: number) {
    const snippet = await this.contextService
      .getContext(this.currentNavigationState, this.adminDocId, openSource, openSourceId, collectiveOrderId);
    this.contextService.setSnippetWithExpiry(this.adminDocId, snippet);
  }

  /** Management of match_stems - retrieving and persisting */
  async storeMatchStems(openSource: string, openSourceId: string, collectiveOrderId: number, exportIdString: string) {
    const exportId = +exportIdString;
    const matchStems = await this.matchStemsService
      .getMatchStems(this.currentNavigationState, this.adminDocId, openSource, openSourceId, collectiveOrderId, exportId);
    if(matchStems){
      this.matchStemsService.setMatchStemsWithExpiry(this.adminDocId, matchStems);
    }
  }

  bookmarkPathsUpdated(id: string, event: Array<any>) {
    this.entityService.sendBookmarkPaths(id, event);
  }

}
