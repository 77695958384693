import { AfterViewInit, Component, Input } from '@angular/core';
import { ArticleImSearchService } from '../../shared/services/article-im-search.service';
import { ContextService } from '../../shared/services/context.service';
import { EventTypeName } from '../../models/user-tracker';
import { first } from 'rxjs/operators';
import { UserTrackerService } from '../../shared/services/tracking/user-tracker.service';

@Component({
  selector: 'app-article-card',
  templateUrl: './article-card.component.html',
  styleUrls: ['./article-card.component.scss']
})
export class ArticleCardComponent implements AfterViewInit {

  @Input() docId!: string;
  @Input() allowOnClick = true;
  @Input() title!: string;
  @Input() publicationDate!: Date | null;
  @Input() nbOccurrences?: number;
  @Input() showOccurrences = true;
  @Input() source?: string;
  @Input() text?: string;
  @Input() territories?: Array<any>;
  @Input() isPreview = false;
  @Input() folderId!: number;
  @Input() displayBookmarkCreationDate = false;
  @Input() bookmarkPaths: any;
  @Input() photoCount?: number;
  @Input() impacterId: string | null = null;

  displayReadMore = false;

  constructor(
    private articleImSearchService: ArticleImSearchService,
    private contextService: ContextService,
    private userTrackerService: UserTrackerService,
  ) {
  }

  ngAfterViewInit() {
    const mightOverflowElement = document.getElementById('territory-list-' + this.docId);

    // If the displayed list of territories takes more space than allowed.
    this.displayReadMore = !!mightOverflowElement && (mightOverflowElement.offsetWidth < mightOverflowElement.scrollWidth);

    if (this.impacterId) {
      this.articleImSearchService.getSearch().subscribe((search: any) => {
        this.showOccurrences = !!search['text'];
      });
    }
  }

  onClickCard(event: any) {
    if(!this.text) return;
    document.dispatchEvent(new CustomEvent(event, {cancelable: true}));
    this.contextService.setSnippetWithExpiry(this.docId, this.text);
  }

  onReadMoreClick(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
  }

  territoriesList(): string | undefined {
    return this.territories?.map((territory: any) => territory['name']).join('  |  ');
  }

  trackClickedArticle() {
    const userId = localStorage.getItem('user_id');
    if (this.impacterId && userId) {
      this.userTrackerService.track({
        event_type: EventTypeName.ARTICLE_OPENING_FROM_IMPACTER,
        event_timestamp: (new Date()).toISOString(),
        user_id: +userId,
        email: localStorage.getItem('email'),
        impacter_id: +this.impacterId,
        article_id: this.docId,
        article_url: window.location.href + '/' + this.docId
      }).pipe(first()).subscribe();
    }
  }
}
