{
  "data": [
    {
      "text": "all-roles",
      "isRoot": true,
      "children": [
        {
          "text": "department",
          "children" :[
            {
              "text": "prefet",
              "value": "prefet"
            },
            {
              "text": "senateur",
              "value": "senateur"
            },
            {
              "text": "depute",
              "value": "depute"
            },
            {
              "text": "president",
              "value": "president-departemental"
            },
            {
              "text": "vice-president",
              "value": "vp-departemental"
            },
            {
              "text": "membre-conseil-departemental",
              "value": "membre-departemental"
            }
          ]
        },
        {
          "text": "epci",
          "children" :[
            {
              "text": "president",
              "value": "president-epci"
            },
            {
              "text": "vice-president",
              "value": "vp-epci"
            },
            {
              "text": "membre-conseil-communautaire",
              "value": "membre-epci"
            }
          ]
        },
        {
          "text": "town",
          "children" :[
            {
              "text": "maire",
              "value": "maire"
            },
            {
              "text": "adjoint",
              "value": "adjoint"
            },
            {
              "text": "membre-conseil-municipal",
              "value": "membre-municipal"
            }
          ]
        }
      ]
    }
  ]
}
