import { Subscription } from 'rxjs';
import { SearchService } from '../../shared/services/search.service';
import { EntityService } from '../../shared/services/entity.service';
import { Entity } from '../entity/entity';
import { ArticleImSearchService } from '../../shared/services/article-im-search.service';
import { debounceTime } from 'rxjs/operators';

export abstract class Content extends Entity {
  textSearch = '';
  searchSubscription!: Subscription;
  currentRequest!: Subscription;
  protected constructor(protected searchService: SearchService,
                        protected override entityService: EntityService,
                        protected articleImSearchService: ArticleImSearchService) {
    super(entityService);
  }

  getSearch(ignoreTextEmpty = true): void {
    if (ignoreTextEmpty) {
      this.getSearchService(this.searchService, true);
    } else {
      this.getSearchService(this.articleImSearchService, false);
    }
  }

  getSearchService(searchService: SearchService, ignoreTextEmpty: boolean) {
    this.searchSubscription = searchService.getSearch()
      .pipe(debounceTime(100)) // Debounce time for limiting multiple calls.
      .subscribe((search: any) => {
        if (!search['text'] && ignoreTextEmpty || !search['filters']) {
          this.totalCount = null;
          this.entityService.sendTotalCount(this.totalCount);
        } else {
          const filter = JSON.parse(JSON.stringify(search));
          delete filter['filters']['titles'];
          delete filter['filters']['with_old_position'];
        if (search['filters']['territories'].length !== 0) {
            this.retrieveData(filter);
        } else {
          this.totalCount = 0;
          this.entityService.sendTotalCount(this.totalCount);
        }
        }
      });
  }

  abstract retrieveData(search: object): void;

  protected request(sub: Subscription) {
    this.currentRequest?.unsubscribe();
    this.currentRequest = sub;
  }

  buildBody(search: any): any {
    delete search['filters']['resources_count'];
    const sources = search['filters']['territories_kind'];
    delete search['filters']['territories_kind'];
    search['filters']['source'] ||= sources;
    const offset = search['offset'];
    this.textSearch = search['text'];
    return {
      filters: {
        ...search['filters'],
        text: this.textSearch
      },
      sort: search['sortBy'],
      limit: search['limit'],
      offset: offset,
      perimeter: search['perimeter']
    };
  }
  buildCountBody(search: any): object {
    return {
      filters: {
        territories: search.filters.territories
      },
      service: 'count'
    };
  }
}
