<div class="d-table-cell label-occurrence align-self-center justify-content-center align-content-center"
     *ngIf="showOccurrences && nbOccurrences && (nbOccurrences > 1)">
  {{nbOccurrences}} {{ 'content.occurrences' | translate}}
</div>
<div *ngIf="showFolder && bookmarkPaths && bookmarkPaths.length > 0" class="label-folder d-flex align-items-center">
  <ng-container *ngIf="showCreationDate">
    <span class="creation-date">{{'folder.added-at' | translate}} {{bookmarkPaths | bookmarkCreatedAt: folderId | date:'dd MMMM yyyy':'':'fr'}}</span>
  </ng-container>
  <div class="folder-icon-container">
    <mat-icon class="folder-icon" svgIcon="saved-folder-icon-green"></mat-icon>
  </div>
</div>
