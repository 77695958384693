import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SearchService } from '../../shared/services/search.service';
import { Entity } from '../../core/entity/entity';
import { EntityService } from '../../shared/services/entity.service';
import { Subscription } from 'rxjs';
import { auditTime, first } from 'rxjs/operators';
import { PublicationSource } from '../search/publication-source';
import { LoaderService } from '../../common-explain/services/loader.service';
import { ApiService } from '../../shared/services/api/api.service';

@Component({
  selector: 'app-impacters-page',
  templateUrl: './impacters-page.component.html',
  styleUrls: ['./impacters-page.component.scss']
})
export class ImpactersPageComponent extends Entity implements OnInit, OnDestroy {
  @ViewChild('impacter') impacterElement!: ElementRef;
  private filteredCount!: number;
  public suggestionCount!: number;
  public impacterId: string | null = null;
  public impacterPageId = 'impacterId';

  /** Subscriptions to services */
  private getSearchSubscription!: Subscription;
  getEntityIdSubscription!: Subscription;
  retrieveImpactersSubscription!: Subscription;

  constructor(
    private apiService: ApiService,
    private searchService: SearchService,
    protected override entityService: EntityService,
    private loaderService: LoaderService
    ) {
    super(entityService);
  }

  ngOnInit(): void {

    this.getSearchSubscription =
      this.searchService.getSearch()
        .pipe(auditTime(100))
        .subscribe((search: any) => {
        if (!search['filters']) {
          return;
        }
        const body: any = this.buildBody(search);
          if (body['filters']['territories'].length === 0) {
            this.totalCount = 0;
            this.entityService.sendTotalCount(this.totalCount);
            return;
          }
            this.retrieveImpactersSubscription =
          this.apiService.impacter.retrieveImpacters(body)
            .pipe(first())
            .subscribe(
          (response) => {
            this.impacterElement.nativeElement.scrollTo({
              top: 0
            });
            // Here, there a need to burst our RetrieveImpactersTypedResponseBody object to match the generic format of Entities.
            this.setEntities(
              {
                data: response.impacters,
                total_count: response.totalCount,
                filtered_count: response.filteredCount
              },
              search['limit'],
              search['offset']);
            this.filteredCount = response.filteredCount;
            if(this.totalCount) this.suggestionCount = this.totalCount - this.filteredCount;
            this.entityService.sendTotalCount(response.filteredCount);
          },
          _ => {
            this.totalCount = 0;
            this.entityService.sendTotalCount(this.totalCount);
            this.loaderService.hideLoader(true);
          });
      });

    this.getEntityIdSubscription =
      this.entityService.getEntityId().subscribe(impacterId => {
        this.impacterId = impacterId;
      });

    // ADD event listener to store the current scrollTop value in the adminDoc component.
    setTimeout(() => {
      (document.getElementById(this.impacterPageId) as HTMLElement).addEventListener(
        'scroll',
        () => {
          this.scrollHandler();
        });
    }, 300);
  }

  scrollHandler() {
    const currentScroll = document.getElementById(this.impacterPageId)?.scrollTop;
    // Set value of currentScroll in storing map
    const currentOffset = this.searchService.SearchOffsetMap.get(PublicationSource.OTHERS);
    const currentSearchMode = this.searchService.SearchModeMap.get(PublicationSource.OTHERS);
    if(currentOffset && currentSearchMode && currentScroll) currentOffset.set(currentSearchMode, currentScroll);
  }

  ngOnDestroy() {
    /** Unsubscribe of subscriptions */
    if (this.getSearchSubscription) { this.getSearchSubscription.unsubscribe(); }

    /** Unsubscribe of sucription */
    if (this.getEntityIdSubscription) { this.getEntityIdSubscription.unsubscribe(); }
    if (this.retrieveImpactersSubscription) { this.retrieveImpactersSubscription.unsubscribe(); }

    if (document.getElementById(this.impacterPageId)) {
      (document.getElementById(this.impacterPageId) as HTMLElement).removeEventListener('scroll', () => { this.scrollHandler(); });
    }

  }

  /** Overriding abstact method buildBody declared in Entity */
  buildBody(search: any): object {
    const searchJson = JSON.parse(JSON.stringify(search));
    delete searchJson['filters']['territories_kind'];
    delete searchJson['filters']['resources_count'];
    delete searchJson['filters']['period'];
    delete searchJson['filters']['source'];
    delete searchJson['filters']['publication_type'];
    return {
      filters: {
        ...searchJson['filters'],
        text: searchJson['name']
      },
      limit: searchJson['limit'],
      offset: searchJson['offset'],
      perimeter: searchJson['perimeter'],
      sort: !searchJson['name'] ? {
        dir: 'asc',
        field: 'fullname.raw'
      } : undefined
    };
  }
}
