<div class="carousel-container"
     [ngClass]="{
        'carousel-container': true,
        'one-image': images.length === 1,
        'dialog': zoom,
        'guest-display': guestDisplay
     }"
     (keyup.escape)="handleZoom.emit(false)"
>
  <ngb-carousel #carouselSelector *ngIf="images.length">
    <ng-template ngbSlide *ngFor="let image of images">
      <div class="image-container">
        <img class="carousel-image"
             [src]="image.src"
             appImageFallback
             (click)="handleZoom.emit(true)"
             alt="article's image">
      </div>
      <div class="carousel-caption">
        <span>{{image.caption}}</span>
      </div>
    </ng-template>
  </ngb-carousel>
  <button class="icon-button" (click)="handleZoom.emit(false)">
    <mat-icon class="icon-40 fill-basic-800" svgIcon="close-circle-fill"></mat-icon>
  </button>
</div>
