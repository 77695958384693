<nav class="menu d-flex flex-column text-center">
  <div class="flex-grow-1">
    <div class="menu-body flex-grow-1"
         [ngClass]="{'tender-page': isTendersPage, 'local-data-page': !isTendersPage}"
         routerLinkActive
         #rla="routerLinkActive"
    >
      <img [routerLink]="isTendersPage ? ['/tenders'] : ['/']"
           alt="menu logo" class="logo"
           [src]="isTendersPage ? '/assets/images/logo-explain-for-blue-background.svg' :
                                  '/assets/images/logo-picto-explain-2023-no-bg.svg'"
           title="retour à l'accueil"
      />
      <div id="navbar-menu-icons" class="no-select menu-content position-relative"
           [ngClass]="{'tender-page-icon': isTendersPage, 'local-data-page-icon': !isTendersPage}">
        <div id="local-data-section" class="menu-section">
          <div class="menu-item icon-menu icon-menu--search" [routerLink]="['/search']"
               ngbTooltip="{{'menu.search-tooltip' | translate}}"
               routerLinkActive="router-link-active"
               placement="right" tooltipClass="custom-tooltip" triggers="hover"></div>
          <div class="menu-item icon-menu icon-menu--folder" [routerLink]="['/folder']"
               ngbTooltip="{{'menu.folder-tooltip' | translate}}"
               routerLinkActive="router-link-active"
               placement="right" tooltipClass="custom-tooltip" triggers="hover"></div>
          <div class="menu-item icon-menu icon-menu--bell" [routerLink]="['/alert']"
               ngbTooltip="{{'menu.alert-tooltip' | translate}}"
               routerLinkActive="router-link-active"
               placement="right" tooltipClass="custom-tooltip" triggers="hover"></div>
          <div class="menu-item icon-menu icon-menu--message" [routerLink]="['/topics']"
               ngbTooltip="{{'menu.topic-tooltip' | translate}}"
               routerLinkActive="router-link-active"
               placement="right" tooltipClass="custom-tooltip" triggers="hover"></div>
        </div>
      </div>

      <div class="text-center menu-content">
        <div appClickOutside
             id="account"
             [style.background-color]="isTendersPage ? 'var(--basic-500)' : 'var(--primary-300)'"
             class="menu-item menu-body-footer icon-menu icon-menu--person position-absolute"
             (clickOutside)="displayPersonalSettings = false"
             (click)="displayPersonalSettings = !displayPersonalSettings">
        </div>

      </div>
    </div>
  </div>

<!-- POP up of personal settings -->
  <div *ngIf="displayPersonalSettings"
       class="personnal-settings">
    <div class="round-top-corner"></div>
    <div class="btn-container">
      <div (click)="displayPersonalSettings=false" [routerLink]="['/settings']" class="btn settings cartridge">
        <span class="btn-text">{{'menu.settings' | translate}}</span>
        <img class="personnal-settings-icon" src="./../../../assets/images/icon-settings.svg" alt="icon-settings">
      </div>
      <div class="btn logout cartridge" (click)="onLogOut()">
        <span class="btn-text">{{'menu.logout' | translate}}</span>
        <img class="logout-icon" src="./../../../assets/images/power-icon.svg" alt="logout-icon">
      </div>
    </div>
    <div class="round-bottom-corner"></div>
  </div>

</nav>
