<div *ngIf="!showLoader && !totalArticleCount" class="no-result d-flex">
  <div class="icon align-self-center"></div>
  <div class="fw-bold message align-self-center">
    {{'content.no-result-article-impacter-message' | translate}}
  </div>
</div>
<app-loader *ngIf="showLoader" [show]="true" [center]="true"></app-loader>
<div class="page-content" [hidden]="!totalArticleCount">
  <app-filter [hidden]="inArticleView" currentPage="article" [territoryUid]="territoryUid" [inImpacterView]="true"></app-filter>
  <div class="right-content" [ngClass]="{'width-fix': !inArticleView}">
    <app-search *ngIf="!inArticleView" currentPage="article" [haveTip]="true" [inImpacterView]="true"></app-search>
    <app-task-bar *ngIf="!inArticleView" currentPage="article" [resultCount]="totalCount" [inImpacterView]="true"></app-task-bar>
    <div class="list-entities">
      <app-articles-page [impacterId]="impacterId"></app-articles-page>
    </div>
  </div>
</div>
