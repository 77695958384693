import { EntityService } from '../../shared/services/entity.service';

export abstract class Entity {
  entities!: Array<any>;
  totalCount: number | null = null;
  adminDocTotalCount!: number;
  nbPages = 0;
  dataUpdated = false;
  protected constructor(protected entityService: EntityService) {
  }

  abstract buildBody(search: object): object;

  setEntities(data: any, limit: number, offset: number): void {
    this.entities = data['data'];
    this.totalCount = data['total_count'];
    this.adminDocTotalCount = data['adminDocTotalCount'];
    if(this.totalCount) {
      this.nbPages = Math.ceil(this.totalCount / limit);
    }
    this.dataUpdated = !this.dataUpdated;
  }
}
