import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserSessionService } from "../services/user-session.service";

@Injectable()
export class IsLoggedInRedirectResolver {
  constructor(
    private router: Router,
    private userSessionService: UserSessionService
  ) { }

  resolve(): void {
    const userSession = this.userSessionService.userSession;
    const explainToken = this.userSessionService.explainToken;
    if (userSession || explainToken) {
      this.router.navigate(['/']).then();
    }
  }
}
