<div class="wizard-container">

  <app-steps [alertId]="alertId" class="no-select">
    <div topLeftPart class="align-self-center d-flex wizard-header">
      <div class="align-self-center d-flex cancel-button"
           (click)="openModal()">
        <div class="align-self-center icon-wizard icon-wizard--close"></div>
        <div class="align-self-center">{{'button.cancel' | translate | uppercase}}</div>
      </div>
    </div>
    <div topRightPart class="top-right-part align-self-center d-flex wizard-header">
      <button class="primary-explain-button mgl-16px align-self-center align-items-center d-flex"
              data-cy="digest-submit-button"
              appPreventDoubleClick
              (throttledClick)="onSubmit()"
              [disabled]="!canBeSaved">
        <div class="fw-700 font-14px">{{'button.create-alert' | translate | uppercase}}</div>
      </button>
    </div>
  </app-steps>

  <div class="step-page-container">
    <div [hidden]="currentStep?.stepIndex !== 0"
         class="form-step">
      <app-step-header class="no-select"
                       [myClass]="'form-header-container'"
                       [attr.data-cy]="'wizard-header-container--perimeters'"
                       [step]="steps?.[0]"
                       [alertId]="alertId"
                       [maxSelectionValue]="maxValueDepartments"
                       [currentSelectionValue]="currentValueDepartments"
                       [selectionComplexityLimitationValue]="complexityLimiters?.department_limitation"
                       [valueName]="'wizard.perimeters'"
                       [stepTitle]="'wizard.perimeter-step-title'"
                       [awaitingSubmission]="awaitingSubmission"
                       [stepSubTitle]="complexityLimiters && (maxValueDepartments <= complexityLimiters.department_limitation) ?
                       'wizard.perimeter-step-sub-title' : 'wizard.perimeter-step-limiter-sub-title'"
                       [stepSubTitleEmptySelection]="complexityLimiters && (maxValueDepartments <= complexityLimiters.department_limitation) ?
                       'wizard.perimeter-step-sub-title-empty' : 'wizard.perimeter-step-limiter-sub-title-empty'">
      </app-step-header>
      <div class="step-container">
        <app-perimeter-step-template (totalDepartments)="onRetrieveProgressBarMaxValue($event)"
                                     (selectedDepartments)="onRetrieveProgressBarCurrentValue($event)"
                                     [isComponentHidden]="(currentStep$ | async)?.stepIndex !== 0"
                                     [maxDepartmentNumber]="complexityLimiters?.department_limitation ?? 0">
        </app-perimeter-step-template>
      </div>
    </div>

    <div [hidden]="currentStep?.stepIndex !== 1"
         class="form-step">
      <app-step-header class="no-select"
                       [myClass]="'form-header-container'"
                       [attr.data-cy]="'wizard-header-container--topics'"
                       [alertId]="alertId"
                       [step]="steps?.[1]"
                       [maxSelectionValue]="maxValueTopics"
                       [currentSelectionValue]="currentValueTopics"
                       [selectionComplexityLimitationValue]="complexityLimiters?.topics_limitation"
                       [valueName]="'wizard.topics'"
                       [stepTitle]="'wizard.topics-step-title'"
                       [awaitingSubmission]="awaitingSubmission"
                       [stepSubTitle]="complexityLimiters && (maxValueTopics <= complexityLimiters.topics_limitation) ?
                                       'wizard.topics-step-sub-title' : 'wizard.topics-step-limiter-sub-title'"
                       [stepSubTitleEmptySelection]="complexityLimiters && (maxValueTopics <= complexityLimiters.topics_limitation) ?
                                       'wizard.topics-step-sub-title-empty' : 'wizard.topics-step-limiter-sub-title-empty'">
      </app-step-header>
      <div class="step-container">
        <app-topic-step-template (totalTopics)="onRetrieveProgressBarMaxValue($event)"
                                 (selectedTopics)="onRetrieveProgressBarCurrentValue($event)"
                                 [limiterAchieved]="complexityLimiters && currentValueTopics >= complexityLimiters.topics_limitation">
        </app-topic-step-template>
      </div>
    </div>

    <div [hidden]="currentStep?.stepIndex !== 2"
         class="form-step">
      <app-step-header class="no-select"
                       [myClass]="'form-header-container'"
                       [attr.data-cy]="'wizard-header-container--sources'"
                       [alertId]="alertId"
                       [step]="steps?.[2]"
                       [stepTitle]="'wizard.sources-step-title'"
                       [awaitingSubmission]="awaitingSubmission"
                       [stepSubTitle]="'wizard.sources-step-sub-title'"
                       [stepSubTitleEmptySelection]="'wizard.sources-step-sub-title-empty'">
      </app-step-header>

      <div class="step-container">
        <app-source-step-template>
        </app-source-step-template>
      </div>
    </div>

    <div [hidden]="currentStep?.stepIndex !== 3"
         class="form-step">
      <app-step-header class="no-select"
                       [myClass]="'form-header-container'"
                       [attr.data-cy]="'wizard-header-container--parameters'"
                       [alertId]="alertId"
                       [step]="steps?.[3]"
                       [stepTitle]="'wizard.parameters-step-title'"
                       [awaitingSubmission]="awaitingSubmission"
                       [stepSubTitle]="'wizard.parameters-step-sub-title'"
                       [stepSubTitleEmptySelection]="'wizard.parameters-step-sub-title'"
      ></app-step-header>
      <app-frequent-step-template [alertId]="alertId"
                                  (otherUserAlerts)="updateOtherUserAlerts($event)">
      </app-frequent-step-template>
    </div>

    <div *ngIf="currentStep?.stepIndex === 4"
         class="form-step">
      <app-step-header *ngIf="complexityLimiters &&
                              nbPreviewedDocuments < complexityLimiters.warning_content_threshold &&
                              load"
                       id="app-step-header-alert-overview"
                       class="no-select"
                       [myClass]="'form-header-container'"
                       [alertId]="alertId"
                       [step]="steps?.[0]"
                       [otherUserAlerts]="otherUserAlerts"
                       [stepTitle]="'wizard.overview-step-title'"
                       [awaitingSubmission]="awaitingSubmission"
                       [stepSubTitle]="'wizard.overview-step-sub-title'"
                       [stepSubTitleEmptySelection]="'wizard.overview-step-sub-title'">
      </app-step-header>

      <app-step-header *ngIf="complexityLimiters &&
                              nbPreviewedDocuments >= complexityLimiters.warning_content_threshold &&
                              nbPreviewedDocuments <= complexityLimiters.alert_content_threshold &&
                              load"
                       class="no-select"
                       [warning]=true
                       [myClass]="'form-header-container-warning'"
                       [alertId]="alertId"
                       [otherUserAlerts]="otherUserAlerts"
                       [stepTitle]="'wizard.overview-step-title-warning'"
                       [awaitingSubmission]="awaitingSubmission"
                       [stepSubTitle]="'wizard.overview-step-sub-title-warning'"
                       [stepSubTitleEmptySelection]="'wizard.overview-step-sub-title-warning'">
      </app-step-header>

      <app-step-header *ngIf="complexityLimiters &&
                              nbPreviewedDocuments > complexityLimiters.alert_content_threshold &&
                              load"
                       class="no-select"
                       [error]=true
                       [myClass]="'form-header-container-error'"
                       [alertId]="alertId"
                       [otherUserAlerts]="otherUserAlerts"
                       [stepTitle]="'wizard.overview-step-title-error'"
                       [awaitingSubmission]="awaitingSubmission"
                       [stepSubTitle]="'wizard.overview-step-sub-title-error'"
                       [stepSubTitleEmptySelection]="'wizard.overview-step-sub-title-error'">
      </app-step-header>

      <div id="overviewStepContainer" #overviewStepContainer
           class="step-container"
           (scroll)="onOverviewResultsScroll()">

        <app-overview-step-template
          (nbPreviewedDocuments)="setNbPreviewedDocuments($event)"
          (overviewStepIsDestroyed)="lastStepDestroyed()"
          [isScrolledDownToShowTheAbstractHeader]="isScrolledDownToShowTheAbstractHeader"
          [previewsListScrollValue]="previewsListScrollValue">
        </app-overview-step-template>

      </div>

    </div>

  </div>

</div>

