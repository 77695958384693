import { LoaderdialogComponent } from '../loaderdialog/loaderdialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../../shared/services/api/api.service';
import { DbTopic } from '../../models/topic';
import { ExplainAppAccess } from '../../models/explain-app-access';

@Component({
  selector: 'app-admin-topic',
  templateUrl: './admin-topic.component.html',
  styleUrls: ['./admin-topic.component.scss']
})
export class AdminTopicComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    private router: ActivatedRoute,
    private apiService: ApiService,
    private route: Router) { }
  token: string | null = null;
  method: string | null = null;
  topicId: string | null = null;
  ExplainAppAccess = ExplainAppAccess;

  dialogRef!: MatDialogRef<LoaderdialogComponent>;
  ngOnInit(): void {
    this.token = this.router.snapshot.params['token'];
    this.method = this.router.snapshot.params['method'];
    this.topicId = this.router.snapshot.params['topicId'];
    if (this.method?.toLocaleLowerCase() === 'post' && this.topicId !== '0') {
      this.route.navigateByUrl('/login').then();
    }
  }

  onReceivedTopic(topic: DbTopic) {
    this.putTopic(topic);
  }

  openDialog(msg: string): void {
    this.dialogRef = this.dialog.open(LoaderdialogComponent, {
      width: '250px',
      data: msg,
      disableClose: true
    });
    this.dialogRef.afterClosed().subscribe(_ => {
      console.log('The dialog was closed');
    });

  }

  /** Update of a Topic from the CSM */
  putTopic(topic: DbTopic) {
    this.openDialog('Loading ...');
    if (this.method?.toLocaleLowerCase() === 'post' && this.token) {
      delete topic.id;
      this.apiService.topic.insertTopic(topic, this.token).subscribe(_ => {
        this.dialogRef.close();
        this.openDialog('Les modifications ont bien été enregistrées. Aucune redirection n’est activée depuis cette page,' +
          'pour retourner à la liste des thèmes, utilisez le menu.');
      }, err => {
        this.dialogRef.close();
        console.error(err);
      });
    } else {
      if (this.method?.toLocaleLowerCase() === 'put') {
        this.apiService.topic.updateTopic(topic, this.router.snapshot.params['token']).subscribe((val) => {
          this.dialogRef.close();
          this.openDialog('Les modifications ont bien été enregistrées');
          console.log(val);
        }, err => {
          this.dialogRef.close();
          console.error(err);
        });
      }
    }
  }

}
