import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { RegexpSearch } from '../../shared/helpers/regex';
import { ApiService } from '../../shared/services/api/api.service';
import { DisplayedTerritoryWatch } from '../../models/territory-watch';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-territory-watches-page',
  templateUrl: './territory-watches-page.component.html',
  styleUrls: ['./territory-watches-page.component.scss']
})
export class TerritoryWatchesPageComponent implements OnInit {

  territoryWatches!: Array<DisplayedTerritoryWatch>;
  filteredTerritoryWatches!: Array<DisplayedTerritoryWatch>;
  filterTerritoryWatchNameFormControl = new FormControl() ;
  showClearIcon = false;

  constructor(
    private apiService: ApiService,
  ) {
  }

  ngOnInit(): void {

    const userId = localStorage.getItem('user_id');
    const fields: Array <string> = ['id', 'name', 'users', 'is_obsolete', 'created_at', 'updated_at'];
    const filters: object = { 'user_ids': [parseInt(userId || '', 10)] };

    this.apiService.territoryWatch.getTerritoryWatches(fields, filters)
      .pipe(first())
      .subscribe((retrievedTerritoryWatches) => {
        this.territoryWatches = this.apiService.territoryWatch.addCurrentUserToTerritoryWatches(retrievedTerritoryWatches);
        this.filteredTerritoryWatches = this.territoryWatches;
    });

    this.filterTerritoryWatchNameFormControl.valueChanges
      .subscribe(searchText => {
        if ((searchText != null) && (searchText.length > 0)) {
          this.filterTerritoryWatch(searchText);
          this.showClearIcon = true;
        } else {
          this.resetFilteredTerritoryWatch();
          this.showClearIcon = false;
        }
    });
  }

  clearText() {
    this.filterTerritoryWatchNameFormControl.setValue('') ;
  }

  filterTerritoryWatch(searchText: string) {
    this.filteredTerritoryWatches = this.territoryWatches.filter(item => RegexpSearch.normalizedSearch(item.name, searchText));
  }

  resetFilteredTerritoryWatch() {
    this.filteredTerritoryWatches = this.territoryWatches;
  }

  onSelectChange(value: any): void {
    const { itemId, isActive } = value;
    this.apiService.userTerritoryWatches.switchTerritoryWatchActiveState(itemId, isActive).subscribe(
      () => {},
      error => {
        console.log(error);
      }
    );
  }

  renameTerritoryWatch(value: DisplayedTerritoryWatch): void {
    this.apiService.territoryWatch.updateTerritoryWatch(value)
      .toPromise().then();
  }

  deleteTerritoryWatch(value: any): void {
    this.apiService.territoryWatch.deleteTerritoryWatch(value)
      .toPromise().then(
      () => {},
      error => {
        console.log(error);
      }
    );
  }
}
