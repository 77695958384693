import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { StepModel } from '../../core/step/step.model';
import { ActivatedRoute, Router } from '@angular/router';
import { FunnelContextEnum, StepsService } from '../../shared/services/steps.service';
import { TerritoryWatchSettingService } from '../../shared/services/territory-watch-setting.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmationComponent } from '../modal-confirmation/modal-confirmation.component';
import { ToastMessageService } from '../../shared/services/toast-message.service';
import { first } from 'rxjs/operators';
import { TerritoryWatchSettings } from '../../models/territory-watch-settings';
import { ApiService } from '../../shared/services/api/api.service';
import { JsonGettersService } from '../../shared/services/json-getters.service';
import { DbTerritoryWatchWithUsers } from '../../models/territory-watch';
import { SourceStepService } from '../source-step-template/source-step.service';
import frequency from '../../components/frequent-step-template/days.json'
import { UserTrackerService } from "../../shared/services/tracking/user-tracker.service";

interface ComplexityLimiters {
  department_limitation: number;
  topics_limitation: number;
  topic_complexity_limitation: number;
  warning_content_threshold: number;
  alert_content_threshold: number;
}

@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss']
})

export class WizardComponent implements OnInit, OnDestroy {
  currentStep$: Observable<StepModel> = this.stepsService.getCurrentStep$();
  alertId?: string;
  nbPreviewedDocuments: number = 0;
  load = true;
  currentStep: StepModel | null = null;

  maxValueDepartments = 0;
  currentValueDepartments = 0;
  maxValueTopics = 0;
  currentValueTopics = 0;
  complexityLimiters?: ComplexityLimiters;
  steps?: StepModel[];
  subscription = new Subscription();
  otherUserAlerts: DbTerritoryWatchWithUsers[] = [];
  modalOptions = { backdrop: 'static', keyboard: false, ariaLabelledBy: 'modal-basic-title', centered: true } as NgbModalOptions;

  @ViewChild('overviewStepContainer') overviewStepContainer!: ElementRef;
  isScrolledDownToShowTheAbstractHeader = false;
  previewsListScrollValue = 0;
  awaitingSubmission = false;

  constructor(private stepsService: StepsService,
              private router: Router,
              private modalService: NgbModal,
              private jsonGettersService: JsonGettersService,
              private apiService: ApiService,
              private alertSettingService: TerritoryWatchSettingService,
              private sourceStepService: SourceStepService,
              private route: ActivatedRoute,
              private toastMessageService: ToastMessageService,
              private userTrackerService: UserTrackerService) {}


  ngOnInit(): void {
    // Reset steps
    this.stepsService.resetSteps();

    // Management of cases alert creation and modification.
    this.route.params
      .pipe(first())
      .subscribe((params) => {
        this.alertId = params['alertId'];
        if (this.alertId) {
          this.stepsService.funnelContext = FunnelContextEnum.MODIFICATION;
          // Retrieving settings.
          this.apiService.territoryWatch.getTerritoryWatchSetting(+this.alertId)
            .pipe(first())
            .subscribe((data) => {
              this.alertSettingService.setTerritoryWatchSettings(data);
            });
        } else {
          this.stepsService.funnelContext = FunnelContextEnum.CREATION;
          this.alertSettingService.reset();
          this.alertSettingService.territoryWatchSettings.selectedDayOfWeek = frequency.days;
        }
      });

    // Management of funnelContext in case of alert duplication.
    this.route.queryParams
      .pipe(first())
      .subscribe((queryParams) => {
        const { context, funnelContext, alertId, fromAlertId } = queryParams;
        this.sourceStepService.isFromAlert = (context?.trim() === 'remove_source_from_alert');
        if (funnelContext) { // only in case of duplication.
          if (!alertId) return;
          this.alertId = alertId;
          this.stepsService.funnelContext = FunnelContextEnum.DUPLICATION;
          this.stepsService.duplicatedFromAlertId = +fromAlertId;
          // Retrieving settings.
          this.apiService.territoryWatch.getTerritoryWatchSetting(+alertId)
            .pipe(first())
            .subscribe((data: TerritoryWatchSettings) => {
              this.alertSettingService.setTerritoryWatchSettings(data);
              this.stepsService.duplicatedFromAlertSettings = data;
            });
        }
      });

    this.subscription.add(
      this.stepsService.getSteps$()
        .subscribe(steps => {
          this.steps = steps;
        })
    );

    // retrieve of current step value from stepsService
    this.subscription.add(
      this.stepsService.getCurrentStep$()
        .subscribe((currentStep) => {
          this.load = this.stepsService.arePreviewsRetrieved;
          this.currentStep = currentStep;
        })
    );

    this.toastMessageService.setFinished(false);

    // No need to add on subscription here thanks to the pipe(first())
    this.jsonGettersService.getComplexityLimiters()
      .pipe(first())
      .subscribe(complexityLimiters => {
        this.complexityLimiters = complexityLimiters;
      });
  }

  lastStepDestroyed() {
    this.scrollAlertPreviewsResultsUp();
  }

  onOverviewResultsScroll() {
    // Note that 176px is the height of the thin abstract overview panel.
    // This value will need to be changed when increasing number of sources.
    this.previewsListScrollValue = this.overviewStepContainer.nativeElement.scrollTop;
    this.isScrolledDownToShowTheAbstractHeader = this.previewsListScrollValue > 176;
    // NB to add another issuer count to the abstract, add the corresponding size here (176px) as well as to the
    // .result-separator-on-scroll-down class height.
  }

  scrollAlertPreviewsResultsUp() {
    if (!this.overviewStepContainer) { return; }
    this.overviewStepContainer.nativeElement.scrollTop = 0;
    this.onOverviewResultsScroll(); // to set previewListScroll to 0 and isScroll to false;
  }

  setNbPreviewedDocuments(event: number | undefined) {
    if (event) this.nbPreviewedDocuments = event;
    this.load = true;
    this.scrollAlertPreviewsResultsUp();
  }

  onRetrieveProgressBarMaxValue(event: any) {
    switch (event.context) {
      case 'departments' : {
        this.maxValueDepartments = event.total;
        break;
      }
      case 'topics' : {
        this.maxValueTopics = event.total;
        break;
      }
    }
  }

  onRetrieveProgressBarCurrentValue(event: any) {
    switch (event.context) {
      case 'departments' : {
        this.currentValueDepartments = event.selected;
        break;
      }
      case 'topics' : {
        this.currentValueTopics = event.selected;
        break;
      }
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event) {
    event.preventDefault();
  }

  openModal() {
    const modal = this.modalService.open(ModalConfirmationComponent, this.modalOptions);
    modal.componentInstance.returnUrl = '/alert';
    if (this.alertId) {
      modal.componentInstance.title = this.stepsService.funnelContext === FunnelContextEnum.MODIFICATION ?
        'wizard.modal-edit-cancel-title' : 'wizard.modal-duplicate-cancel-title';
      modal.componentInstance.content = this.stepsService.funnelContext === FunnelContextEnum.MODIFICATION ?
        'wizard.modal-edit-cancel-content' : 'wizard.modal-duplicate-cancel-content';
      modal.componentInstance.btnName = this.stepsService.funnelContext === FunnelContextEnum.MODIFICATION ?
        'button.modal-edit-cancel-yes' : 'button.modal-duplicate-cancel-yes';

      // Add a custom cancel function to manage suppression of newly duplicated alert in case of cancellation.
      if (this.stepsService.funnelContext === FunnelContextEnum.DUPLICATION) {
        modal.componentInstance.delayToApplyOnCancellation = 200;
        modal.componentInstance.cancellationFunction = () => {
          return this.apiService.territoryWatch.deleteTerritoryWatch(Number(this.alertId))
            .pipe(first());
        };
      }
    } else {
      modal.componentInstance.title = 'wizard.modal-cancel-title';
      modal.componentInstance.content = 'wizard.modal-cancel-content';
      modal.componentInstance.btnName = 'button.modal-cancel-yes';
    }
  }

  updateOtherUserAlerts(otherUserAlerts: DbTerritoryWatchWithUsers[]) {
    this.otherUserAlerts = otherUserAlerts;
  }

  onSubmit(): void {
    this.awaitingSubmission = true;
    const setting = this.alertSettingService.getSetting();
    if (this.alertId) {
      setting['id'] = this.alertId;
    }
    this.apiService.territoryWatch.createOrUpdateTerritoryWatch(setting)
      .pipe(first())
      .subscribe((res) => {
        this.trackFirstKPIOnDuplicationFeature(res?.id);

        const name = this.alertSettingService.getName();
        const isDuplication = this.stepsService.funnelContext === FunnelContextEnum.DUPLICATION;
        this.toastMessageService.setFinished(true, name, this.alertId, isDuplication);
        this.router.navigate(['/alert']).then();
      }, () => {
        this.awaitingSubmission = false;
      });
  }

  get canBeSaved(): boolean {
    return this.stepsService.canBeSaved();
  }

  ngOnDestroy(): void {
    this.alertSettingService.reset();
    this.stepsService.initServiceObjects();
    this.subscription.unsubscribe();
  }


  //region *** TRACKING ***

  /**
   * Method to find out if the user had an identical alert at the moment of the creation / modification / duplication.
   * We keep track of all almost identical (lookalike) alert ids.
   */
  trackFirstKPIOnDuplicationFeature(alertId: number) {
    const identicalTerritoriesAlertsIds = new Array<any>();
    const identicalTopicsAlertsIds= new Array<any>();
    let currentAlertTerritoryUids= new Array<any>();
    let currentAlertFRDEPAs= new Array<any>();
    let duplicateFromAlertFRDEPAs= new Array<any>();
    let duplicateFromAlertTopicIds= new Array<any>();
    const currentAlertTopicIds = this.alertSettingService?.topicIds.map(elm => Number(elm)).sort((a, b) => a - b);

    this.alertSettingService?.territories?.forEach((elm) => {
      // Element object is like {territory: "FRDEPA01", values: ["FRDEPA01", "FRCOMM122133", ...]}
      elm['values']?.forEach((terr) => {
        currentAlertTerritoryUids.push(terr);
        if (String(terr)?.includes('FRDEPA')) {
          currentAlertFRDEPAs.push(terr);
        }
      });
    });
    currentAlertTerritoryUids = currentAlertTerritoryUids.sort((a, b) => a - b);
    currentAlertFRDEPAs = currentAlertFRDEPAs.sort((a, b) => a - b);

    if (this.stepsService.funnelContext === FunnelContextEnum.DUPLICATION) {
      this.stepsService.duplicatedFromAlertSettings?.territories?.forEach((territoryWatchTerritory) => {
        territoryWatchTerritory.values?.filter(elm => elm.includes('FRDEPA'))?.forEach((departmentCode) => {
          duplicateFromAlertFRDEPAs.push(departmentCode);
        });
      });
      duplicateFromAlertFRDEPAs = duplicateFromAlertFRDEPAs.sort((a, b) => a - b);
      duplicateFromAlertTopicIds = this.stepsService.duplicatedFromAlertSettings?.topicsIds?.sort((a, b) => a - b) ?? [];
    }

    this.otherUserAlerts?.forEach((alert: any) => {
      const alertTopicsIds = alert.topics?.map((topic: any) => topic.id).sort();
      // alert.territory_uid is like : "FRDEPA01, ..., FRCOMM122133"
      const alertTerritories = alert?.territory_uid?.split(',').sort();
      // Checking existence of identical alerts comparing territories.
      if (JSON.stringify(alertTerritories) === JSON.stringify(currentAlertTerritoryUids)) {
        identicalTerritoriesAlertsIds.push(alert.id);
      }
      // Checking existence of identical alerts comparing topics.
      if (JSON.stringify(alertTopicsIds) === JSON.stringify(currentAlertTopicIds)) {
        identicalTopicsAlertsIds.push(alert.id);
      }
    });

    const body = {
      event_timestamp: (new Date()).toISOString(),
      event_type: 'lookalike-alerts-on-funnel-submission',
      ...this.userTrackerService.buildBasicUserInformations(),
      territory_watch_id: alertId,
      territory_watch_departments: currentAlertFRDEPAs,
      territory_watch_topics: currentAlertTopicIds,
      submission_type: this.stepsService.funnelContext,
      duplicated_from_territory_watch_id: this.stepsService.duplicatedFromAlertId,
      duplicated_from_territory_watch_departments: duplicateFromAlertFRDEPAs,
      duplicated_from_territory_watch_topics: duplicateFromAlertTopicIds,
      identical_territory_watch_ids_by_territories: identicalTerritoriesAlertsIds,
      identical_territory_watch_ids_by_topics: identicalTopicsAlertsIds
    };

    this.userTrackerService.track(body)
      .pipe(first())
      .subscribe();
  }

  //endregion

}
