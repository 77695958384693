import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { LoginComponent } from './components/login/login.component';
import { ArticlesPageComponent } from './components/articles-page/articles-page.component';
import { ArticleViewComponent } from './components/documents-view/article-view/article-view.component';
import { ContentComponent } from './components/content/content.component';
import { AdminDocsPageComponent } from './components/admin-docs-page/admin-docs-page.component';
import { AdminDocViewComponent } from './components/documents-view/admin-doc-view/admin-doc-view.component';
import { TerritorySearchPageComponent } from './components/territory-search-page/territory-search-page.component';
import { ImpactersPageComponent } from './components/impacters-page/impacters-page.component';
import { ImpacterViewComponent } from './components/impacter-view/impacter-view.component';
import { TerritoryWatchesPageComponent } from './components/territory-watches-page/territory-watches-page.component';
import { WizardComponent } from './components/wizard/wizard.component';
import { TopicsPageComponent } from './components/topics-page/topics-page.component';
import { TopicFormComponent } from './components/topic-form/topic-form.component';
import { ForgotPasswordComponent } from './components/reset-password/forgot-password.component';
import {
  ForgotPasswordExpiredComponent
} from './components/reset-password/forgot-password-expired/forgot-password-expired.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password/reset-password.component';
import { SettingsComponent } from './components/settings/settings.component';
import { AdminTopicComponent } from './components/admin-topic/admin-topic.component';
import {
  AdminDocGuestViewComponent
} from './components/documents-view/admin-doc-guest-view/admin-doc-guest-view.component';
import { ArticleGuestViewComponent } from './components/documents-view/article-guest-view/article-guest-view.component';
import { GuestContentComponent } from './components/guest-content/guest-content.component';
import { IsLoggedInRedirectResolver } from './shared/resolvers/is-logged-in-redirect.resolver';
import { FoldersPageComponent } from './components/folders-page/folders-page.component';
import { FolderViewComponent } from './components/folder-view/folder-view.component';
import { FolderContentComponent } from './components/folder-content/folder-content.component';
import { FolderViewContainerComponent } from './components/folder-view-container/folder-view-container.component';
// tslint:disable-next-line:max-line-length
import {
  ForgotPasswordIncorrectComponent
} from './components/reset-password/forgot-password-incorrect/forgot-password-incorrect.component';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

export const routes: Routes = [
  //region _____GUEST_____
  {
    path: 'guest',
    component: GuestContentComponent,
    data: {
      guestDisplay: true,
      module: 'guest-interface'
    },
    children: [
      {
        path: 'article/:shareToken/:articleId',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: ArticleGuestViewComponent,
            data: {
              displayNavBar: false,

            }
          },
          {
            path: 'share/:openSourceId',
            pathMatch: 'full',
            component: ArticleGuestViewComponent,
            data: {
              displayNavBar: false
            }
          }
        ],
      },
      {
        path: 'admin-doc/:shareToken/:adminDocId',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: AdminDocGuestViewComponent,
            data: {
              displayNavBar: false
            }
          },
          {
            path: 'share/:openSourceId',
            pathMatch: 'full',
            component: AdminDocGuestViewComponent,
            data: {
              displayNavBar: false
            }
          }
        ]
      }
    ]
  },
  //endregion
  //region _____SEARCH_____
  {
    path: '',
    canActivate: [AuthGuard],
    data: {
      guestDisplay: false,
      module: 'territory_intel'
    },
    children: [
      {
        path: '',
        redirectTo: 'search',
        pathMatch: 'full'
      },
      {
        path: 'search',
        title: 'Explain Territoires',
        component: TerritorySearchPageComponent,
        data: {
          breadcrumb: 'breadcrumb.search'
        }
      },
      {
        path: 'search/:territoryUid',
        component: ContentComponent,
        children: [
          {
            path: '',
            redirectTo: 'article',
            pathMatch: 'full'
          },
          {
            path: 'admin-doc',
            component: AdminDocsPageComponent,
            data: {
              breadcrumb: 'breadcrumb.search_result'
            },
            children: [
              {
                path: ':adminDocId',
                component: AdminDocViewComponent,
                data: {
                  breadcrumb: 'breadcrumb.admin-doc',
                  openSource: 'web_app'
                }
              },
              {
                path: ':adminDocId/email/:openSourceId/collective-order/:collectiveOrderId',
                component: AdminDocViewComponent,
                data: {
                  breadcrumb: 'breadcrumb.admin-doc',
                  openSource: 'digest'
                }
              },
              {
                path: ':adminDocId/email/:openSourceId',
                component: AdminDocViewComponent,
                data: {
                  breadcrumb: 'breadcrumb.admin-doc',
                  openSource: 'digest'
                }
              },
              {
                path: ':adminDocId/share/:openSourceId',
                component: AdminDocViewComponent,
                data: {
                  breadcrumb: 'breadcrumb.admin-doc',
                  openSource: 'share'
                }
              },
              {
                path: ':adminDocId/export',
                component: AdminDocViewComponent,
                data: {
                  breadcrumb: 'breadcrumb.admin-doc',
                  openSource: 'export'
                }
              },
              {
                path: ':adminDocId/export/:exportId',
                component: AdminDocViewComponent,
                data: {
                  breadcrumb: 'breadcrumb.admin-doc',
                  openSource: 'export'
                }
              }
            ]
          },
          {
            path: 'article',
            component: ArticlesPageComponent,
            data: {
              breadcrumb: 'breadcrumb.search_result'
            },
            children: [
              {
                path: ':articleId',
                component: ArticleViewComponent,
                data: {
                  breadcrumb: 'breadcrumb.article',
                  openSource: 'web_app'
                }
              },
              {
                path: ':articleId/email/:openSourceId/collective-order/:collectiveOrderId',
                component: ArticleViewComponent,
                data: {
                  breadcrumb: 'breadcrumb.article',
                  openSource: 'digest'
                }
              },
              {
                path: ':articleId/email/:openSourceId',
                component: ArticleViewComponent,
                data: {
                  breadcrumb: 'breadcrumb.article',
                  openSource: 'digest'
                }
              },
              {
                path: ':articleId/share/:openSourceId',
                component: ArticleViewComponent,
                data: {
                  breadcrumb: 'breadcrumb.article',
                  openSource: 'share'
                }
              }
            ]
          },
          {
            path: 'impacter',
            component: ImpactersPageComponent,
            data: {
              breadcrumb: 'breadcrumb.search_result'
            },
            children: [
              {
                path: ':impacterId',
                component: ImpacterViewComponent,
                data: {
                  breadcrumb: 'breadcrumb.impacter'
                },
                children: [
                  {
                    path: ':articleId',
                    component: ArticleViewComponent,
                    data: {
                      breadcrumb: 'breadcrumb.article',
                      openSource: 'web_app'
                    }
                  }
                ]
              }
            ]
          }
        ]
      },
      //region _____FOLDER_____
      {
        path: 'folder',
        title: 'Explain Territoires',
        canActivate: [AuthGuard],
        component: FolderContentComponent,
        data: {
          breadcrumb: 'breadcrumb.folder',
          module: 'territory_intel'
        },
        children: [
          {
            path: '',
            component: FoldersPageComponent
          },
          {
            path: ':folderId',
            component: FolderViewContainerComponent,
            children: [
              {
                path: '',
                pathMatch: 'full',
                redirectTo: 'article'
              },
              {
                path: 'article',
                component: FolderViewComponent,
                data: {
                  breadcrumb: 'breadcrumb.folder-name',
                  folderView: true
                },
                children: [
                  {
                    path: ':articleId',
                    component: ArticleViewComponent,
                    data: {
                      breadcrumb: 'breadcrumb.article',
                      openSource: 'web_app',
                      showContentFolder: true,
                      folderView: false
                    }
                  }
                ]
              },
              {
                path: 'admin-doc',
                component: FolderViewComponent,
                data: {
                  breadcrumb: 'breadcrumb.folder-name',
                  folderView: true
                },
                children: [
                  {
                    path: ':adminDocId',
                    component: AdminDocViewComponent,
                    data: {
                      breadcrumb: 'breadcrumb.admin-doc',
                      openSource: 'web_app',
                      showContentFolder: true,
                      folderView: false
                    }
                  }
                ]
              }
            ]
          }
        ]
      },
      //endregion
      //region _____ALERT_____
      {
        path: 'alert',
        title: 'Explain Territoires',
        canActivate: [AuthGuard],
        component: TerritoryWatchesPageComponent,
        data: {
          breadcrumb: 'breadcrumb.alert',
          module: 'territory_intel'
        }
      },
      {
        path: 'alert-form',
        title: 'Explain Territoires',
        canActivate: [AuthGuard],
        component: WizardComponent,
        data: {
          displayNavBar: false,
          module: 'territory_intel'
        }
      },
      {
        path: 'alert-form/:alertId',
        title: 'Explain Territoires',
        canActivate: [AuthGuard],
        component: WizardComponent,
        data: {
          displayNavBar: false,
          module: 'territory_intel'
        }
      },
      //endregion
      //region _____TOPICS_____
      {
        path: 'topics',
        title: 'Explain Territoires',
        canActivate: [AuthGuard],
        component: TopicsPageComponent,
        data: {
          breadcrumb: 'breadcrumb.topic',
          module: 'territory_intel'
        }
      },
      {
        path: 'topic-form',
        title: 'Explain Territoires',
        canActivate: [AuthGuard],
        component: TopicFormComponent,
        data: {
          displayNavBar: false,
          module: 'territory_intel'
        }
      },
      {
        path: 'topic-form/:topicId',
        title: 'Explain Territoires',
        canActivate: [AuthGuard],
        component: TopicFormComponent,
        data: {
          displayNavBar: false,
          module: 'territory_intel'
        }
      },
      //endregion

    ]
  },
  //endregion




  //region _____ADMIN_CONNEXION_____
  {
    /*
    c'est une page qui permet de créer ou modifier un topic (theme) à partir
    de l'admin, sans avoir authentification front authentifier
    Method: POST pour écrire / PUT pour modifier un topic
    Token: pour verifier qu'il s'agit bien de l'admin (car l'acces pour cette page
    est anonyme)
    TopicId: id de topic, 0 en cas d'ecriture
    */
    path: 'admin-topic/:method/:token/:topicId',
    title: 'Explain',
    component: AdminTopicComponent,
    data: {
      displayNavBar: false,
      module: 'admin-interface'
    }
  },
  //endregion
  //region _____SETTINGS_____
  {
    path: 'settings',
    title: 'Explain - paramètres',
    canActivate: [AuthGuard],
    component: SettingsComponent,
    data: {
      breadcrumb: 'breadcrumb.settings',
      module: 'settings'
    }
  },
  //region _____LOGIN_____
  {
    path: 'login',
    title: 'Explain - connexion',
    component: LoginComponent,
    resolve: [IsLoggedInRedirectResolver],
    data: {
      displayNavBar: false
    }
  },
  {
    path: 'login/forgot-password',
    title: 'Explain - connexion',
    component: ForgotPasswordComponent,
    data: {
      displayNavBar: false
    }
  },
  {
    path: 'login/reset-password',
    title: 'Explain - connexion',
    component: ResetPasswordComponent,
    data: {
      displayNavBar: false
    }
  },
  {
    path: 'login/reset-password-expired',
    title: 'Explain - connexion',
    component: ForgotPasswordExpiredComponent,
    data: {
      displayNavBar: false
    }
  },
  {
    path: 'login/reset-password-incorrect',
    title: 'Explain - connexion',
    component: ForgotPasswordIncorrectComponent,
    data: {
      displayNavBar: false
    }
  },
  //region _____TENDERS_____
  {
    path: 'tenders',
    title: 'Explain Marchés',
    data: {module: 'tenders'},
    loadChildren: () => import('./tenders/tenders.module').then((m) => m.TendersModule),
  },
  //endregion
  //region _____DEFAULT_ROUTE_____
  {
    path: '**', // every other value.
    redirectTo: '',
    pathMatch: 'full'
  }
  //endregion
];

@NgModule({
  imports: [RouterModule.forRoot(routes), TranslateModule, CommonModule],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
