<div class="container-fluid h-100" #pdfViewContainer>
  <div class="h-100 d-flex flex-wrap-reverse">
    <div class="left-content">
      <app-admin-doc-pdf *ngIf="adminDoc"
                         [adminDoc]="adminDoc"
                         [pdfView]="pdfView"
                         [time]="time"
                         [guestView]="true"></app-admin-doc-pdf>
    </div>
    <div *ngIf="adminDoc" class="info-section">
      <app-metadata
        [documentType]="'admin_doc'"
        [website]="adminDoc.website"
        [publicationDate]="adminDoc.publicationDate"
        [territories]="adminDoc.territories"
        [issuerKind]="adminDoc.source"
        [displayedPublicationType]="adminDoc.displayedPublicationType">
      </app-metadata>

      <app-welcome-panel [shareToken]="shareToken"></app-welcome-panel>
    </div>
  </div>
</div>
