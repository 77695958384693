<div class="container-fluid">
  <div class="row">
    <div class="col-sm-6 left-side"></div>
    <div class="col-sm-6 right-side d-flex">
      <div class="content">
        <div class="fw-bold title">{{"reset-password.title-success" | translate }}</div>
        <div class="sub-title pb-4">
          {{"reset-password.sub-title-success" | translate}} <br>
        </div>
        <div class="login-btn-container form-group">
          <button [routerLink]="['/login']"
                  class="fw-bold btn btn-login">{{'reset-password.reset-success' | translate | uppercase}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>


