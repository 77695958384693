import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HeaderComponent } from '../header/header.component';

@Component({
  selector: 'app-folder-content',
  templateUrl: './folder-content.component.html',
  styleUrls: ['./folder-content.component.scss']
})
export class FolderContentComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('folderHeaderView') folderHeaderComponent?: HeaderComponent;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy() {
  }

}
