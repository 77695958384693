import { Component } from '@angular/core';

@Component({
  selector: 'app-explain-faq',
  templateUrl: './explain-faq.component.html',
  styleUrls: ['./explain-faq.component.scss']
})
export class ExplainFaqComponent {

}
