import { Pipe, PipeTransform } from '@angular/core';
import { BookmarkPath } from '../../models/bookmark-path';

@Pipe({
  name: 'bookmarkCreatedAt'
})
export class BookmarkCreatedAtPipe implements PipeTransform {
  getBookmarkCreatedAt(bookmarkPaths: Array<BookmarkPath>, folderId: number) {
    for (const bookmark of bookmarkPaths) {
      if (bookmark.bookmarkFolderId === folderId) {
        return bookmark.date;
      }
    }
    return undefined;
  }

  transform(bookmarkPaths: Array<BookmarkPath>, folderId: number): Date | undefined {
    return this.getBookmarkCreatedAt(bookmarkPaths, folderId);
  }

}
