<div class="d-flex page-contain">
  <div class="icon align-self-center" [ngClass]="territoriesIsEmpty ? 'icon--no-location': 'icon--no-results'"></div>
  <div class="message align-self-center">
    <ng-container *ngIf="!territoriesIsEmpty">
      <ng-container *ngIf="!titlesIsEmpty">
        <div>{{'content.no-result-message' | translate}}</div>
        <div>{{'content.indicative-no-result-message' | translate}}</div>
      </ng-container>
      <ng-container *ngIf="titlesIsEmpty">
        <div>{{'content.titles-empty-message' | translate}}</div>
        <div>{{'content.indicative-titles-empty-message' | translate}}</div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="territoriesIsEmpty">
      <div>{{'content.territories-empty-message' | translate}}</div>
      <div>{{'content.indicative-territories-empty-message' | translate}}</div>
    </ng-container>
  </div>
</div>
