import { ActivatedRoute } from '@angular/router';
import { Article } from '../models/article';

const DOC_TITLE_LENGTH = 150;

export default class Utils {

  getAllParamsFromRoute(route: ActivatedRoute) {
    let currentRoute : ActivatedRoute | null = route;
    let params = {};
    while (currentRoute) {
      params = {...params, ...currentRoute.snapshot.params};
      currentRoute = currentRoute.parent;
    }
    return params;
  }

  textContainsTitle(article: Article): boolean {
    const first_N_element = article.text?.slice(0, article.title.length);
    return first_N_element === article.title;
  }

  updateArticleText(article: Article): string | undefined {
    if (article.title?.length > DOC_TITLE_LENGTH && !this.textContainsTitle(article)) {
      return article.title + '\n' + article.text;
    } else {
      return article.text;
    }
  }

  parseImages(articleHtml: string) {
    let matches: IterableIterator<RegExpMatchArray>;
    if (articleHtml.includes('class="nitfp"')) {
      matches = this.nitfpPreParsing(articleHtml);
    } else {
      matches = this.xhtmlPreParsing(articleHtml);
    }
    return this.imagesParsing(matches);
  }

  imagesParsing(matches: IterableIterator<RegExpMatchArray>) {
    // Matches every tag: <tag>, </tag>, <tag />
    // [^>]: any character except '>'.
    const htmlTagRegexp = /<[^>]*>/g;
    // Match all whitespaces: \n \t, spaces...
    const whiteSpaceRegexp = /\s/g;
    // Match all multiple spaces.
    const moreThanOneSpaceRegexp = /\s{2,}/g;

    const images = [];
    for (const match of matches) {
      let caption = match?.groups?.['caption']
        ?.replace(htmlTagRegexp, '')
        .replace(whiteSpaceRegexp, ' ')
        .replace(moreThanOneSpaceRegexp, ' ');
      if (caption && caption.startsWith('Photo:')) {
        caption = caption.substr(6);
      }
      caption = caption?.trim();
      images.push({
        src: match?.groups?.['url'],
        caption: caption
      });
    }
    return images;
  }

  nitfpPreParsing(articleHtml: string): IterableIterator<RegExpMatchArray> {
    /* <img\s+.*src\s*=\s*"(?<url>.+?)"[^>]*>:
    *   - will match img tag with src specified
    *   - src will be put in capture group 'url'
    *   (?<caption>[\s\S]*?)<\/img>:
    *   - will put in capture group 'caption' every character until next closing image tag </img>
    *     (next only because quantifier is lazy, otherwise it would go to the last img closing time)
    *   - [\s\S] is any whitespace or non whitespace character
    *   - *? matches the previous token between zero and unlimited times, as few times as possible, expanding as needed (lazy)
    * */

    const captureImagesUrlAndCaptionNitfp = /<img\s+.*src\s*=\s*"(?<url>.+?)"[^>]*>(?<caption>[\s\S]*?)<\/img>/g;
    return articleHtml.matchAll(captureImagesUrlAndCaptionNitfp);
  }

  xhtmlPreParsing(articleHtml: string):IterableIterator<RegExpMatchArray> {
    /* <img\s+.*src\s*=\s*"(?<url>.+?)"[^>]*?\/>:
    *   - will match closing image tag with src specified
    *   - src will be put in capture group 'url'
    *   - [^>]*?: matches any character except '>', and is lazy.
    * (?<caption>[\s\S]*?)(?=<img|$):
    *   - will put in capture group 'caption' every character until next opening image tag or end of string.
    *   - [\s\S] is any whitespace or non whitespace character
    *   - *? matches the previous token between zero and unlimited times, as few times as possible, expanding as needed (lazy)
    *   - (?=<img|$): Positive Lookahead: Assert that the Regex below matches
    *     - either <img matches the characters <img literally (case sensitive)
    *     - either $ asserts position at the end of the string
    * */

    const captureImagesUrlAndCaptionXhtml = /<img\s+.*src\s*=\s*"(?<url>.+?)"[^>]*?\/>(?<caption>[\s\S]*?)(?=<img|$)/g;
    return articleHtml.matchAll(captureImagesUrlAndCaptionXhtml);
  }

}
