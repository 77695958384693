import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { interval, Subscription } from 'rxjs';
import { ModalConfirmationComponent } from '../../components/modal-confirmation/modal-confirmation.component';
import { tap } from 'rxjs/operators';
import { JsonGettersService } from './json-getters.service';

@Injectable({
  providedIn: 'root'
})
export class UpdateModalService {

  checkForUpdateIntervalSubscription!: Subscription;
  updateModal!: NgbModalRef;
  updateModalNoSnooze!: NgbModalRef;
  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    ariaLabelledBy: 'modal-basic-title',
    centered: true,
    windowClass: 'custom-modal-update'
  };
  // Time for snoozing, in milliseconds
  snoozeTime = 120000;

  constructor(
    private update: SwUpdate,
    private modalService: NgbModal,
    private jsonGettersService: JsonGettersService,
    ) {
  }

  /**
   * Set up change detection every x minutes. If change is detected by the service worker, display a first modal with snooze allowed.
   * Timer is unregistered in that case.
   * When snooze button hit, timer of updateModalService.snoozeTime is triggered to display second modal without snooze button.
   * Action performed with modal is a window.reload(true).
   * @param milliseconds - time for interval in milliseconds
   */
  public setUpdateDetectionTimer(milliseconds: number) {

    const checkForUpdateInterval = interval(milliseconds);

    // Subscribing to interval for checking updates every x milliseconds
    this.checkForUpdateIntervalSubscription =
      checkForUpdateInterval
        .pipe(tap(() => {
          this.update.checkForUpdate().then();
        }))
        .subscribe();

    // subscribe to detection of application update
    this.update.versionUpdates
      .pipe(tap((versionEvent) => {
        if (versionEvent.type === 'VERSION_DETECTED') {
          console.log('new version detected by ngsw');
          console.log('versionEvent', versionEvent);
          // subscribe to get the value of displayBoolean boolean in config file named version.json
          this.jsonGettersService.getDisplayUpdateModal()
            // Displaying export feature modale
            .pipe(tap((displayBoolean: boolean) => this.openUpdateModal(displayBoolean, UpdateModalTypeEnum.GENERIC_UPDATE)))
            .subscribe();
        }
      }))
      .subscribe();
  }

  /**
   * Method to open an update modal in case of true boolean as param.
   * @param displayBoolean - boolean set in version.json to tell the app to display modal after a new deployment
   * @param type - generic-update or new-feature-explained
   */
  openUpdateModal(displayBoolean: boolean, type: UpdateModalTypeEnum) {
    if (displayBoolean) {

      // Gestion of the modale type 'generic' (legacy) or 'new-feature'
      const modalOptions = this.getModalOptions(type);

      // If there is no opened modal
      if (!this.modalService.hasOpenModals()) {

        // Open generic update modal or specific new feature description modal
        this.updateModal = this.modalService.open(ModalConfirmationComponent, this.ngbModalOptions);
        this.updateModal.componentInstance.updateMode = modalOptions.updateMode;
        this.updateModal.componentInstance.snooze = modalOptions.snooze;
        this.updateModal.componentInstance.returnUrl = modalOptions.returnUrl;
        this.updateModal.componentInstance.isNewFeatureExplainedModal = modalOptions.isNewFeatureExplainedModal; // New Feature modal
        this.updateModal.componentInstance.title = modalOptions.title;
        this.updateModal.componentInstance.content = modalOptions.content;
        this.updateModal.componentInstance.explanation = modalOptions.explanation;
        this.updateModal.componentInstance.explanationUnderline = modalOptions.explanationUnderline;
        this.updateModal.componentInstance.btnName = modalOptions.btnName;
        this.updateModal.componentInstance.snoozeFunction = modalOptions.snoozeFunction;

        // Unsubscribing to avoid issues when displaying 2nd modal.
        if (this.checkForUpdateIntervalSubscription) {
          this.checkForUpdateIntervalSubscription.unsubscribe();
        }
      }
    }
  }

  /** Getting modal options in fonction of UpdateModalType generic or new-feature-explained */
  getModalOptions(type: UpdateModalTypeEnum): ModalOptions {
    return {
      updateMode: true,
      snooze: (type === UpdateModalTypeEnum.GENERIC_UPDATE),
      returnUrl: true,
      isNewFeatureExplainedModal: !(type === UpdateModalTypeEnum.GENERIC_UPDATE),
      title: (type === UpdateModalTypeEnum.GENERIC_UPDATE) ?
        'application-update.modal-title' :
        'new-feature-explained.modal-title',
      content: (type === UpdateModalTypeEnum.GENERIC_UPDATE) ?
        'application-update.modal-body1' :
        'new-feature-explained.modal-body1',
      explanation: (type === UpdateModalTypeEnum.GENERIC_UPDATE) ?
        'application-update.modal-explanation' :
        'new-feature-explained.modal-explanation',
      explanationUnderline: (type === UpdateModalTypeEnum.GENERIC_UPDATE) ?
        'application-update.modal-explanation-underline' :
        '',
      btnName: (type === UpdateModalTypeEnum.GENERIC_UPDATE) ?
        'application-update.modal-yes' :
        'new-feature-explained.modal-yes',
      snoozeFunction: () => this.onUpdateModalSnooze(type)
    };
  }

  /** Display of second modal (without the snooze button) after snoozeTime */
  onUpdateModalSnooze(type: UpdateModalTypeEnum) {
    setTimeout(() => {
      this.updateModalNoSnooze = this.modalService.open(ModalConfirmationComponent, { ...this.ngbModalOptions});
      this.updateModalNoSnooze.componentInstance.updateMode = true;
      this.updateModalNoSnooze.componentInstance.snooze = false;
      this.updateModalNoSnooze.componentInstance.returnUrl = '';
      this.updateModalNoSnooze.componentInstance.isNewFeatureExplainedModal = !(type === UpdateModalTypeEnum.GENERIC_UPDATE);
      this.updateModalNoSnooze.componentInstance.title = (type === UpdateModalTypeEnum.GENERIC_UPDATE) ?
        'application-update.modal-title' :
        'new-feature-explained.modal-title';
      this.updateModalNoSnooze.componentInstance.content = (type === UpdateModalTypeEnum.GENERIC_UPDATE) ?
        'application-update.modal-body1' :
        'new-feature-explained.modal-body1';
      this.updateModalNoSnooze.componentInstance.btnName = 'application-update.modal-yes';
    }, this.snoozeTime);
  }

}

export interface ModalOptions {
  updateMode: boolean;
  snooze: boolean;
  returnUrl: boolean;
  isNewFeatureExplainedModal: boolean;
  title: string;
  content: string;
  explanation: string;
  explanationUnderline: string;
  btnName: string;
  snoozeFunction: Function;
}

export enum UpdateModalTypeEnum {
  GENERIC_UPDATE= 'generic-update',
  NEW_FEATURE_EXPLAINED = 'new-feature-explained'
}
