<div class="view-content">
  <div class="pdf-view" *ngIf="adminDoc">
    <app-loader data-cy="top-loader-pdf-admin-doc-view" [show]="true" [inView]="true" [inSearch]="inSearch" [noResult]="searchNotFound" [topChoice]="'top-loader-doc-admin'"></app-loader>
    <ngx-extended-pdf-viewer
      #pdf
      [(page)]="page"
      (pageChange)="pageChange($event)"
      [src]="adminDoc.url"
      [textLayer]="true"
      [customToolbar]="customToolBar"
      [handTool]="false"
      [ignoreKeyboard]="true"
      [showBorders]="false"
      [useBrowserLocale]="true"
      [enablePrint]="false"
      [(rotation)]="rotation"
      [(sidebarVisible)]="sidebarVisible"
      backgroundColor="transparent"
      zoom="auto"
      height="{{
        guestView ? 'calc(100vh - var(--size-header-guest-display) - 16px'
                  : 'calc(100vh - var(--size-header) - 16px'}}"
      (pdfLoaded)="pdfLoaded()"
      (pagesLoaded)="onPagesLoaded($event)"
      (updateFindMatchesCount)="updateFindMatchesCount($event)">
    </ngx-extended-pdf-viewer>
  </div>
</div>

<ng-template #customToolBar>

  <app-keywords-custom-toolbar
    [stems]="adminDoc.matchStems"
    [matchTermMatrix]="matchTermMatrix"
    [globalOccurrencePagesReplaySubject]="globalOccurrencePagesReplaySubject"
    [selectedTerms]="selectedTerms"
    [documentLoading]="inSearch"
    [$matchTermMatrixUpdate]="$matchTermMatrixUpdate"
    (isPanelExpanded)="onExpandPanel($event)"
    (keywordSelectionChanged)="keywordSelectionChanged($event)"
    (deleteUserInput)="deleteUserInput($event)"
  >

    <!-- SEARCH PAGINATION -->
    <app-admin-doc-search-pagination searchPagination
                                     [$occurrencePages]="globalOccurrencePagesReplaySubject"
                                     [currentPage]="page"
                                     [$currentPage]="$page"
                                     (pageClicked)="newPageNumber($event, InteractionLeadingToNewPage.PAGINATION_COMPONENT_CLICK)">
    </app-admin-doc-search-pagination>

    <!-- PDF MENUS (zoom, page selection, icons) -->
    <app-pdf-reader-menus pdfMenus
                             [page]="page"
                             [nbPages]="nbPages"
                             (pageEntered)="newPageNumber($event, InteractionLeadingToNewPage.PDF_PAGE_INPUT)"
                             (rotatePdf)="rotatePdf($event)"
    >

    </app-pdf-reader-menus>

  </app-keywords-custom-toolbar>

    <!-- BUTTON SIDEBAR -->
    <app-admin-doc-sidebar-button sidebarButton
                                  [sidebarVisible] = "sidebarVisible"
                                  [toolbarHeight$]="toolbarHeight$"
                                  (displayLateralPanel)="displayLateralPanel($event)">
    </app-admin-doc-sidebar-button>

</ng-template>
