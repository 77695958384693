<div class="icon-text-badge-container d-table">
    <div class="icon-badge d-table-cell text align-content-center justify-content-center">
        <mat-icon svgIcon="{{iconName}}" class="icon align-content-center justify-content-center"></mat-icon>
    </div>
    <div class="text-container d-table align-content-center justify-content-center">
        <div class="d-table-cell text align-content-center justify-content-center">
            {{'document.issuer-kind.' + text | translate}}
        </div>
    </div>
</div>
