<!--case we want to go to external website on a new tab-->
<a data-cy="admin-doc-card-folder-view"
  *ngIf="redirectsToUrl" [href] = "url" target="_blank">
  <ng-container *ngTemplateOutlet="cardComponentBody"></ng-container>
</a>

<!--case we want to stay in explain app-->
<!--middle click is for the mouse wheel click-->
<a data-cy="admin-doc-card-folder-view"
  *ngIf="!redirectsToUrl" [routerLink]="getRouterLink()"
   (contextmenu)="onClickCard()"
   (click)="onClickCard()"
   (middleclick)="onClickCard()"
   [state]="{context: text}">
  <ng-container *ngTemplateOutlet="cardComponentBody"></ng-container>
</a>

<!--main body of the card-->
<ng-template #cardComponentBody>
  <div [ngClass]="{'preview': isPreview}" class="card-board">
    <div class="d-flex first-line">

      <div *ngIf="displayHelper" class="card-info d-flex">
        <!--macro catégorie-->
        <app-icon-text-badge
                class=" d-flex align-self-center"
                [text]="displayHelper?.text ?? ''"
                [iconName]="displayHelper?.icon_name ?? ''"
        ></app-icon-text-badge>

        <!--separation-->
        <div class="category-separation d-flex align-self-center">
          <mat-icon class="fill-basic-600 icon-16 me-1"
                    svgIcon="chevron-right-basic-600"></mat-icon>
        </div>

        <!--micro catégorie-->
        <div class="publication-category d-flex align-self-center">
          <mat-icon *ngIf="(displayedPublicationType !== DisplayedPublicationType.NON_DETECTED)"
                    class="fill-basic-800 icon-16 me-1"
                    svgIcon="docs-basic-600"></mat-icon>
          <mat-icon *ngIf="(displayedPublicationType === DisplayedPublicationType.NON_DETECTED)"
                    svgIcon="docs-basic-600" class="icon-16 me-1"></mat-icon>
          <div [ngClass]="(displayedPublicationType === DisplayedPublicationType.NON_DETECTED) ? 'color-basic-600' : 'color-basic-800'"
               [style.font-style]="(displayedPublicationType === DisplayedPublicationType.NON_DETECTED) ? 'italic' : 'normal'"
               class="category-name fw-600">{{displayedPublicationType}}</div>
        </div>
      </div>

      <div class="occurrence-and-folder d-flex justify-content-end position-static">
        <!--date de publication-->
        <div [ngClass]="{'fw-600': isPreview,
                         'fw-700': !isPreview}"
             class="publication-date d-flex align-self-center">
          <mat-icon svgIcon="calendar-basic-600" class="icon-16 me-1"></mat-icon>
          <div *ngIf="publicationDate"> {{publicationDate | date:'dd MMMM yyyy':'':'fr' }}</div>
          <div *ngIf="!publicationDate" class="text-capitalize">{{'content.date_not_detected' | translate}}</div>
        </div>
      </div>
      <!--PREVIEW Website-->
      <div *ngIf="isPreview"
           class="d-flex color-basic-800 fw-600 align-self-center website-part">
        <mat-icon svgIcon="website" class="icon-16 fill-basic-600 me-1"></mat-icon>
        <div class="text-ellipsis">{{website}}</div>
      </div>
    </div>

    <div class="second-line">
      <!--title part-->
      <div class="left-part d-flex text-truncate">
        <app-occurrence-and-folder-icon-badge
                class="d-flex"
                [folderId]="folderId"
                [nbOccurrences]="nbOccurrences"
                [showOccurrences]="false"
                [showCreationDate]="false"
                [bookmarkPaths]="bookmarkPaths"
                [showFolder]="true">
        </app-occurrence-and-folder-icon-badge>
        <div class="card-title d-table-cell align-middle position-static text-truncate"
             data-cy="admin-doc-search-card-board-title"
             (mouseenter)="displayExtLinkIcon = true"
             (mouseleave)="displayExtLinkIcon = false"
             [innerHTML]="title">
        </div>
        <!--external link icon in case of redirection to external URL-->
        <mat-icon *ngIf="!isPreview && redirectsToUrl" class="ext-link-icon d-flex align-middle justify-content-center align-content-center"
                  [style.opacity]="redirectsToUrl ? (displayExtLinkIcon ? '1' : '0') : '0'"
                  svgIcon="ext-link-primary-500"></mat-icon>
      </div>
      <!--occurrence count part-->
      <div class="right-part d-table h-100">
        <app-occurrence-and-folder-icon-badge
                class="d-table-cell align-middle"
                [folderId]="folderId"
                [nbOccurrences]="nbOccurrences"
                [showOccurrences]="showOccurrences"
                [bookmarkPaths]="bookmarkPaths"
                [showFolder]="false">
        </app-occurrence-and-folder-icon-badge>
      </div>
    </div>

    <!--IF territories-->
    <div *ngIf="!isPreview && territories && territories.length > 0"
         class="territory-section"
         [ngClass]="displayReadMore ? 'split' : ''"
         id="territory-section-{{docId}}">
      <div *ngIf="territories.length > 0"
           class="territories d-flex align-self-center">
        <mat-icon *ngIf="isPreview" svgIcon="pin" class="icon-16 fill-primary-500 me-1"></mat-icon>
        <mat-icon *ngIf="!isPreview" svgIcon="pin-basic-600" class="icon-16 me-1"></mat-icon>
        <div id="territory-list-{{docId}}" class="territory-list text-truncate-territories">
          <span *ngFor="let territory of territories; let i = index">
            <span *ngIf="isPreview" class="territory-name fw-bold color-primary-500">{{territory['name']}}</span>
            <span *ngIf="!isPreview" class="territory-name fw-bold color-basic-800">{{territory['name']}}</span>
            <span *ngIf="i < territories.length - 1"
                  class="separator color-basic-600 fw-light"> | </span>
            <span *ngIf="i === territories.length - 1" class="fantom-space">...</span>
          </span>
        </div>
      </div>

      <div *ngIf="displayReadMore" class="fading-color-box">
      </div>

      <div *ngIf="displayReadMore"
           id="read-more-{{docId}}"
           class="read-more d-flex"
           (click)="onReadMoreClick($event)"
           [ngbTooltip]="territoryTooltip"
           tooltipClass="custom-tooltip-article-card-territories"
           placement="bottom-right">
        <mat-icon svgIcon="plus-circle-icon-fill" class="icon-16 fill-basic-600 me-1 align-self-center align-middle"></mat-icon>
        <div class="text color-basic-600 fw-bold align-self-center">{{"content.see-more"| translate}}</div>
      </div>
    </div>

    <!--PREVIEW orange publication type-->
    <div *ngIf="isPreview"
         class="publication-category-preview d-flex align-self-center">
      <mat-icon *ngIf="(displayedPublicationType !== DisplayedPublicationType.NON_DETECTED)"
                class="fill-primary-500 icon-16 me-1"
                svgIcon="docs-primary-500"></mat-icon>
      <mat-icon *ngIf="(displayedPublicationType === DisplayedPublicationType.NON_DETECTED)"
                svgIcon="docs-basic-600" class="icon-16 me-1"></mat-icon>
      <div [ngClass]="(displayedPublicationType === DisplayedPublicationType.NON_DETECTED) ? 'color-basic-600' : 'color-primary-500'"
           [style.font-style]="(displayedPublicationType === DisplayedPublicationType.NON_DETECTED) ? 'italic' : 'normal'"
           class="category-name fw-600">{{displayedPublicationType}}</div>
    </div>


    <div *ngIf="text" class="card-text" data-cy="admin-doc-search-card-board-text" [innerHTML]="text"></div>
    <div *ngIf="!text" class="card-text fst-italic color-basic-600" data-cy="admin-doc-search-card-board-text">{{'content.extract-not-detected' | translate}}</div>
  </div>
</ng-template>

<!--tooltip containing long list of territories when taking more than 1 line-->
<ng-template #territoryTooltip class="territory-tooltip">
      <span *ngFor="let territory of territories; let i = index">
            <span class="territory-name fw-bold color-white">{{territory['name']}}</span>
            <span *ngIf="i < territories.length - 1"
                  class="separator color-basic-600 fw-light"> | </span>
      </span>
</ng-template>

