import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HeadersService } from './api/headers/headers.service';
import { ApiService } from "./api/api.service";
import { firstValueFrom } from "rxjs";
import { UserSessionService } from "./user-session.service";

@Injectable({ providedIn: 'root'})
export class AuthenticationService {
  constructor(
    private http: HttpClient,
    private headersService: HeadersService,
    private apiService: ApiService,
    private userSessionService: UserSessionService
  ) {}


  async newLogin(email: string, password: string) {
    const sanitizedEmail = email.trim().toLowerCase();
    // mise à jour de l'instance de userSession dans le service utilisateur dédié.
    this.userSessionService.userSession =
      await firstValueFrom(this.apiService.account.getUserSession({email: sanitizedEmail, password}));
  }

  async checkCurrentPassword(password: string) {
    const email = localStorage.getItem('email');
    const userSession = await firstValueFrom(this.apiService.account.getUserSession({email, password}))
    // setting up the new api_token
    localStorage.removeItem('api_token');
    localStorage.setItem('api_token', userSession.apiToken);
  }

  logout() {
    this.userSessionService.logout();
  }

  /**
   * POST method to request a new password.
   * */
  forgotPassword(email: string) {
    const sanitizedEmail = email.trim().toLowerCase();
    return this.http.post(`${environment.explainApiUrl}account/password/request_reset`, { email: sanitizedEmail});
  }

  /**
   * Méthode POST pour le reset du mot de passe.
   * Fonctionne à la fois pour tester la validité du reset_password_token à l'ouverture de la page.
   * Et également pour la modification effective du mot de passe.
   */
  resetPassword(newPassword: string, resetPasswordToken: string) {
    return this.http.post(`${environment.explainApiUrl}account/password/reset`, {
      new_password: newPassword,
      reset_password_token: resetPasswordToken
    });

  }

  /**
   * POST method to update the password of a logged in user.
   * @param new_password - new password
   * @param password - previous password
   */
  updatePassword(new_password: string, password: string) {
    const body = {
      'new_password': new_password,
      'password': password
    };

    return this.http.post(`${environment.explainApiUrl}account/password/update`, body, this.headersService.httpHeaders);

  }

  /**
   * POST method to check validity of the reset_password_token
   * @param token - reset_password_token
   */
  checkResetPasswordToken(token: string | null) {

    return this.http.post(`${environment.explainApiUrl}account/password/reset`, { 'reset_password_token': token});

  }


}
