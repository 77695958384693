import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { RegexpSearch } from '../../shared/helpers/regex';
import { BookmarkFolderService } from '../../shared/services/bookmark-folder.service';
import { BookmarkEntityType } from '../../models/bookmark-folder';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { Folder } from '../../models/folder';
import { ApiService } from '../../shared/services/api/api.service';
import { DbListItem } from '../../models/generic/list-item';

@Component({
  selector: 'app-folders-page',
  templateUrl: './folders-page.component.html',
  styleUrls: ['./folders-page.component.scss']
})

export class FoldersPageComponent implements OnInit, OnDestroy {

  items = new Array<Folder>();
  filteredItems = new Array<Folder>();
  itemFilteredNameFormControl = new FormControl();
  showClearIcon = false;
  hasFilter = true;
  iconFolderName = 'folder-fill.svg';
  componentSubscription: Subscription;

  constructor(private router: Router,
              private apiService: ApiService,
              private bookmarkFolderService: BookmarkFolderService) {
    this.componentSubscription = new Subscription();
  }

  ngOnInit(): void {

    this.getItems();

    this.componentSubscription.add(
      this.itemFilteredNameFormControl.valueChanges
        .subscribe(val => {
          if ((val !== null) && val.length > 0) {
            this.filterItems(val);
            this.showClearIcon = true;
          } else {
            this.resetFilteredItems();
            this.showClearIcon = false;
          }
        })
    );

  }

  ngOnDestroy() {
    this.componentSubscription.unsubscribe();
  }

  filterItems(str: string) {
    this.filteredItems = this.items.filter(item => RegexpSearch.normalizedSearch(item['name'], str));
  }

  resetFilteredItems() {
    this.filteredItems = this.items;
  }

  clearText() {
    this.itemFilteredNameFormControl.setValue('');
  }

  getItems() {
    this.apiService.bookmark.retrieveBookmarkFolders(this.bookmarkFolderService.buildPostBody())
      .pipe(first())
      .subscribe((folders) => {
        this.items = folders;
        this.filteredItems = folders;
      });
  }

  onSelectItem(bookmarkFolder: Folder) {
    this.router.navigate([`/folder/${bookmarkFolder.id}/article`]).then();
  }

  refreshListItem() {
    this.getItems();
  }

  renameItem(value: DbListItem) {
    this.apiService.bookmark.renameBookmarkFolder(value.id, value.name)
      .toPromise().then();
  }

  deleteItem(itemId: number) {
    this.apiService.bookmark.deleteBookmarkEntity(itemId, BookmarkEntityType.FOLDER)
      .toPromise().then(() => {
        // console.log(`item d'id : ${itemId} deleted !`);
      }, error => {
        console.log(`error on deletion : ${error}`);
      });
  }

  updateItem(event: any) {
    console.log('update: ' + event);
  }

}
