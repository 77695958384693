import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EntityService } from '../../shared/services/entity.service';
import { Subscription } from 'rxjs';
import { ApiService } from '../../shared/services/api/api.service';
import { Impacter } from '../../models/impacter/impacter';

@Component({
  selector: 'app-impacter-view',
  templateUrl: './impacter-view.component.html',
  styleUrls: ['./impacter-view.component.scss']
})
export class ImpacterViewComponent implements OnInit, OnDestroy, AfterViewChecked {
  public impacterId: string | null = null;
  private impacter: Impacter| null = null;
  public territoryUid!: string;
  public totalCount: number | null = null;
  public totalArticleCount: number | null = null;
  public inArticleView!: boolean;
  public showLoader!: boolean;

  /** Subscriptions */
  routeParamsSubscription!: Subscription;
  getImpactersSubscription!: Subscription;
  routeGreatParentParamsSubscription?: Subscription;
  getArticleIMCurrentCountSubscription!: Subscription;
  getArticleIMTotalCountSubscription!: Subscription;
  getEntityIdSubscription!: Subscription;

  constructor(private route: ActivatedRoute,
              private entityService: EntityService,
              private apiService: ApiService,
              private ref: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.impacterId = '';
    this.impacter = null;
    this.territoryUid = '';
    this.totalCount = 0;
    this.totalArticleCount = 0;
    this.inArticleView = false;
    this.showLoader = true;

    this.routeParamsSubscription =
      this.route.params.subscribe(
        (params) => {
          this.impacterId = params['impacterId'];

          if (this.impacterId) {
            this.entityService.sendEntityId(this.impacterId);

            this.getImpactersSubscription =
              this.apiService.impacter.getImpacter(this.impacterId).subscribe(data => {
                this.impacter = data;
                this.entityService.sendImpacter(this.impacter);
              });
          }

        });

    this.routeGreatParentParamsSubscription =
      this.route.parent?.parent?.params.subscribe(params => {
        this.territoryUid = params['territoryUid'];
      });

    this.getArticleIMCurrentCountSubscription =
      this.entityService.getArticleIMCurrentCount().subscribe(totalCount => {
        this.totalCount = totalCount;
      });

    this.getArticleIMTotalCountSubscription =
      this.entityService.getArticleIMTotalCount().subscribe(count => {
        this.totalArticleCount = count;
        this.showLoader = false;
      });

    this.getEntityIdSubscription =
      this.entityService.getEntityId().subscribe(entityId => {
        this.inArticleView = this.impacterId!== null && entityId !== this.impacterId;
      });
  }

  ngAfterViewChecked() {
    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    /** Unsubscriptions */
    if (this.routeParamsSubscription) {
      this.routeParamsSubscription.unsubscribe();
    }
    if (this.getImpactersSubscription) {
      this.getImpactersSubscription.unsubscribe();
    }
    if (this.routeGreatParentParamsSubscription) {
      this.routeGreatParentParamsSubscription.unsubscribe();
    }
    if (this.getArticleIMCurrentCountSubscription) {
      this.getArticleIMCurrentCountSubscription.unsubscribe();
    }
    if (this.getArticleIMTotalCountSubscription) {
      this.getArticleIMTotalCountSubscription.unsubscribe();
    }
    if (this.getEntityIdSubscription) {
      this.getEntityIdSubscription.unsubscribe();
    }

    this.entityService.sendEntityId(null);
    this.entityService.sendImpacter(null);
    this.entityService.sendArticleIMTotalCount(null);
  }
}
