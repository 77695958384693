<div class="pagination-btn">
  <div class="d-flex justify-content-center">
    <button class="prev-btn text-uppercase d-flex justify-content-between"
            (click)="onPageClick(currentPage - 1)">
      <img class="align-self-center" src="assets/images/icon-arrow-left.svg" alt="icon-arrow-left"/>
      <div class="align-self-center">{{'button.prev' | translate}}</div>
    </button>
    <ng-container *ngFor="let pageIndex of listOfPage; let i = index;">
      <div class="align-self-center item active"
           [ngClass]="{'active': currentPage === pageIndex}"
           *ngIf="i === 0"
           (click)="onPageClick(pageIndex)">
        <a class="" >{{pageIndex}}</a>
      </div>
      <div class="align-self-center item"
           *ngIf="currentPage >= maxNbPagesConsecutive && i === 3 && i < nbPages && nbPages > maxNbPagesShow">
        <a class="">...</a>
      </div>
      <div class="align-self-center item"
           [ngClass]="{'active': currentPage === pageIndex}"
           *ngIf="(showWhenInFirstPages(pageIndex)
            || showWhenInLastPages(pageIndex)
            || showNextPrevPage(pageIndex))
            && notShowFirstLastPage(i)"
           (click)="onPageClick(pageIndex)">
        <a class="" >{{pageIndex}}</a>
      </div>
      <div class="align-self-center item"
           *ngIf="currentPage <= nbPages - maxNbPagesConsecutive + 1  && i === nbPages - 4 && nbPages > maxNbPagesShow">
        <a class="">...</a>
      </div>
      <div class="align-self-center item"
           [ngClass]="{'active': currentPage === nbPages}"
           *ngIf="i === nbPages - 1"
           (click)="onPageClick(pageIndex)">
        <a class="">{{nbPages}}</a>
      </div>
    </ng-container>

    <button class="next-btn text-uppercase d-flex justify-content-between"
            (click)="onPageClick(currentPage + 1)">
      <div class="align-self-center">{{'button.next' | translate}}</div>
      <img class="align-self-center" src="assets/images/icon-arrow-right.svg" alt="icon-arrow-right"/>
    </button>
  </div>
</div>
