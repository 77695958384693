<div class="d-flex">
  <ex-menu *ngIf="canDisplayMenu"
           (logout)="logout()"></ex-menu>
  <div [ngClass]="{
      'root-content': true,
      'guest-menu-margin': displayGuestMenu
    }">
    <router-outlet></router-outlet>
  </div>
  <app-toast-message-stack aria-live="polite" aria-atomic="true">
    <div style="position: absolute; bottom: 0; right: 0;"></div>
  </app-toast-message-stack>
</div>
<app-explain-faq *ngIf="urlIsAllowedToDisplayHelp()"/>
