import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  BookmarkDocumentEntityType,
  BookmarkDocumentService,
} from '../../shared/services/bookmark-document.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UrlService } from '../../shared/services/url.service';
import { EntityService } from '../../shared/services/entity.service';
import { BookmarkFolderService } from '../../shared/services/bookmark-folder.service';
import { Folder } from '../../models/folder';
import { AdminDocument } from '../../models/admin-document';
import { Article } from '../../models/article';
import { ApiService } from '../../shared/services/api/api.service';

/** Component highly inspired by TaskBarComponent (use of scss and ts) */
@Component({
  selector: 'app-folder-view',
  templateUrl: './folder-view.component.html',
  styleUrls: ['./folder-view.component.scss']
})
export class FolderViewComponent implements OnInit, OnDestroy {
  nbPages: number;
  documentEntities!: Array<Article | AdminDocument>;
  documentsType: BookmarkDocumentEntityType;
  entityId: string;
  componentSubscription: Subscription;
  bookmarkDocumentServiceSubscription: Subscription;
  folderId!: number;
  showLoader!: boolean;

  constructor(private bookmarkDocumentService: BookmarkDocumentService,
              private bookmarkFolderService: BookmarkFolderService,
              private apiService: ApiService,
              private entityService: EntityService,
              private urlService: UrlService,
              private route: ActivatedRoute,
              private router: Router) {
    this.bookmarkDocumentService.reset();
    this.nbPages = 0;
    this.documentsType = BookmarkDocumentEntityType.ARTICLE;
    this.entityId = '';
    this.componentSubscription = new Subscription();
    this.bookmarkDocumentServiceSubscription = new Subscription();
    this.documentsType = this.router.url.includes('article') ?
      BookmarkDocumentEntityType.ARTICLE : BookmarkDocumentEntityType.ADMIN_DOC;
    this.bookmarkDocumentService.entityType = this.documentsType;
  }

  get bookmarkDocumentEntityType(): typeof BookmarkDocumentEntityType {
    return BookmarkDocumentEntityType;
  }

  ngOnInit() {

    /** Getting nbPages from docs_count & sendPostSetting on service */
    this.componentSubscription.add(
      this.bookmarkFolderService.getFolderSubject()
        .subscribe((folder: Folder) => {
          this.nbPages = Math.ceil(folder[`${this.bookmarkDocumentService.entityType}sCount`] / this.bookmarkDocumentService.limit);
          this.bookmarkDocumentService.sendPostSettings();
          const cardsContainer = document.getElementById('cards-container')
          if(cardsContainer) cardsContainer.scrollTop = 0;
        }));

    /** Getting entityId */
    this.componentSubscription.add(
      this.entityService.getEntityId()
        .subscribe(entityId => {
          this.entityId = entityId ?? "";
        })
    );

    /** Subscribing to service subject update */
    this.bookmarkDocumentServiceSubscription.add(
      this.bookmarkDocumentService.subject
        .subscribe(
          postParams => {
            this.showLoader = true;
            this.folderId = postParams.container_id;
            /** Retrieving the list of documentEntities */
            this.apiService.bookmark.retrieveBookmarkDocuments(postParams)
              .subscribe((result) => {
                this.documentEntities = result.documents;
                this.showLoader = false;
                // Set page scroll top to 0.
                const cardsContainer = document.getElementById('cards-container')
                if(cardsContainer) cardsContainer.scrollTop = 0;
              });
          })
    );

  }

  ngOnDestroy() {
    this.componentSubscription.unsubscribe();
    this.bookmarkDocumentServiceSubscription.unsubscribe();
  }

  get currentUrl(): string {
    return this.router.url;
  }

  get currentPage(): number {
    return this.bookmarkDocumentService.currentPage;
  }

  get BookmarkDocumentService() {
    return this.bookmarkDocumentService;
  }

}
