import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { StoredCollectionNames } from '../../models/stored-collection-names';
import { DbMatchStem, DbStoredMatchStems, MatchStem, StoredMatchStems } from '../../models/match-stem';
import { ApiService } from './api/api.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MatchStemsService {

  // Time to live, in milliseconds.
  TTL = 7 * 24 * 60 * 60 * 1000; // seven days

  constructor(
    private localStorageService: LocalStorageService,
    private apiService: ApiService
  ) { }

  /**
   * Method to store a StoreMatchStem inside localStorage with a TTL (time to live) list of storedContexts.
   * @param documentId document id
   * @param matchStems document list of matchStems to store
   */
  setMatchStemsWithExpiry(documentId: string, matchStems: MatchStem[]): void {
    const itemToStore = {
      'id': documentId,
      'match_stems': matchStems,
      'expiry': Date.now() + this.TTL
    };
    console.log(`item of id ${itemToStore.id} set to stem store`);
    this.localStorageService.storeItem(StoredCollectionNames.STORED_MATCH_STEMS, itemToStore);
  }

  /** Méthode to retrieve all StoredMatchStems from localStorage */
  getStoredMatchStems(): StoredMatchStems[] {
    const storedMatchStems = localStorage.getItem(StoredCollectionNames.STORED_MATCH_STEMS)
    if(!storedMatchStems) return [];
    return JSON.parse(storedMatchStems).storedItems
        .map((dbStoredMatchStems: DbStoredMatchStems) => {
          return new StoredMatchStems(dbStoredMatchStems);
        });
  }

  /** Method retrieving stored MatchStems for a unique document */
  getDocumentStoredMatchStems(documentId: string): MatchStem[] | undefined {
    const storedMatchStems = this.getStoredMatchStems();
    const documentMatchStems = storedMatchStems.find(item => item?.id === documentId);

    return documentMatchStems?.matchStems;
  }

  /** Generic method retrieving matchStems  */
  async getMatchStems(currentNavigationState: { [k: string]: any; } | undefined, documentId: string, openSource: string, openSourceId: string, collectiveOrderId: number, exportId: number) {
    // Current navigation && openSourceId unused at the moment
    let matchStems = null;

    const storedDocumentMatchStems = this.getDocumentStoredMatchStems(documentId);

    if (openSource === 'digest') {
      console.log('case DIGEST - match stem');
      // getting digest results s3's url for loading on next step
      const s3DeduplicatedFileUrl = await this.retrieveS3DeduplicatedDigestResultsFileUrlFromCollectiveOrderId(collectiveOrderId);
      // loading digest results document and retrieving match Stems from it
      matchStems = await this.retrieveDocumentMatchStemsFromS3(s3DeduplicatedFileUrl, documentId);
    } else if (openSource === 'share') {
      console.log('case SHARE - match stem');
      const sharedDocument = await this.retrieveSharedDocument(openSourceId);
      matchStems = sharedDocument?.matchStems;
    } else if (openSource === 'export') {
      console.log('case EXPORT - match stem');
      matchStems = await this.retrieveExportDocumentMatchStems(exportId);
    } else if (storedDocumentMatchStems) {
      console.log('default case STORE - match stem');
      matchStems = storedDocumentMatchStems;
    }
    return matchStems;
  }

  /** Async call to api to retrieve export's Match Stems */
  async retrieveExportDocumentMatchStems(exportId: number): Promise<MatchStem[] | undefined> {
    try {
      return await firstValueFrom(this.apiService.exportAdminDoc.getExportDocumentMatchStems(exportId));
    } catch (e) {
      console.log(`error retrieving matchStems from export - ${e}`);
      return undefined;
    }
  }

  /** Async call to api to retrieve S3DeduplicatedDigestResults file url from a collectiveOrderId */
  async retrieveS3DeduplicatedDigestResultsFileUrlFromCollectiveOrderId(collectiveOrderId: number): Promise<string|undefined|null> {
    try {
      return await firstValueFrom(this.apiService.territoryWatchSending
        .retrieveS3DeduplicatedDigestResultsFileUrlFromCollectiveOrderId(collectiveOrderId));
    } catch (e) {
      console.log(`error on retrieveS3DeduplicatedFileUrlFromCollectiveOrderId - ${e}`);
      return null;
    }
  }

  /** Method to retrieve document's matchStems from its digestResults file */
  async retrieveDocumentMatchStemsFromS3(s3DeduplicatedFileUrl: string | undefined | null, documentId: string): Promise<Array<MatchStem>|null|undefined> {
    try {
      return await firstValueFrom(this.apiService.territoryWatchSending
        .retrieveDocumentMatchStemsFromS3(s3DeduplicatedFileUrl, documentId));
    } catch (e) {
      console.log(`error on retrieveDocumentMatchStemsFromS3 - ${e}`);
      return null;
    }
  }

  /** Method to retrieve shared document's matchStems from its id */
  async retrieveSharedDocument(shareId: string) {
    try {
      return await firstValueFrom(this.apiService.share
        .getSharedDocument(shareId));
    } catch (e) {
      console.log(`error on retrieveSharedDocumentMatchStems - ${e}`);
      return null;
    }
  }

}
