<div class="container-fluid">
  <div class="row">
    <div class="col-sm-6 left-side"></div>
    <div class="col-sm-6 right-side d-flex">
      <div class="content">
        <div class="fw-bold title">{{"login.title" | translate }}</div>
        <div class="sub-title pb-4">{{"login.sub-title" | translate}}</div>

        <form [formGroup]="loginForm" (ngSubmit)="newLogin()">
          <div class="form-group">
            <label for="username"
                   class="login-label-title fw-bold"> {{"login.email" | translate | uppercase}}</label>
            <input type="text" formControlName="username" class="form-control login-form-control" id="username"
                   [ngClass]="{ 'is-invalid': isError }" data-cy="username-input"/>
            <div *ngIf="submitted && f['username'].errors" class="invalid-feedback">
              <div *ngIf="f['username'].errors?.['required']">Username is required</div>
            </div>
          </div>
          <div class="form-group">
            <label for="password"
                   class="login-label-title fw-bold">{{"login.password" | translate | uppercase}}</label>

            <div class="input-group">
              <input [type]="fieldTextType1 ? 'text' : 'password'" [ngClass]="{ 'is-invalid': isError }" formControlName="password" class="form-control login-form-control" id="password"
                     autocomplete="current-password" data-cy="password-input"/>
              <div class="input-group-append-hide-show">
                <span *ngIf="fieldTextType1"  (click)="toggleFieldTextType1()" class="hide-show">
                  <img class="hide-show-icon"
                       src="assets/images/hide-password-icon.svg" alt="icon-hide">
                </span>
                  <span *ngIf="!fieldTextType1" (click)="toggleFieldTextType1()" class="hide-show">
                  <img class="hide-show-icon"
                       src="assets/images/show-password-icon.svg" alt="icon-show">
                </span>
              </div>
            </div>

            <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
              <div *ngIf="f['password'].errors?.['required']">Password is required</div>
            </div>
          </div>

          <div *ngIf="isError" class="error">
            <img class="no-match-icon" src="assets/images/icon-alert-triangle.svg" alt="icon-no-match"/>
            <div class="no-match-text">{{ 'login.no-match' | translate }} </div>
          </div>
          <div class="login-btn-container form-group">
            <button [disabled]="loading"
                    class="fw-bold btn btn-login">{{'login.login' | translate | uppercase}}</button>
            <div [routerLink]="['/login/forgot-password']"
                 class="btn btn-password-forgotten">{{'login.password-forgotten' | translate}}</div>
          </div>
        </form>
      </div>
      <img class="logo" alt="explain-banner" src="assets/images/banner-explain.png"/>
    </div>
  </div>
</div>

