import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TerritoryWatchSettingService } from '../../shared/services/territory-watch-setting.service';
import daysJson from './days.json';
import { StepModel } from '../../core/step/step.model';
import { FunnelContextEnum, StepsService } from '../../shared/services/steps.service';
import { TerritoryWatchDayOfWeek } from '../../models/territory-watch-settings';
import { ApiService } from '../../shared/services/api/api.service';
import { first, tap } from 'rxjs/operators';
import { TerritoryWatchWithUsers, DbTerritoryWatchWithUsers } from '../../models/territory-watch';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-frequent-step-template',
  templateUrl: './frequent-step-template.component.html',
  styleUrls: ['./frequent-step-template.component.scss']
})
export class FrequentStepTemplateComponent implements OnInit {
  @Input() alertId?: string;
  @Input() step?: StepModel;
  @Output() otherUserAlerts = new EventEmitter<DbTerritoryWatchWithUsers[]>();
  name?: string;
  isNameUnique = true;
  days: Array<TerritoryWatchDayOfWeek>;
  daysSetting!: Array<TerritoryWatchDayOfWeek>;
  userAlerts: TerritoryWatchWithUsers[] = [];

  constructor(
    private stepsService: StepsService,
    private alertSettingService: TerritoryWatchSettingService,
    private apiService: ApiService
  ) {
    // initial set up of default pre-selection values (lu, me, ve).
    this.days = JSON.parse(JSON.stringify(daysJson.days));
    this.sendSelectDay();
  }

  ngOnInit() {
    if (this.stepsService.funnelContext === FunnelContextEnum.CREATION) {
      this.daysSetting = this.alertSettingService.territoryWatchSettings.selectedDayOfWeek;
      this.days = [...this.daysSetting];
      this.sendSelectDay();
    } else {
      this.alertSettingService.areSettingsRetrieved$.pipe(first()).subscribe(() => {
        this.name = this.alertSettingService.territoryWatchSettings.name.trim();
        this.daysSetting = this.alertSettingService.territoryWatchSettings?.selectedDayOfWeek;
        this.days = [...this.daysSetting];
        this.sendSelectDay();
        this.onNameChange();
        this.checkCompleted();
      });

      // Checking name uniqueness in case of duplication.
      const settingsPromise = lastValueFrom(this.alertSettingService.areSettingsRetrieved$);

      // Retrieving of user Alerts to manage name uniqueness
      const userId = localStorage.getItem('user_id');
      if (!userId) throw new Error('User id missing.');
      // Add fields  territory_uid and topics to help identify existence of lookalike alerts in tracking.
      const fields: Array<string> = ['id', 'name', 'territory_uid', 'topics'];
      const filters: object = {'user_ids': [+userId]};

      const userAlertsPromise = lastValueFrom(this.apiService.territoryWatch.getTerritoryWatchesAsDbTerritoryWatchWithUsers(fields, filters)
        .pipe(tap((alerts) => {
          this.userAlerts = alerts.map(x => new TerritoryWatchWithUsers(x));
          // Pass otherUserAlerts to the parent component.
          this.otherUserAlerts.emit(alerts);
        })));

      Promise.all([settingsPromise, userAlertsPromise])
        .then(() => {
          this.checkNameUniqueness();
          this.checkCompleted();
        });
    }
  }

  onSelectDay(dayIndex: number): void {
    this.days[dayIndex].selected = !this.days[dayIndex].selected;
    this.checkCompleted();
    this.sendSelectDay();
  }

  sendSelectDay(): void {
    const daysSelected = this.getSelectedDay();
    this.alertSettingService.setFrequent(daysSelected);
  }

  getSelectedDay(): string {
    return this.days?.filter(x => x.selected).map(x => x.value).toString();
  }

  onNameChange(): void {
    this.checkNameUniqueness();
    this.checkCompleted();
    if (this.name) this.alertSettingService.setName(this.name);
  }

  checkCompleted(): void {
    const isComplete = this.isNameUnique && this.name && this.name.length > 1 && this.getSelectedDay().length > 0;
    this.stepsService.updateStepCompletionState(3, !!isComplete);
  }

  checkNameUniqueness() {
    // Get other alerts, sifted by id.
    const otherAlerts = this.userAlerts.filter((alert) => +alert.id !== Number(this.alertId));
    // Alert name is unique if is not given to another alert.
    this.isNameUnique = otherAlerts.filter((alert) =>
      alert?.name?.trim().toLowerCase() === this.name?.trim().toLowerCase()).length === 0;
  }
}
