<div class="stats-page">
  <div class="title">{{'stats-page.'+pageName+'.title' | translate}}</div>
  <div class="d-flex">
    <img class="align-self-center" [src]="'/assets/images/icon-search-'+pageName+'.svg'" alt="icon-search-pagename"/>
    <div class="stats align-self-center">
      <div class="stats-label">{{'stats-page.'+pageName+'.stats-label' | translate}}</div>
      <div class="d-flex">
        <div class="d-flex">
          <div class="stats-number align-self-center text-nowrap">{{totalCount | insertText: ' ': 3}}</div>
          <div class="align-self-center">
            <div>{{'stats-page.'+pageName+'.listed-items' | translate}}</div>
            <div>{{'stats-page.in-national-base' | translate}}</div>
          </div>
        </div>
        <div class="d-flex source-stats">
          <div class="stats-number align-self-center text-nowrap">{{sourceCount | insertText: ' ': 3}}</div>
          <div class="align-self-center">
            <div>{{'stats-page.'+pageName+'.source-stats-1' | translate}}</div>
            <div>{{'stats-page.'+pageName+'.source-stats-2' | translate}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
