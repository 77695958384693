import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TerritoryWatchSettingService } from '../../shared/services/territory-watch-setting.service';
import { StepModel } from '../../core/step/step.model';
import { FunnelContextEnum, StepsService } from '../../shared/services/steps.service';
import { TerritoryWatchSettings, DbIssuerGroup, TerritoryWatchIssuerKindEnum } from '../../models/territory-watch-settings';
import { TreeviewConfig, TreeviewItem } from '../../lib/ngx-treeview';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { EventTypeName } from '../../models/user-tracker';
import { SourceStepService } from './source-step.service';
import { territoriesContainsEpt } from '../../shared/helpers/territory.helper';
import { EptTreeviewItem } from './source-treeview-items-data';
import { first } from 'rxjs/operators';
import { KeyValue } from '@angular/common';

@Component({
  selector: 'app-source-step-template',
  templateUrl: './source-step-template.component.html',
  styleUrls: ['./source-step-template.component.scss']
})
export class SourceStepTemplateComponent implements OnInit, OnDestroy {
  @Input() step?: StepModel;
  settings?: TerritoryWatchSettings;
  EventTypeName = EventTypeName;
  SourceTrackingName = {
    territoryKind: 'territory-kind',
    syndicate: 'syndicate'
  };
  config = TreeviewConfig.create();
  touchedStep = false;
  subscription = new Subscription();
  activeId = '';

  constructor(
    private stepsService: StepsService,
    private alertSettingService: TerritoryWatchSettingService,
    private translate: TranslateService,
    private sourceStepService: SourceStepService
  ) {
  }

  ngOnInit(): void {
    if (this.stepsService.funnelContext === FunnelContextEnum.CREATION) {
      this.settings = this.alertSettingService.territoryWatchSettings;
      this.sourceStepService.setUpSourceStepSectionFromScratch(this.settings);
      this.checkCompleted();
    } else {
      this.alertSettingService.areSettingsRetrieved$
        .pipe(first())
        .subscribe(() => {
          this.settings = this.alertSettingService.territoryWatchSettings;

          // Setting sources of TerritoryWatchSettings.
          // Building SourceStepSection attribute of TerritoryWatchSettings object this.settings.
          this.sourceStepService.setUpSourceStepSectionOfTerritoryWatchSettings(this.settings);
          this.manageEptToggle();
          const syndicates = this.settings.sourceStepSections.get('admin-docs')?.issuerGroups?.get('syndicates');
          if (syndicates) syndicates.checked = !!this.settings.issuerKind?.includes(TerritoryWatchIssuerKindEnum.SYNDICAT);
          this.checkCompleted();
        });
    }
    // management of checking, syndicates.
    this.subscription.add(this.stepsService.currentStep$
      .subscribe((currentStep) => {
        this.touchedStep = this.touchedStep || (currentStep.stepIndex === 2);
        if (this.touchedStep) {
          this.checkCompleted();
        }
      })
    );

    /** Syndicate Specific method 1/3 */
    this.subscription.add(
      this.alertSettingService.territoryManagers
        .subscribe((registry) => {
          this.sourceStepService.setUpSyndicatesAttributes(registry);
          if (this.settings) {
            this.sourceStepService.updateSyndicatesOnSourceStepSections(this.settings.sourceStepSections);
          }
        })
    );

    /** Syndicate Specific method 2/3 */
    this.subscription.add(
      this.alertSettingService.onPerimeterChange
        .subscribe((perimeter: { territory: any, values: any }[]) => {
          this.sourceStepService.updateValidSyndicates(perimeter);
          if (this.settings) {
            this.sourceStepService.updateSyndicatesOnSourceStepSections(this.settings.sourceStepSections);
            // Manage ept toggle for every perimeter change
            this.manageEptToggle();
            this.checkCompleted();
          }
        })
    );

    /** Syndicate Specific method 3/3 */
    this.subscription.add(
      this.stepsService.arePerimetersRetrieved$
        .subscribe((state) => {
          if (state && this.settings) {
            this.sourceStepService.setCheckedRecursiveSyndicates(this.settings);
          }
          if (this.settings) {
            this.sourceStepService.updateSyndicatesOnSourceStepSections(this.settings.sourceStepSections);
          }
        })
    );

  }

  originalOrder = (a: KeyValue<string,any>, b: KeyValue<string,any>): number => {
    return 0;
  }

  onSelectedItemChanged(issuerGroup: DbIssuerGroup, value: Array<any>): void {
    issuerGroup.selectedItems = value;
    if (issuerGroup.name === 'syndicates') {
      this.alertSettingService.setSyndicates(value);
    }
    this.checkCompleted();
  }

  issuerGroupBadgeNumber(issuerGroup: DbIssuerGroup): string {
    if (issuerGroup.treeviewItems) {
      const selection = issuerGroup.treeviewItems[0]?.getSelection();
      const allItemsLength = selection?.checkedItems.length + selection?.uncheckedItems.length;
      if (selection?.checkedItems.length < allItemsLength) {
        return `${selection.checkedItems.length}/${allItemsLength}`;
      }
    }
    return "";
  }

  countChecked(item: TreeviewItem) {
    return item.children?.reduce((acc, child) => acc + (child.checked ? 1 : 0), 0);
  }

  resetIssuerGroupTreeviewItems(issuerGroup: DbIssuerGroup): void {
    issuerGroup.treeviewItems.forEach((item) => item.setCheckedRecursive(true));
    issuerGroup.selectedItems = issuerGroup?.itemsStore;
    this.onSelectedItemChanged(issuerGroup, issuerGroup.selectedItems);
  }

  onToggleIssuerGroup(issuerGroup: DbIssuerGroup): void {
    issuerGroup.treeviewItems.forEach((item) => {
      item.disabled = !issuerGroup.checked;
    });
    // if previous selection was null, set all items to checked.
    if (issuerGroup.checked && (issuerGroup.treeviewItems[0]?.getSelection().checkedItems.length === 0)) {
      issuerGroup.treeviewItems.forEach((item) => item.setCheckedRecursive(true));
    }
    this.checkCompleted();
  }

  onAccordionToggle(issuerGroup: DbIssuerGroup): void {
    this.activeId = issuerGroup.name;
  }

  checkCompleted(): void {
    let isComplete = false;
    let atLeastOneIssuerGroupChecked = false;
    if (!this.settings) return;
    for (const stepSection of this.settings.sourceStepSections.values()) {
      for (const issuerGroup of stepSection.issuerGroups?.values() || []) {
        atLeastOneIssuerGroupChecked = atLeastOneIssuerGroupChecked || issuerGroup.checked;
        if (issuerGroup.name === 'press') {
          // press is a particula case : it doesn't have any treeviewItems.
          isComplete = isComplete || issuerGroup.checked;
        } else {
          isComplete = isComplete || (issuerGroup.checked && issuerGroup.treeviewItems[0]?.getSelection().checkedItems.length > 0);
        }
      }
    }
    this.updateSourceStepCompletionState(isComplete && atLeastOneIssuerGroupChecked);
    this.alertSettingService.territoryWatchSettings.sourceStepSections = this.settings.sourceStepSections;
  }

  updateSourceStepCompletionState(isComplete: boolean) {
    if (this.stepsService.funnelContext === FunnelContextEnum.CREATION) {
      this.stepsService.updateStepCompletionState(2, isComplete && this.touchedStep);
    } else {
      this.stepsService.updateStepCompletionState(2, isComplete);
    }
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /** getters and setters to sourceStepService instances */
  get validSyndicates(): Array<string> | undefined {
    return this.sourceStepService.validSyndicates;
  }

  /** Methode pour gérer le cas particulier des EPT qui s'ajoutent, le cas échéant, aux émetteurs de documents administratifs */
  manageEptToggle() {
    const settings = this.alertSettingService.getSetting();
    const children = this.settings?.sourceStepSections.get('admin-docs')?.issuerGroups?.get('comm-and-epci')?.treeviewItems[0]?.children || [];
    // cas ajout - si territoire contient EPT et que EPT n'est pas dans la liste des émetteurs
    if (territoriesContainsEpt(settings.territories) && !children.find((child) => child.value === TerritoryWatchIssuerKindEnum.EPTP)) {
      this.settings?.issuerKind.push(TerritoryWatchIssuerKindEnum.EPTP);
      // add issuer group item to sourceStepSection object
      this.settings?.sourceStepSections.get('admin-docs')?.issuerGroups?.get('comm-and-epci')?.treeviewItems[0]
        .children.push(new TreeviewItem(EptTreeviewItem));
      // update store
      this.updateCommuneAndEpciIssuerGroupItemStore();
      // update section title / subtitle
      this.setCommAndEpciSectionTitle(true);
    }
    // cas retrait - si territoire ne contient pas EPT et que EPT est dans la liste des émetteurs
    if (
        !territoriesContainsEpt(settings.territories) &&
        children.find((child) => child.value === TerritoryWatchIssuerKindEnum.EPTP) &&
        this.settings
    ) {
      // remove EPT from issuerKind list
      this.settings.issuerKind = this.settings.issuerKind.filter((kind) => kind !== TerritoryWatchIssuerKindEnum.EPTP);
      // remove issuer group item from sourceStepSection object
      const commEpci = this.settings.sourceStepSections.get('admin-docs')?.issuerGroups?.get('comm-and-epci');
      if (commEpci)
        commEpci.treeviewItems[0].children = children.filter((child) => child.value !== TerritoryWatchIssuerKindEnum.EPTP);
      // update store
      this.updateCommuneAndEpciIssuerGroupItemStore();
      // update section title / subtitle
      this.setCommAndEpciSectionTitle(false);
    }
  }

  /** réalignement du store d'items relativement aux nouveaux items */
  updateCommuneAndEpciIssuerGroupItemStore() {
    const issuerGroup = this.settings?.sourceStepSections.get('admin-docs')?.issuerGroups?.get('comm-and-epci');
    if (issuerGroup) {
      // maj du store en utilisant la méthode getSelection de treeview
      issuerGroup.itemsStore = issuerGroup.treeviewItems[0].getSelection().checkedItems
        .concat(issuerGroup.treeviewItems[0].getSelection().uncheckedItems);
    }
  }

  setCommAndEpciSectionTitle(isWithEpt: boolean) {
    const suffix = 'alert.issuer-kind.comm-and-epci-';
    const commEpci = this.settings?.sourceStepSections.get('admin-docs')?.issuerGroups?.get('comm-and-epci')
    if (commEpci) {
      commEpci.title = suffix + (isWithEpt ? 'and-ept-title' : 'title');
      commEpci.subTitle = suffix + (isWithEpt ? 'and-ept-subtitle' : 'subtitle');
    }
  }

}
