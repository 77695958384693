/** Structure of a Match Stem as retrieve from explain DB */
export interface DbMatchStem {
  stem: string;
  occurrences: number;
  items: string[];
}

/** Structure of a Match Stem as used in explain Front */
export class MatchStem {
  private _stem: string;
  private _occurrences: number;
  private _items: string[];

  constructor(dbMatchStem: DbMatchStem) {
    this._stem = dbMatchStem.stem;
    this._occurrences = dbMatchStem.occurrences;
    this._items = dbMatchStem.items;
  }

  get stem(): string {
    return this._stem;
  }

  set stem(value: string) {
    this._stem = value;
  }

  get occurrences(): number {
    return this._occurrences;
  }

  set occurrences(value: number) {
    this._occurrences = value;
  }

  get items(): string[] {
    return this._items;
  }

  set items(value: string[]) {
    this._items = value;
  }
}

export interface DbStoredMatchStems {
  id: string;
  match_stems: MatchStem[];
  expiry: number;
}

export class StoredMatchStems {
  private _id: string;
  private _matchStems: MatchStem[];
  private _expiry: number;

  constructor(storedMatchStems: DbStoredMatchStems) {
    this._id = storedMatchStems.id;
    this._matchStems = storedMatchStems.match_stems;
    this._expiry = storedMatchStems.expiry;
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get matchStems(): MatchStem[] {
    return this._matchStems;
  }

  set matchStems(value: MatchStem[]) {
    this._matchStems = value;
  }

  get expiry(): number {
    return this._expiry;
  }

  set expiry(value: number) {
    this._expiry = value;
  }
}

export interface MatchStemsSearchInterface {
  stem: string;
  items: string[];
  itemsIndexesAmongSearchList: number[];
  distinctOccurrencePages: number[] | null;
  occurrencePages: number[] | null;
  type?: SearchKeywordTypeEnum;
}

export interface DbSearchKeyword {
  type: SearchKeywordTypeEnum;
  value: string;
}

export enum SearchKeywordTypeEnum {
  STEM = 'stem',
  USER_INPUT = 'input'
}
