import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-modal-nps-form',
  templateUrl: './modal-nps-form.component.html',
  styleUrls: ['./modal-nps-form.component.scss']
})
export class ModalNPSFormComponent implements OnInit {
  @Input() email = '';
  @Input() company = '';
  thanksPage = false;
  srcIframe = '';

  constructor(
    private activeModal: NgbActiveModal,
  ) {

    window.onmessage = (e) => {
      if (e.data === 'thanks') {
        this.resizeIframe();
      }
    };
  }

  ngOnInit(): void {
      this.srcIframe = `${environment.npsFormUrl}?email=${this.email}&company=${this.company}`;
  }

  resizeIframe(): void {
    this.thanksPage = true;
  }

  closeModal(): void {
    this.activeModal.close();
  }

  dismissModal(): void {
    this.activeModal.dismiss('reminderNps');
  }

}
