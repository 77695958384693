<div class="d-flex align-items-center justify-content-center pt-3">
  <button type="button" class="btn-close" aria-label="Close" (click)="thanksPage ? closeModal() : dismissModal()">
    <mat-icon class="icon-20 strict" svgIcon="close-circle"></mat-icon>
  </button>
  <iframe *ngIf="srcIframe"
          title="npsForm"
          class="iframe-form"
          [ngClass]="{
              'thanks': thanksPage
            }"
          [src]="srcIframe | safeResourceUrl"
  ></iframe>
</div>
<div class="modal-footer">
  <button *ngIf="!thanksPage; else closeModalButton" class="footer-button" (click)="dismissModal()">
    <span>{{"nps-form.remind-me-later" | translate}}</span>
  </button>
</div>

<ng-template #closeModalButton>
  <button class="footer-button" (click)="closeModal()">
    <span>{{"nps-form.close" | translate}}</span>
  </button>
</ng-template>
