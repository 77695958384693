<div class="d-flex justify-content-between task-bar">
  <div class="count-info">
    <div class="fw-bold">
      <ng-container *ngIf="currentPage !== 'impacter' && inSearch">
        {{(resultCount == 10000 ? 'search.more-than' : "") | translate}}
        {{resultCount | insertText: ' ': 3}}
        {{(resultCount && resultCount > 1 ? 'search.results-for-this-term' : 'search.result-for-this-term') | translate}}
      </ng-container>
      <ng-container *ngIf="currentPage === 'impacter' || !inSearch">
        {{resultCount | insertText: ' ': 3}}
        {{( resultCount && resultCount > 1 ? 'search.results' : 'search.result') | translate}}
      </ng-container>
    </div>
    <div class="sub-info">
      <ng-container *ngIf="allCount > 1">
        {{'search.'+pageName+'-results'
        | translate: ({totalCount: (allCount  | insertText: ' ': 3)})}}
      </ng-container>
      <ng-container *ngIf="allCount === 1">
        {{'search.'+pageName+'-result'
        | translate: ({totalCount: (allCount  | insertText: ' ': 3)})}}
      </ng-container>
      <ng-template #sources>
        <ul class="sources-list text-start" >
          <li *ngFor="let source of sourcesList" class="source">{{source}}</li>
        </ul>
      </ng-template>
      <ng-container *ngIf="nbResource > 1 && currentPage != 'impacter'">
        {{'search.'+pageName+'-sub-results'
        | translate: ({nbWebsite: (nbResource  | insertText: ' ': 3)})}}
        <mat-icon *ngIf="currentPage == 'article'" class="info-sources icon-info" svgIcon="info"
                  [ngbTooltip]="sources"
                  tooltipClass="custom-tooltip-sources"
                  placement="right-top"
                  container="body">
        </mat-icon>
      </ng-container>
      <ng-container *ngIf="nbResource === 1 && currentPage != 'impacter'">
        {{'search.'+pageName+'-sub-result'
        | translate: ({nbWebsite: (nbResource  | insertText: ' ': 3)})}}
        <mat-icon *ngIf="currentPage == 'article'" class="info-sources icon-info" svgIcon="info"
                  [ngbTooltip]="sources"
                  tooltipClass="custom-tooltip-sources"
                  placement="right-top"
                  container="body">
        </mat-icon>
      </ng-container>
    </div>
  </div>
  <div class="btn-group" role="group">
    <div class="dropdown pagination-btn">
      <span>{{'search.limit-by-page' | translate}}</span>
      <button class="btn dropdown-toggle" type="button" id="dropdownPagination"  data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        {{currentLimit}}
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownPagination">
        <div class="dropdown-item"
             *ngFor="let limit of limitValues"
             (click)="onLimitChange(limit)"
             [ngClass]="{'active': limit == currentLimit}">{{limit}}</div>
      </div>
    </div>
    <div [hidden]="currentPage == 'impacter'" class="dropdown sort-btn">
      <span>{{'search.sort-by' | translate}}</span>
      <button class="btn dropdown-toggle" type="button" id="dropdownSort" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        {{('search.sort-by-'+currentSort['field']+'-'+currentSort['dir']) | translate}}
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownSort">
        <div class="dropdown-item"
             *ngFor="let sortBy of filterSortList()"
             (click)="onSortChange(sortBy)"
             [ngClass]="{'active': sortBy['field'] == currentSort['field'] && sortBy['dir'] == currentSort['dir']}">
          {{('search.sort-by-'+sortBy['field']+'-'+sortBy['dir']) | translate}}
        </div>
      </div>
    </div>
  </div>
</div>
