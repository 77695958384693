import { TerritoryWatchIssuerKindEnum } from '../territory-watch-settings';

export class TerritoryWatchOverviewParams {
  day_of_week?: string;
  issuer_kind?: TerritoryWatchIssuerKindEnum[];
  project_publication_types?: string[];
  topics_ids?: string[];
  territories?: object[];
  syndicates?: string[];
  limit?: number;
  territory_watch_offset?: number;
  offset?: number;

  constructor(day_of_week?: string,
              issuer_kind?: TerritoryWatchIssuerKindEnum[],
              project_publication_types?: string[],
              topics_ids?: string[],
              territories?: object[],
              syndicates?: string[],
              limit?: number,
              territory_watch_offset?: number,
              offset?: number) {
    this.day_of_week = day_of_week;
    this.issuer_kind = issuer_kind;
    this.project_publication_types = project_publication_types;
    this.topics_ids = topics_ids;
    this.territories = territories;
    this.syndicates = syndicates;
    this.limit = limit;
    this.territory_watch_offset = territory_watch_offset;
    this.offset = offset;
  }
}
