import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { DisplayedPublicationType } from '../../../models/displayed-publication-type';
import { TerritoryForArticle } from '../../../models/territory/territory-utils';

@Component({
  selector: 'app-metadata',
  templateUrl: './metadata.component.html',
  styleUrls: ['./metadata.component.scss']
})
export class MetadataComponent implements OnInit, OnChanges {
  @ViewChild('territoryNamesContainer') territoryNamesContainer!: ElementRef;
  tooltipText = '';
  maxHeight = 72; // size of 3 lines.

  // commun à tous les documents
  @Input() publicationDate!: Date | null;
  @Input() website?: string;
  @Input() displayedPublicationType?: string;
  @Input() territories?: TerritoryForArticle[];
  @Input() issuerKind?: string;                          // ~macro catégorie : type d'émetteur
  @Input() documentType!: string;                         // ~micro catégorie : type de document
  // propre aux articles
  @Input() source?: string;

  DisplayedPublicationType = DisplayedPublicationType;
  @Input() territoryNames?: string[];

  ngOnInit(): void {
    // Manage case of displayedPublicationType not provided
    if (!this.displayedPublicationType) {
      this.displayedPublicationType = DisplayedPublicationType.NON_DETECTED;
    }

    if (this.website)
      this.website = (new URL(this.website)).hostname.replace('www.', '') || '';

  }

  ngOnChanges() {
    if (this.territories) {
      this.territoryNames = this.territories.map(t => t.name);
    }
  }
}
