<div class="d-flex" *ngIf="occurrencePagesCounter >= 1">

  <div class="fading-div left-fading-div"
       [ngClass]="{'hide': !displayFadingDivs.left, 'show': displayFadingDivs.left}">
  </div>

  <div class="pagination-container d-flex">

    <div *ngIf="occurrencePagesCounter > 1"
         class="change-page-icon previous d-table"
    (click)="onPreviousOccurrencePage()">
      <mat-icon svgIcon="pagination-left-basic-800" class="strict me-2 d-table-cell align-middle"></mat-icon>
    </div>

    <div #fixedContainerScrollable id="fixed-container-scrollable" class="fixed-size-pagination-container">
      <div class="h-100 d-table">
        <div class="pages-part d-table-cell align-middle">
          <div class="page-part-container d-flex">
            <div *ngFor="let representation of pagesRepresentationArray; let i = index; trackBy: trackByPage "
                 (click)="onClickPage(representation)">

              <div *ngIf="representation.style === PageRepresentationTypes.FIRST_OR_LAST_PAGE"
                   class="page first-or-last-page d-table">
                <div class="d-table-cell align-middle">
                  <span class="d-flex justify-content-center">{{representation.page}}</span>
                </div>
              </div>

              <div *ngIf="representation.style === PageRepresentationTypes.ONE_DOT"
                   [ngClass]="{'current-dots' : (currentPage > (representation['spread']?.previousPage ?? 0) &&
                                                (currentPage < (representation['spread']?.nextPage ?? 0)) &&
                                                (pagesRepresentationArray.length > 3))}"
                   class="dots d-table">
                <div class="d-table-cell align-middle">
                  <span class="d-flex justify-content-center">•</span>
                </div>
              </div>

              <div *ngIf="representation.style === PageRepresentationTypes.THREE_DOTS"
                   [ngClass]="{'current-dots' : (currentPage > (representation['spread']?.previousPage ?? 0) &&
                                                (currentPage < (representation['spread']?.nextPage ?? 0)) &&
                                                (pagesRepresentationArray.length > 3))}"
                   class="dots d-table">
                <div class="d-table-cell align-middle">
                  <span class="d-flex justify-content-center">•••</span>
                </div>
              </div>

              <div *ngIf="representation.style === PageRepresentationTypes.DEFAULT"
                   [ngClass]="{'current-page': currentPage === representation.page}"
                   class="page page-with-occurrence d-table">
                <div class="d-table-cell align-middle">
                  <span class="d-flex justify-content-center">{{representation.page}}</span>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="occurrencePagesCounter > 1"
      class="change-page-icon next d-table"
         (click)="onNextOccurrencePage()">
      <mat-icon svgIcon="pagination-right-basic-800" class="strict me-2 d-table-cell align-middle"></mat-icon>
    </div>

  </div>

  <div class="fading-div right-fading-div"
       [ngClass]="{'hide': !displayFadingDivs.right, 'show': displayFadingDivs.right}">
  </div>

</div>
