import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { ApiService } from '../../shared/services/api/api.service';
import { HttpParams } from '@angular/common/http';
import { UserSessionService } from "../../shared/services/user-session.service";


@Component({
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm!: FormGroup;
  loading = false;
  isError = false;
  submitted = false;
  returnUrl!: string;
  returnQueryParams = new HttpParams();
  fieldTextType1 = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private userSessionService: UserSessionService,
    private apiService: ApiService
  ) {
    // redirect to home if already logged in.
    if (this.userSessionService.userSession) {
      this.router.navigate(['/']).then();
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.route.queryParams
      .subscribe((params) => {
          if (!params) { return; }
          const fromUrlParts = String(params['fromUrl']).split('?');
          // Manage return url and query params
          if (fromUrlParts.length) {
            this.returnUrl = fromUrlParts[0];
            const urlSearchParams = new URLSearchParams(fromUrlParts[1]);
            for (let [key, value] of urlSearchParams.entries()) {
              this.returnQueryParams = this.returnQueryParams.append(key, value);
            }
          } else {
            this.returnUrl = params['fromUrl'];
          }
        }
      );
    // get return url from route parameters or default to '/'.
    this.returnUrl = this.returnUrl || '/';
  }

  newLogin() {
    this.loading = true;
    this.isError = false;
    this.fieldTextType1 = false;

    this.authenticationService
      .newLogin(this.f['username'].value.trim(), this.f['password'].value)
      .then(
        () => {
          this.setSessionInstances();
          this.navigate();
        })
      .catch(
        () => {
          this.loading = false;
          this.isError = true;
        });
  }

  /** Set session values. */
  setSessionInstances() {
    this.apiService.exStatistics.setAccountInfos();
    this.apiService.exStatistics.setExplainStatistics();
  }

  /** Manage routing navigation in fonction of queryParams and module access. */
  navigate() {
    if (this.returnQueryParams.toString()) {
      this.router.navigate([this.returnUrl], {
        queryParams: this.returnQueryParams
      }).then();
      // navigate in function of module access.
    } else if (this.userSessionService.userSession?.modules.length === 2 &&
               this.userSessionService.userSession?.modules.includes('tenders')) {
      // deux modules : go page veille marché.
      this.router.navigate(['/tenders/watch']).then();

    } else if (this.userSessionService.userSession?.modules.every(elm => elm === 'tenders')) {
      // module tenders : go page veille marché.
      this.router.navigate(['/tenders/watch']).then();
    } else {
      // module explain : go page explain recherche.
      this.router.navigate([this.returnUrl]).then();
    }
  }

  toggleFieldTextType1() {
    this.fieldTextType1 = !this.fieldTextType1;
  }

  // convenience getter for easy access to form fields.
  get f() {
    return this.loginForm.controls;
  }


}
