<div [hidden]="impacterId" class="contain" #impacter id="{{impacterPageId}}">
  <app-loader></app-loader>
  <app-result-message *ngIf="totalCount === 0" [inImpacterPage]="true"></app-result-message>
  <div [hidden]="!totalCount" class="list-content">
    <ng-container *ngFor="let impacter of entities; let i = index">
      <div *ngIf="!impacter.byFilter" class="suggestion-sector">
        <div class="fw-bold">{{suggestionCount}} {{'content.suggestion-label' | translate}}</div>
        <div>{{'content.suggestion-sub-label' | translate}}</div>
      </div>
      <app-impacter-card
        [impacterId]="impacter.id"
        [name]="impacter.name"
        [positions]="impacter.positions"
        [byFilter]="impacter.byFilter"
      >
      </app-impacter-card>
    </ng-container>
    <app-pagination *ngIf="nbPages > 1" [nbPages]="nbPages" [stats]="dataUpdated"></app-pagination>
  </div>
</div>
<router-outlet></router-outlet>
