<div class="form-topic-container container-fluid">
  <div class="justify-content-center">
    <div class="col-12 p-0">
      <div class="w-100 search-zone">
        <span class="label">
          {{'topic.search'|translate}}
        </span>
        <span>
          <input [formControl]="search" type="text" placeholder="{{'topic.placeholder'|translate}}">
        </span>
      </div>
    </div>
    <div class="topics-section">
      <div class="title" *ngIf="false">1 - {{'wizard.topics-of-your-alert' | translate}}</div>
      <div class="pb-3">
        <ng-container *ngIf="customTopics.length > 0">
          <div class="topic-kind">
            {{'topic.custom' | translate | uppercase}}
          </div>
          <div *ngIf="customTopics.length === 0" class="topic-not-found">
            {{'topic.notfound-custom' | translate}}
          </div>
          <div>
            <div class="card-board total-step-width"
                 *ngFor="let topic of customTopics | sortText: 'name' : false; let i = index">
              <div class="d-flex justify-content-center">
                <div class="col-1 d-flex">
                  <div class="align-self-center">
                    <label class="switch checkbox"
                           [ngbTooltip]="!topic.isActive && limiterAchieved ? ('wizard.limiters-topics' | translate) : ''"
                           placement="right" triggers="click hover focus">
                      <input type="checkbox"
                             [(ngModel)]="topic['isActive']"
                             (change)="onTopicsChange()"
                             [disabled]="!!(!topic.isActive && limiterAchieved)"
                      >
                      <span
                        [ngClass]="{'not-allowed': !topic.isActive && limiterAchieved, 'slider': true, 'round': true}"></span>
                    </label>
                  </div>
                </div>
                <div class="col-11">
                  <div class="fw-bold name">{{topic.name}}</div>
                  <div class="description" *ngIf="topic.description">{{topic.description}}</div>
                  <div class="description no-result"
                       *ngIf="!topic.description">{{'wizard.no-description' | translate}}</div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container>
          <div class="topic-kind--default">
            {{'topic.default' | translate | uppercase}}
          </div>
          <div *ngIf="defaultTopics.length === 0 && searchValue.length !== 0" class="topic-not-found">
            {{'topic.notfound-default'|translate}}
          </div>
          <div>
            <div class="card-board total-step-width" data-cy="topic-step-create-alert-default-topic-card"
                 *ngFor="let topic of defaultTopics | sortText: 'name' : false; let i = index">
              <div class="d-flex justify-content-center">
                <div class="col-1 d-flex">
                  <div class="align-self-center">
                    <label class="switch checkbox"
                           [ngbTooltip]="!topic.isActive && limiterAchieved ? ('wizard.limiters-topics' | translate) : ''"
                           placement="right" triggers="click hover focus">
                      <input type="checkbox"
                             [(ngModel)]="topic['isActive']"
                             (change)="onTopicsChange()"
                             [disabled]="!!(!topic.isActive && limiterAchieved)"
                      >
                      <span
                        data-cy="topic-step-create-alert-default-topic-slider-round"
                        [ngClass]="{'not-allowed': !topic.isActive && limiterAchieved, 'slider': true, 'round': true}"></span>
                    </label>
                  </div>
                </div>
                <div class="col-11">
                  <div class="fw-bold name">{{topic.name}}</div>
                  <div class="description" *ngIf="topic.description">{{topic.description}}</div>
                  <div class="description no-result"
                       *ngIf="!topic.description">{{'wizard.no-description' | translate}}</div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
