import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { LocalStorageService } from './local-storage.service';
import { StoredCollectionNames } from '../../models/stored-collection-names';
import { ApiService } from './api/api.service';

@Injectable({
  providedIn: 'root'
})
export class ContextService {

  // Time to live, in milliseconds.
  TTL = 24 * 60 * 60 * 1000; // one day

  constructor(
    private apiService: ApiService,
    private localStorageService: LocalStorageService
  ) { }

  async getSharedDocumentContext(shareId: string) {
    const document = await firstValueFrom(this.apiService.share.getSharedDocument(shareId)).catch((e)=> {
      console.log(`error when retrieving context from share: ${e}`);
    });
    return document?.snippet
  }

  async retrieveContextFromCollectiveOrder(documentId: string , collectiveOrderId: number) {
    try {
      const response: any = await firstValueFrom(this.apiService.territoryWatchSending.retrieveContextFromCollectiveOrder(collectiveOrderId))
      for (const doc of response.docs) {
        if (doc.id === documentId) {
          return doc.excerpt;
        }
      }
    } catch (e) {
      console.log(`error when retrieving context from collective order: ${e}`);
      return null;
    }
  }

  async getContext(currentNavigationState: { [k: string]: any; } | undefined, documentId: string, openSource: string, openSourceId: string, collectiveOrderId: number) {
    let snippet = null;

    let storedContexts = this.getStoredContexts();
    if (!storedContexts) {
      storedContexts = {storedItems: []};
    }
    const storedContext = storedContexts.find((item:any) => item?.id === documentId);

    if (openSource === 'share') {
      snippet = await this.getSharedDocumentContext(openSourceId);
    } else if (openSource === 'digest') {
      snippet = await this.retrieveContextFromCollectiveOrder(documentId, collectiveOrderId);
      console.log('snippet', snippet);
    } else if (currentNavigationState?.['context']) {
      snippet = currentNavigationState['context'];
    } else if (storedContext) { // default case
      snippet = storedContext.snippet;
    }
    return snippet;
  }

  /**
   * Method to store a context inside localStorage with a TTL (time to live) list of storedContexts.
   * @param documentId document id
   * @param snippet context
   */
  setSnippetWithExpiry(documentId: string, snippet?: string): void {
    const itemToStore = {
      'id': documentId,
      'snippet': snippet,
      'expiry': Date.now() + this.TTL
    };
    this.localStorageService.storeItem(StoredCollectionNames.STORED_CONTEXTS, itemToStore);
  }

  setProjectUidWithExpiry(documentId: string, projectUid: string): void {
    if (!projectUid) {return; }
    const itemToStore = {
      id: documentId,
      projectUid: projectUid,
      expiry: Date.now() + this.TTL
    };
    this.localStorageService.storeItem(StoredCollectionNames.STORED_PROJECT_UIDS, itemToStore);
  }

  getStoredProjectUids(): any {
    const storedProjectUids = localStorage.getItem(StoredCollectionNames.STORED_PROJECT_UIDS);
    if(storedProjectUids) return JSON.parse(storedProjectUids)?.storedItems;
  }

  getStoredContexts(): any {
    const storedContexts = localStorage.getItem(StoredCollectionNames.STORED_CONTEXTS);
    if(storedContexts) return JSON.parse(storedContexts).storedItems;
  }
}

