<div class="container-fluid h-100" #pdfViewContainer>
  <div class="h-100 d-flex flex-wrap-reverse">
    <div class="left-content">
      <app-admin-doc-pdf *ngIf="adminDoc"
                         [adminDoc]="adminDoc"
                         [pdfView]="pdfView"
                         [time]="time"></app-admin-doc-pdf>
    </div>
    <div *ngIf="adminDoc" class="info-section" data-cy="admin-doc-view-info-section">
      <app-metadata id="admin-doc-metadata-panel"
        [documentType]="'admin_doc'"
        [website]="adminDoc.website"
        [publicationDate]="adminDoc.publicationDate"
        [territories]="adminDoc.territories"
        [issuerKind]="adminDoc.source"
        [displayedPublicationType]="adminDoc.displayedPublicationType"
      ></app-metadata>
      <app-saving-panel [document]="{
                          id: adminDoc.id,
                          type: type,
                          title: adminDoc.title,
                          snippet: adminDoc.snippet,
                          bookmarkPaths: adminDoc.bookmarkPaths,
                          matchStems: adminDoc.matchStems,
                          projectUid: project.id
                        }"
                        (bookmarkPathsUpdated)="bookmarkPathsUpdated(adminDoc.id, $event)">
      </app-saving-panel>
      <div class="d-flex">
        <app-expandable-share-panel
          class="sharing-panel"
          [document]="documentToBeShared"
          [withExportPanel]="true"
          (toggle)="sharingPanelChangeExpandedState($event)"
        />
        <app-export-panel [hidden]="isSharingPanelOpen"
                          [document]="adminDoc"
                          [documentMetaData]="documentMetaData"
                          [routeParams]="adminDocRouteParams">
        </app-export-panel>
      </div>

      <!--Panel "associated with" displayed in case of a prefectoral doc. Only if at least one other document linked to it-->
      <ng-container *ngIf="adminDoc.publicationType.includes(TerritoryWatchProjectPublicationTypeEnum.ARRETE) &&
                           (project.totalDocumentsCount ?? 1) - 1 > 0">

        <div class="separation"></div>

        <div class="title-associated-panel-section font-18px color-basic-600 fw-700 pdb-24px">
          {{'document.associated-to.title' | translate | uppercase}}
        </div>

        <app-associated-panel
                id="associated-with-panel"
                class="d-flex position-relative"
                [panelTitle]="project.title"
                [externalLinkSectionTitle]="'document.associated-to.ext-link-pref-text' | translate"
                [externalLinkUrl]="project.url"
        >
          <!--content projected inside second line of component-->
          <div class="fw-700 font-14px d-flex">
            <div class="color-basic-800">
              {{(project.totalDocumentsCount ?? 1) - 1}}
              <span *ngIf="(project.totalDocumentsCount ?? 1) - 1 <= 1">{{'document.associated-to.one-other-doc' | translate}}</span>
              <span *ngIf="(project.totalDocumentsCount ?? 1) - 1 > 1">{{'document.associated-to.others-doc' | translate}}</span>
            </div>
            <div class="fst-italic color-basic-600 mgl-8px">
              ({{'document.associated-to.updated-on' | translate}}
              {{project.updatedAt | date: 'dd/MM/yyyy'}})
            </div>
          </div>
        </app-associated-panel>

      </ng-container>

    </div>
  </div>
</div>
