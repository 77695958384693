import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-associated-panel',
  templateUrl: './associated-panel.component.html',
  styleUrls: ['./associated-panel.component.scss']
})
export class AssociatedPanelComponent implements OnInit {

  @Input() panelTitle!: string;
  @Input() externalLinkSectionTitle!: string;
  @Input() externalLinkUrl!: string;

  constructor() { }

  ngOnInit(): void {
  }

}
