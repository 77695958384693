<div id="cards-container" class="cards-container" [hidden]="!!entityId">
  <div *ngIf="showLoader" class="loader-container">
    <app-simple-loader></app-simple-loader>
  </div>
  <div *ngIf="documentEntities && documentEntities.length > 0 || !!entityId; else placeholderEmptySection" class="cards-max-width" [hidden]="showLoader">
    <ng-container *ngIf="documentsType === bookmarkDocumentEntityType.ARTICLE">
      <ng-container *ngFor="let document of documentEntities">
        <app-article-card
          [isPreview]="false"
          [allowOnClick]="true"
          [docId]="document.id"
          [title]="document.title"
          [publicationDate]="document.publicationDate"
          [text]="document.excerpt"
          [source]="document.source"
          [displayBookmarkCreationDate]="true"
          [bookmarkPaths]="document.bookmarkPaths"
          [folderId]="folderId"
          [territories]="document.territories"
          [photoCount]="$any(document)['html']?.nbImages"
          [showOccurrences]="false"
        >
        </app-article-card>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="documentsType === bookmarkDocumentEntityType.ADMIN_DOC">
      <ng-container *ngFor="let document of documentEntities">
        <app-admin-doc-card
          [docId]="document.id"
          [title]="document.title"
          [publicationDate]="document.publicationDate"
          [text]="document.excerpt ?? ''"
          [website]="$any(document)['website']"
          [url]="$any(document)['url']"
          [showOccurrences]="false"
          [bookmarkPaths]="document.bookmarkPaths"
          [folderId]="folderId"
          [displayedPublicationType]="$any(document)['displayedPublicationType']"
          [matchStems]="$any(document)['matchStems']"
          [projectUid]="$any(document)['projectUid']"
        >
        </app-admin-doc-card>
      </ng-container>
    </ng-container>

    <app-item-list-pagination
      *ngIf="(nbPages > 1)"
      [itemsService]="BookmarkDocumentService"
      [currentPage]="currentPage"
      [nbPages]="nbPages"
    ></app-item-list-pagination>
  </div>
</div>

<section>
  <router-outlet></router-outlet>
</section>


<ng-template #placeholderEmptySection>
  <div class="cards-container d-flex flex-column placeholder-font padding-top-20" *ngIf="documentEntities" >
    <span class="title-placeholder">{{
      (documentsType === bookmarkDocumentEntityType.ARTICLE
          ? 'folder.placeholder-no-article-title'
          : 'folder.placeholder-no-admin-doc-title'
      ) | translate}}</span>
    <div>
      <a class="sub-title-placeholder"
         routerLink="/search">{{'folder.placeholder-no-document-sub-title-part-1' | translate}}</a>
      <span class="sub-title-placeholder">&nbsp;{{
        (documentsType === bookmarkDocumentEntityType.ARTICLE
            ? 'folder.placeholder-no-article-sub-title-part-2'
            : 'folder.placeholder-no-admin-doc-sub-title-part-2'
        ) | translate}}</span>
    </div>
  </div>
</ng-template>
