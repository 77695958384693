<div [ngClass]="{
  'h-100': true,
  'folder-document-container': !!entityId === false
  }">
  <div *ngIf="folder && folder.documentsCount > 0 || !!entityId; else placeholderNoDocument" [hidden]="!!entityId">
    <div class="folder-header-container">
      <div class="folder-header">
        <div class="left-part">
          <div class="app-page-tab">
            <button class="left text-uppercase d-inline-flex"
                    [ngClass]="{'active': currentUrl.includes('article')}"
                    (click)="onTabClick('article')">
              <div class="icon-page-tab icon-page-tab--article align-self-center"></div>
              <div class="button-label">{{'header.article-tab' | translate}} ({{nbArticle | insertText: ' ': 3}})</div>
            </button>
            <button class="right text-uppercase d-inline-flex"
                    [ngClass]="{'active': currentUrl.includes('admin-doc')}"
                    (click)="onTabClick('admin-doc')">
              <div class="icon-page-tab icon-page-tab--admin-doc align-self-center"></div>
              <div class="button-label">{{'header.admin-doc-tab' | translate}} ({{nbAdminDoc | insertText: ' ': 3}})
              </div>
            </button>
          </div>
        </div>
        <div class="separation"></div>
        <div class="right-part">
          <div class="dropdown sort-btn">
            <span>{{'search.sort-by' | translate}}</span>
            <button class="btn dropdown-toggle m-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              {{('folder.sort-by-' + currentSort['field'] + '-' + currentSort['dir']) | translate}}
            </button>
            <div class="dropdown-menu">
              <div class="dropdown-item"
                   *ngFor="let sortBy of filterSortList()"
                   (click)="onSortChange(sortBy)"
                   [ngClass]="{'active': sortBy['field'] == currentSort['field'] && sortBy['dir'] == currentSort['dir']}">
                {{('folder.sort-by-' + sortBy['field'] + '-' + sortBy['dir']) | translate}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <router-outlet *ngIf="folder && folder.documentsCount > 0 || !!entityId"></router-outlet>
</div>

<ng-template #placeholderNoDocument>
  <div class="cards-container d-flex flex-column placeholder-font padding-top-20">
    <span class="title-placeholder">{{'folder.placeholder-no-document-title' | translate}}</span>
    <div>
      <a class="sub-title-placeholder"
         routerLink="/search">{{'folder.placeholder-no-document-sub-title-part-1' | translate}}</a>
      <span class="sub-title-placeholder">&nbsp;{{'folder.placeholder-no-document-sub-title-part-2' | translate}}</span>
    </div>
  </div>
</ng-template>
