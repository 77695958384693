<!-- TEXT MODE -->
<div *ngIf="SearchModeMap.get(publicationSource) !== SearchMode.TOPICS || haveTip" class="d-flex search-bar">
  <div class="w-100 position-relative">
    <div class="icon position-absolute" [ngClass]="'icon--'+currentPage"></div>
    <input type="text" id="search-text-input-{{serializedSessionNumber}}" data-cy="admin-doc-keyword-search-text-input"
           [placeholder]="(haveTip ? 'search.search-placeholder': 'search.search-placeholder-impacter') | translate"
           [(ngModel)]="searchText" (ngModelChange)="reset()" (keyup.enter)="onSearch()"/>
    <div *ngIf="searchText" class="icon-search icon-search--close" (click)="searchText=''; onSearch()"></div>
  </div>
  <ng-template #tipSearch>
    <ul class="text-start">
      <li class="pb-3 pt-2">{{'search.tip-message1' | translate}} <strong>{{'search.tip-message2' | translate}}</strong> {{'search.tip-message3' | translate}}</li>
      <li>{{'search.tip-sub-message1' | translate}} <strong>{{'search.tip-sub-message2' | translate}}</strong> {{'search.tip-sub-message3' | translate}} </li>
    </ul>
  </ng-template>
  <div *ngIf="haveTip" class="search-tip d-flex" [ngbTooltip]="tipSearch" tooltipClass="custom-tooltip-search"
       placement="bottom">
    <img src="assets/images/icon-bulb.svg" class="align-self-center" alt="icon-bulb">
    <div class="align-self-center">{{'search.tip' | translate}}</div>
  </div>
  <button class="d-flex" data-cy="admin-doc-keyword-launch-search-btn" (click)="onSearch()">
    <div class="icon-search icon-search--search align-self-center"></div>
    <div class="text-uppercase fw-bold align-self-center">{{'search.search-btn' | translate}}</div>
  </button>
</div>

<!-- TOPICS MODE -->
<div *ngIf="SearchModeMap.get(publicationSource) === SearchMode.TOPICS" class="d-flex search-bar">
  <div appClickOutside class="search-topics-container position-relative"
       (clickOutside)="hideIfOpen()">
    <div class="icon position-absolute icon-inside-input" [ngClass]="'icon--'+currentPage"></div>

    <input class="search-between-topics"
           [ngClass]="{'text-size-null' : !selectedTopics}"
           type="text" id="search-text-topics-input-{{serializedSessionNumber}}"
           appOutlineNone autocomplete="off"
           [placeholder]="!selectedTopics ? '' : 'Sélectionnez un thème de recherche'"
           [(ngModel)]="filteredTopicName"
           (keyup)="onFilterTopicName()"
           (click)="hideOrOpen()"
           data-cy="search-topic-input"
    />

    <img *ngIf="showDropDownMenu" class="dropdownmenu-arrow chevrons" src="./assets/images/icon-chevron-up.svg" alt="icon-chevron-up">
    <img *ngIf="!showDropDownMenu" class="dropdownmenu-arrow chevrons" src="./assets/images/icon-chevron-down.svg" alt="icon-chevron-down">

    <div [hidden]="!topics" class="dropdown-menu"
         [ngClass]="{'show': showDropDownMenu}" aria-labelledby="dropdownTopicsList">
      <div class="dropdown-item" [ngClass]="{'none-topic': !filteredTopicName}"
           (click)="onTopicClicked({})">
        <div class="topic-item h-100 w-100">
          <div class="topic-item-container">
            <div class="topic-item-name fst-italic">
              {{'search.topic-search-none' | translate}}
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="filteredTopics">
        <!-- Custom topics -->
        <div class="dropdown-item-header w-100 d-flex" *ngIf="(topics | filter: 'type': 'custom_topic').length > 0">
          <span class="dropdown-item-header-title align-self-center">
            {{'search.custom-topics-header' | translate | uppercase}}
          </span>
          <span class="dropdown-item-header-counter align-self-center d-flex">
            <span *ngIf="filteredTopics.length < topics.length">
              {{(filteredTopics | filter: 'type': 'custom_topic')?.length + '/'}}
            </span>
            <span>
              {{(topics | filter: 'type': 'custom_topic')?.length}}
            </span>
          </span>
        </div>
        <div class="dropdown-item" [ngClass]="{'selected-topic': isSelectedTopic(topic)}"
             title="{{topic.description}}"
             (click)="onTopicClicked(topic)"
             *ngFor="let topic of filteredTopics | sortText: 'name' | filter: 'type': 'custom_topic'">
          <div class="topic-item h-100 w-100">
            <div class="topic-item-container" data-cy="topic-row-select-item">
              <div class="topic-item-name">
                {{topic.name}}
              </div>
              <div *ngIf="topic.description" class="topic-item-description">
                {{topic.description}}
              </div>
              <div *ngIf="!topic.description" class="topic-item-no-description">
                {{'search.topic-search-no-description' | translate}}
              </div>
            </div>
          </div>
        </div>
        <div class="dropdown-item-no-result w-100 align-self-center" *ngIf="
        (topics | filter: 'type': 'custom_topic').length > 0 &&
        (filteredTopics | filter: 'type': 'custom_topic')?.length === 0">
          <div class="no-result">{{'search.topic-filter-no-result-custom' | translate}}</div>
        </div>
        <!-- Default topics -->
        <div class="dropdown-item-header w-100 d-flex">
          <span class="dropdown-item-header-title align-self-center">{{'search.default-topics-header' | translate | uppercase}}</span>
          <span class="dropdown-item-header-counter align-self-center d-flex">
            <span *ngIf="filteredTopics.length < topics.length">
              {{(filteredTopics | filter: 'type': 'default_topic')?.length + '/'}}
            </span>
            <span>{{(topics | filter: 'type': 'default_topic')?.length}}</span>
          </span>
        </div>
        <div class="dropdown-item" [ngClass]="{'selected-topic': isSelectedTopic(topic)}"
             title="{{topic.description}}"
             (click)="onTopicClicked(topic)"
             *ngFor="let topic of filteredTopics | sortText: 'name' | filter: 'type': 'default_topic'">
          <div class="topic-item h-100 w-100">
            <div class="topic-item-container">
              <div class="topic-item-name">
                {{topic.name}}
              </div>
              <div *ngIf="topic.description" class="topic-item-description">
                {{topic.description}}
              </div>
              <div *ngIf="!topic.description" class="topic-item-no-description">
                {{'search.topic-search-no-description' | translate}}
              </div>
            </div>
          </div>
        </div>
        <div class="dropdown-item-no-result w-100 align-self-center" *ngIf="(filteredTopics | filter: 'type': 'default_topic')?.length === 0">
          <div class="no-result">{{'search.topic-filter-no-result-default' | translate}}</div>
        </div>
      </ng-container>
    </div>

  </div>
  <div class="delete-button-box">
    <img *ngIf="filteredTopicName" class="text-delete-cross chevrons" src="./assets/images/icon-close-circle.svg" alt="icon-close"
         (click)="onTopicClicked({})">
  </div>

</div>
