import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { first, map } from 'rxjs/operators';
import { UserTrackerService } from '../services/tracking/user-tracker.service';


@Injectable()
export class CustomInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private userTrackerService: UserTrackerService,
  ) {
  }

  /** /!\ Core part of tracking - do not touch it or it will break all implemented tracking /!\ */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request)
      .pipe(map(event => {

          // As for now, we only track successful transactions, ie. /w api response <> error.
          if (event instanceof HttpResponse && request.headers.has('is-tracked')) {

            event = event.clone();

            const trackJSON = this.userTrackerService.buildUserTrackerJSON(request, event);

            /** Envoi des informations au service de tracking */
            this.userTrackerService.track(trackJSON)
              .pipe(first())
              .subscribe();

            return event;

          } else {

            return event;

          }
        }
      ));
  }
}

