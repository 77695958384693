<div class="d-flex justify-content-between align-items-center my-2 alert-header" data-cy="item-list-create-alert-header">
  <div class="d-flex align-items-center">
    <h3 class="table-title" id="table-title">
      {{'alert.NB-alerts' | translate}}    </h3>
    <div data-cy="alerts-count-rectangle" class="count-rectangle">
      <p class="color">
        {{territoryWatches?.length ?? 0}}
      </p>
    </div>
  </div>
  <button *ngIf="alertMode"
          class="btn btn-create d-flex"
          data-cy="item-list-create-alert-button"
          appPreventDoubleClick
          (throttledClick)="onCreateAlert()"
          [throttleTime]="2000"
  >
    <div class="align-self-center icon icon--plus-circle"></div>
    <div class="text-uppercase fw-bold align-self-center">{{'alert.create' | translate}}</div>
  </button>
</div>
<div *ngIf="!territoryWatches || !territoryWatches.length" class="fw-bold message-no-item" [ngSwitch]="alertMode">
  <ng-container *ngSwitchCase="true">
    <div>{{'alert.no-alert-setting'  | translate}}</div>
    <div>{{'alert.setting-message' | translate}}</div>
  </ng-container>
  <ng-container *ngSwitchCase="false">
    <div>{{'alert.no-topic-setting' | translate}}</div>
    <div>{{'alert.setting-message-topic' | translate}}</div>
  </ng-container>
</div>
<div></div>


<ng-container *ngIf="territoryWatches && territoryWatches.length > 0">
  <div class="card-board-header ps-xl-0">
    <div class="item">
      <div class="row ">
        <div
          class="col-xl-1  col-lg-1 col-md-1 col-sm-2 d-flex align-items-center Header-list-alert    justify-content-xl-center ps-xl-0 ">
          <span> {{ 'alert.status' | translate }}</span>
        </div>
        <div
          class="col-xl-6  col-lg-6 col-md-4  col-sm-4 d-flex  align-items-center Header-list-alert justify-content-sm-start justify-content-xl-start ps-xl-0 "
          (click)="changeOrder('name')">
          <span> {{ 'alert.name_description'  | translate }} </span>
          <ng-container *ngIf="currentOrder.orderBy === 'name'">
            <ng-container *ngIf="currentOrder.reverse">
              <img src="assets/images/icon-arrow-up-fill.svg" alt="arrow-up">
            </ng-container>
            <ng-container *ngIf="!currentOrder.reverse">
              <img src="assets/images/icon-arrow-down-fill.svg" alt="arrow-down">
            </ng-container>
          </ng-container>
          <ng-container *ngIf="currentOrder.orderBy !== 'name'">
            <img src="assets/images/icon-arrow-both-fill.svg" alt="arrow-both-up-and-down">
          </ng-container>
        </div>
        <div class="col-xl-2  col-lg-3 col-md-4 col-sm-2  d-flex align-items-center" (click)="changeOrder('updatedAt')">
          <div class="Header-list-alert ">
            <span>{{('topic.latest-modification') | translate | uppercase}}</span>
          </div>

          <ng-container *ngIf="currentOrder.orderBy === 'updatedAt'">
            <ng-container *ngIf="currentOrder.reverse">
              <img src="assets/images/icon-arrow-up-fill.svg" alt="arrow-up">
            </ng-container>
            <ng-container *ngIf="!currentOrder.reverse">
              <img src="assets/images/icon-arrow-down-fill.svg" alt="arrow-down">
            </ng-container>
          </ng-container>
          <ng-container *ngIf="currentOrder.orderBy !== 'updatedAt'">
            <img src="assets/images/icon-arrow-both-fill.svg" alt="arrow-both-up-and-down">
          </ng-container>
        </div>

        <div class="col-xl-3 col-lg-2 col-md-1 col-sm-5 d-flex align-self-center"></div>
      </div>
    </div>
  </div>
<div *ngFor="let alert of (currentOrder.orderBy === 'name' ? (territoryWatches | sortText: currentOrder.orderBy : currentOrder.reverse : forceUpdate) :
                                                             (territoryWatches | sortDate: currentOrder.orderBy : currentOrder.reverse : forceUpdate) );
        let i = index;
        let first = first;
        let last = last;
        first as isFirst "
        class="{{ isFirst && alert.isObsolete ? 'isFirst card-board-obsolete  ps-xl-0' :
                                                isFirst ? 'isFirst card-board-first ps-xl-0':
                                                          alert.isObsolete ? 'card-board-obsolete ps-xl-0 ' :
                                                                            'card-board ps-xl-0'}}">
  <div class="item">
    <div class="row alert-item entity-item" data-cy="item-list-create-alert-card">
        <div class="col-xl-1  col-lg-1 col-md-1 col-sm-2 d-flex icon-alert justify-content-xl-center ps-xl-0 " *ngIf="alertMode">

          <div class="align-self-center" *ngIf="!alert.isObsolete">
            <label class="switch checkbox ">
              <input type="checkbox" [checked]="alert?.user?.digest_is_active" (change)="onSelectChange($event, alert.id)">
              <span  class="slider round"
                     [ngbTooltip]="tipAlert" tooltipClass="custom-tooltip-active-alert" placement="top">
            </span>
            </label>

          </div>
          <div  class="align-self-center"  *ngIf="alert.isObsolete">
            <div class="info"
                 [ngbTooltip]="tipAlertObsolete"
                 tooltipClass="custom-tooltip-alert-obsolete"
                 placement="{{ i > 7 ? 'top' : 'bottom' }}"> </div>

          </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-4 col-sm-4 d-flex justify-content-xl-start ps-xl-0">
              <div *ngIf="alertMode && !alert.isObsolete" class="d-table-cell name alert-name">
                <span data-cy="item-list-create-alert-cards-name" [routerLink]="'/alert-form/'+ alert.id">{{alert.name}}</span></div>
              <div *ngIf="alertMode && alert.isObsolete"
                   data-cy="territory-watch-name-obsolete"
                   class="d-table-cell name name-obsolete">
                <span [routerLink]="'/alert-form/'+ alert.id">{{alert.name}}</span>
                  <div class="display-error" *ngIf="alert.isObsolete && alertMode">
            <span>{{ 'obsolete-alert.message-error' | translate }}
            <span class="underLine" [ngbTooltip]="Alert" tooltipClass="custom-tooltip-alerts" placement="{{i>5? 'top' : 'bottom'}}"> {{'obsolete-alert.tooltip-explication' | translate }}</span></span>
                  </div>
              </div>
            <div class="d-table-cell justify-content-start">
              <div class="rename d-flex">
                <button appOpenRenameModal class="d-flex"
                        [item]="alert"
                        [itemIndex]="i"
                        [itemsList]="territoryWatches"
                        [itemType]="'alert'"
                        (itemRenamed)="onRename($event)"
                >
                  <img src="assets/images/pen-icon.svg" class="align-self-center" alt="icon-duplicate">
                  <div class="align-self-center fw-bold">{{'alert.rename' | translate}}</div>
                </button>
              </div>
            </div>
        </div>
      <div class="col-xl-2  col-lg-3 col-md-4 col-sm-2  d-flex align-items-center update-date  justify-content-start">
        <p  class="update" *ngIf="alert.updatedAt">
          {{alert.updatedAt | date:'dd MMMM yyyy':'':'fr' }}
        </p>
      </div>
    <div class="col-xl-3 col-lg-2 col-md-1 col-sm-5 d-flex justify-content-end">
        <ng-container>
          <div *ngIf="!alert.isObsolete && alertMode" class="duplicate d-flex" data-cy="item-list-duplicate-alert-area">
            <button class="btn d-flex align-self-center"
                    data-cy="item-list-duplicate-alert-button"
                    [disabled]="awaitingDuplicationResponse"
                    (click)="duplicateAlert(alert.id)">
              <img src="assets/images/duplicate-icon.svg" class="align-self-center" alt="icon-duplicate">
              <div class="align-self-center fw-bold text-uppercase">{{'alert.duplicate' | translate}}</div>
            </button>
          </div>

          <div *ngIf="!alert.isObsolete && alertMode" class="setting d-flex">
            <button class="d-flex align-self-center" [routerLink]="'/alert-form/'+ alert.id">
              <img src="assets/images/pen-icon.svg" class="align-self-center" alt="icon-settings">
              <div class="align-self-center fw-bold text-uppercase">{{'alert.update' | translate}}</div>
            </button>
          </div>

          <div *ngIf="alert.isObsolete && alertMode"
               class="setting obsolete-setting d-flex">
            <button class="d-flex align-self-center" [routerLink]="'/alert-form/'+ alert.id">
              <img src="../../../assets/images/pen-icon-obsolete.svg" class="align-self-center" alt="icon-settings">
              <div class="align-self-center fw-bold text-uppercase">{{'alert.update-alert' | translate}}</div>
            </button>
          </div>

          <div class="delete d-flex">
            <button class="d-flex align-self-center" data-cy="alert-item-from-list-delete-button"
                    (click)="openDeleteModal(content, alert.id, i)"
                    ngbTooltip="{{'alert.delete' | translate}}"
                    tooltipClass="custom-tooltip-trash"
                    placement="bottom" >
              <img src="assets/images/icon-trash.svg" class="align-self-center icon-block--trash" alt="icon-delete">
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

</ng-container>

<ng-template #duplicationModale>
  <div class="duplication-modale d-table no-select">
    <app-simple-loader class="simple-loader d-table-cell align-middle"
                       [loaderText]="'loader-duplication'"
                       [vertical]="true"></app-simple-loader>
  </div>
</ng-template>


<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title-delete" id="modal-basic-title">
      {{(alertMode ? 'alert.modal-delete-title': 'alert.modal-delete-title-topic') | translate}}
    </h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()">

    </button>
  </div>
  <div class="modal-body-delete">
    <div class="fw-bold message">
      {{(alertMode ? 'alert.modal-delete-body-title': 'alert.modal-delete-body-title-topic') | translate}}</div>
    <div class="hint" *ngIf="alertMode">
      <div class="fw-bold">{{'alert.hint' | translate}}</div>
      <div>{{'alert.modal-hint-message' | translate}} <img src="assets/images/bitmap.jpg" alt="bitmap"></div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="cancel fw-bold" (click)="modal.close()">{{'alert.cancel' | translate}}</button>
    <button data-cy="alert-modal-delete-confirmation-button" type="button" class="btn confirm-delete d-flex" (click)="onDelete()">
      <div class="icon icon--trash align-self-center"></div>
      <div class="text-uppercase fw-bold align-self-center">{{(alertMode ? 'alert.delete': 'alert.delete-topic') | translate}}</div>
    </button>
  </div>
</ng-template>

<ng-template #tipAlert>
  <div>
    <div class="warning-title" >{{'alert.tooltip-description' | translate}}</div>
  </div>
</ng-template>
<ng-template #tipAlertObsolete>
  <div class="text-xl-left ">
    <div class="warning-title-obsolete">{{ 'obsolete-alert.tooltip-description' | translate }} </div>
  </div>
</ng-template>
<ng-template #Alert>
  <div>
    <p  class="warning-title header"> {{ 'obsolete-alert.perimeter-warning_title' | translate }}  </p>
    <ul class="text-xl-left ">
      <li class="warning-title" > {{ 'obsolete-alert.empty-perimeter' | translate }} </li>
      <li class="warning-title"> {{ 'obsolete-alert.empty-topic' | translate }}</li>
    </ul>
    <div  class="warning-title header"> {{ 'obsolete-alert.why' | translate }}  </div>
    <p class="warning-title" > {{ 'obsolete-alert.reasons' | translate }}</p>

    <ul class="text-xl-left ">
      <li class="warning-title" > {{ 'obsolete-alert.first-reason' | translate }} </li>
      <li class="warning-title">  {{ 'obsolete-alert.second-reason' | translate }}</li>
      <li class="warning-title">  {{ 'obsolete-alert.third-reason' | translate }}</li>
    </ul>

  </div>
</ng-template>

<ng-template #errorInDuplication>
  <app-toast-error
    [title]="'alert.toast-error-duplication-title'"
    [text]="'alert.toast-error-duplication-text'">
  </app-toast-error>
</ng-template>
