import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-forgot-password-expired',
  templateUrl: './forgot-password-incorrect.component.html',
  styleUrls: ['./forgot-password-incorrect.component.scss']
})
export class ForgotPasswordIncorrectComponent implements OnInit {

  form!: FormGroup;
  loading = false;
  isError = false;

  ngOnInit(): void {
  }

}
