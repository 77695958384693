import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { finalize, first } from 'rxjs/operators';
import { MustMatch } from '../../../shared/helpers/must-match.validator';
import { CustomValidators } from '../../../shared/helpers/custom-validators.validator';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  form!: FormGroup;
  loading = false;
  submitted = false;
  fieldTextType1 = false;
  fieldTextType2 = false;
  userLeftInputConfirmPassword = false;
  resetSuccess = false;
  resetToken = null;

  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit(): void {

    // Récupération du token
    this.route.queryParams
      .subscribe((params) => {

        if (params['token']) {

          this.resetToken = params['token'];
          this.authenticationService.checkResetPasswordToken(this.resetToken)
            .subscribe(
              () => {},
              (error) => {
                switch (error) {
                  // Error 403 - token is present but expired
                  case 'FORBIDDEN' : {
                    this.router.navigate(['/login/reset-password-expired']).then();
                    break;
                  }
                  // Error 404 (not found) / 500 or others - token is absent
                  default : {
                    this.router.navigate(['/login/reset-password-incorrect']).then();
                    break;
                  }
                }
              });

        } else {

          this.router.navigate(['/login']).then();

        }

      });

    this.form = this.formBuilder.group({
      password: [null, [
        // 1\. Password is required
        Validators.required,
        // 2\. Min length of 8 characters
        Validators.minLength(8),
        // 3\. At least one special character
        CustomValidators.patternValidator(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, { hasSpecialCharacters: true}),
        // 4\. At least one Lower case
        CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true}),
        // 5\. At least one number
        CustomValidators.patternValidator(/\d/, { hasNumber: true}),
        // 6\. At least one Upper case
        CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true})
      ]
      ],
      confirmPassword: [null, Validators.required],
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });

  }

  // convenience getter for easy access to form fields
  get f() {
    return this.form.controls;
  }

  toggleFieldTextType1() {
    this.fieldTextType1 = !this.fieldTextType1;
  }

  toggleFieldTextType2() {
    this.fieldTextType2 = !this.fieldTextType2;
  }

  confirmationPasswordIsWrong() {
    if (this.f['confirmPassword'].value) {
      return (
        (this.f['password'].value !== this.f['confirmPassword'].value) &&
        (this.f['password'].value !== '')
      );
    } else {
      return false;
    }
  }

  onSubmit() {
    this.submitted = true;
    this.fieldTextType1 = false;
    this.fieldTextType2 = false;

    // STOP HERE if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.loading = true;

    this.authenticationService.resetPassword(this.f['password'].value, this.resetToken || '')
      .pipe(first())
      .pipe(
        finalize(
          () => this.loading = false
        ))
      .subscribe(
        () => {
          // Success page
          this.resetSuccess = true;
        },
        (error) => {
          switch (error) {
            // Error 403 - token is present but expired
            case 'FORBIDDEN' : {
              this.router.navigate(['/login/reset-password-expired']).then();
              break;
            }
            case 'NOT FOUND' : {
              this.router.navigate(['/login/reset-password-expired']).then();
              break;
            }
            // Error 404 (not found) / 500 or others - token is absent
            default : {
              console.error(('Une erreur est survenue...404 ou 500'));
              break;
            }
          }
        });

  }

}
