import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

export enum PageRepresentationTypes {
  THREE_DOTS = 'three-dots',
  ONE_DOT = 'one-dot',
  FIRST_OR_LAST_PAGE = 'first-or-last-page',
  DEFAULT = 'page-number'
}
export interface DbPageRepresentation {
  page: number;
  style: PageRepresentationTypes;
}
interface Spread {
  previousPage: number;
  nextPage: number;
}

@Component({
  selector: 'app-admin-doc-search-pagination',
  templateUrl: './admin-doc-search-pagination.component.html',
  styleUrls: ['./admin-doc-search-pagination.component.scss']
})
export class AdminDocSearchPaginationComponent implements OnInit {
  @Input() $occurrencePages!: Observable<Array<boolean>>;
  @Input() currentPage = 1;
  @Input() $currentPage!: ReplaySubject<number>;
  @Output() pageClicked = new EventEmitter<number>();

  PageRepresentationTypes = PageRepresentationTypes;
  pagesRepresentationArray = new Array<{page: number, style: PageRepresentationTypes, offset: number, spread?: Spread}>();
  occurrencePagesCounter = 0;

  // behavior management of pagination component
  @ViewChild('fixedContainerScrollable') paginationScrollableElement!: ElementRef;
  totalPaginationWidth = 0;
  maxWidthPaginationContainer = 342;
  pageRpzWidth = 34;
  oneDotRpzWidth = 5.6;
  threeDotsRpzWidth = 16.8;
  displayFadingDivs = { left: false, right: false };

  constructor() {
  }

  ngOnInit(): void {

    /** On occurrencePages reception - Method to generate pagesRepresentationArray */
    this.$occurrencePages
      .subscribe((occurrencePages) => {
        // Evaluate if at least one page contains occurrences.
        this.occurrencePagesCounter = occurrencePages?.filter(elm => elm === true)?.length;

        // Initial value of empty array.
        this.pagesRepresentationArray = [];

        let offset = 0; // cumulative offset.
        let count = 0; // counter for dot(s).

        occurrencePages?.forEach((page, index) => {
          /** First page management */
          if (index === 0) {
            this.pagesRepresentationArray.push({page: 1, style: PageRepresentationTypes.FIRST_OR_LAST_PAGE, offset});
          }

          /** PAGE WITH OCCURRENCE */
          if (page) {

            /** Dot(s) management */
            [count, offset] = this.manageDotRepresentationAndReturnUpdatedArgs(count, offset); // updated values
            offset += this.pageRpzWidth; // updatedOffset = dot.s? width + page representation width

            /** First | last | other Page management */
            if (index === 0) { // If it is first page with occ -> change style
              const firstPage = this.pagesRepresentationArray.find(elem => elem.page === 1)
              if (firstPage) firstPage.style = PageRepresentationTypes.DEFAULT;
            } else if (index === (occurrencePages?.length - 1)) { // If it is last page with occ -> push it
              this.pagesRepresentationArray.push({page: occurrencePages?.length, style: PageRepresentationTypes.DEFAULT, offset});
            } else { // All other cases (push the page)
              this.pagesRepresentationArray.push({page: index + 1, style: PageRepresentationTypes.DEFAULT, offset});
            }

            /** PAGE WITHOUT OCCURRENCE */
          } else {

            /** Last page management */
            if (index === (occurrencePages?.length - 1) && index !== 0) {
              /** Dot(s) management before adding the last page */
              [count, offset] = this.manageDotRepresentationAndReturnUpdatedArgs(count, offset);
              offset += this.pageRpzWidth; // updatedOffset = dot.s? width + page representation width
              this.pagesRepresentationArray
                .push({page: occurrencePages?.length, style: PageRepresentationTypes.FIRST_OR_LAST_PAGE, offset});
            }

            /** Count increment management */
            if (index !== 0) { // increment only if not first - to avoid one dot between page one and page two.
              count++;
            }
          }
        });

        this.manageDotsSpreads();
        this.updateTotalPaginationWidth();

      });

    /** Reaction on current page change from pdf viewer */
    this.$currentPage
      .subscribe((page) => {
          this.currentPage = page;
          const representation = this.pagesRepresentationArray.find(elem => elem.page === page);
          if (representation) {
            this.scrollOnClick(representation);
          } else {
            const dotRepresentation = this.pagesRepresentationArray
              .find(elem => (elem.spread?.nextPage ?? 0 > page) && (elem.spread?.previousPage ?? 0 < page));
            if (dotRepresentation) {
              this.scrollOnClick(dotRepresentation);
            }
          }
        });
  }

  /** Method to manage the adding of dot representation inside pagesRepresentationArray
   * and return nex value of the counter */
  manageDotRepresentationAndReturnUpdatedArgs(previousCount: number, previousOffset: number): [count: number, offset: number] {
    let count = previousCount;
    let offset = previousOffset;
    if (count > 0 && count <= 2) { // CASE one dot
      offset += this.oneDotRpzWidth;
      this.pagesRepresentationArray.push({page: 0, style: PageRepresentationTypes.ONE_DOT, offset });
      count = 0;
    } else if (count >= 3) { // CASE three dots
      offset += this.threeDotsRpzWidth;
      this.pagesRepresentationArray.push({page: 0, style: PageRepresentationTypes.THREE_DOTS, offset });
      count = 0;
    } else { // do nothing
    }
    return [count, offset];
  }

  /** Method setting spread to dots representations. A spread is the pages segment (or gap) represented by the dot(s).
   * For instance a threeDots representing the gap between p12 and p17 will have a spread = {12, 17} */
  manageDotsSpreads() {
    for (let i = 0 ; i < this.pagesRepresentationArray.length ; i ++) {
      if ((this.pagesRepresentationArray[i].style === PageRepresentationTypes.ONE_DOT) ||
          (this.pagesRepresentationArray[i].style === PageRepresentationTypes.THREE_DOTS)) {

        this.pagesRepresentationArray[i].spread = {
          previousPage: this.pagesRepresentationArray[i - 1].page,
          nextPage: this.pagesRepresentationArray[i + 1].page
        };
      }
    }
  }

  /** Method to update the total size (in px) of the pagination main container */
  updateTotalPaginationWidth() {
    this.totalPaginationWidth =
      this.pagesRepresentationArray
        .filter(elm => (elm.style === PageRepresentationTypes.FIRST_OR_LAST_PAGE) ||
          (elm.style === PageRepresentationTypes.DEFAULT)).length * this.pageRpzWidth
      +
      this.pagesRepresentationArray
        .filter(elm => (elm.style === PageRepresentationTypes.ONE_DOT)).length * this.oneDotRpzWidth
      +
      this.pagesRepresentationArray
        .filter(elm => (elm.style === PageRepresentationTypes.THREE_DOTS)).length * this.threeDotsRpzWidth;

    this.manageDisplayOfFadingDivs();
  }

  /** Method triggered when a click on a page occurred */
  onClickPage(representation: {page: number, style: PageRepresentationTypes, offset?: number}) {
    // only for page ish representations
    if (representation.style === PageRepresentationTypes.DEFAULT || representation.style === PageRepresentationTypes.FIRST_OR_LAST_PAGE) {
      this.pageClicked.emit(representation.page);
    }
    this.scrollOnClick(representation);
  }

  /** Previous occurrence page button management */
  onPreviousOccurrencePage() {
    // Searching for elements left to the current page
    const leftOccPagesRpz = this.pagesRepresentationArray.filter((elm) => {
      return (elm.style === PageRepresentationTypes.DEFAULT) && (elm.page < this.currentPage);
    });
    if (leftOccPagesRpz?.length) {
      // take last element of interval
      const representation = leftOccPagesRpz.pop();
      if (representation) {
        this.pageClicked.emit(representation.page);
        this.scrollOnClick(representation);
      }
    } else {
      // go to last page of all occurrence pages
      const representationLastOccPage =
        this.pagesRepresentationArray.filter((elm) => (elm.style === PageRepresentationTypes.DEFAULT)).pop();
      if (representationLastOccPage) {
        this.pageClicked.emit(representationLastOccPage.page);
        this.scrollOnClick(representationLastOccPage);
      }
    }
  }

  /** Next occurrence page button management */
  onNextOccurrencePage() {
    // Searching for elements right to the current page
    const rightOccPagesRpz = this.pagesRepresentationArray.filter((elm) => {
      return (elm.style === PageRepresentationTypes.DEFAULT) && (elm.page > this.currentPage);
    });
    if (rightOccPagesRpz?.length) {
      // take last element of intervalle
      this.pageClicked.emit(rightOccPagesRpz[0].page);
      const representation = rightOccPagesRpz[0];
      this.scrollOnClick(representation);

    } else {
      // go to first page of all occurrence pages
      const representationFirstOccPage =
        this.pagesRepresentationArray.filter((elm) => (elm.style === PageRepresentationTypes.DEFAULT))[0];
      this.pageClicked.emit(representationFirstOccPage.page);
      this.scrollOnClick(representationFirstOccPage);
    }
  }

  /** Method taking care of the movement of scrollable part of the pagination component */
  scrollOnClick(representation: {page: number, style: PageRepresentationTypes, offset?: number}) {
    const offset = representation.offset;
    const midLength = this.maxWidthPaginationContainer / 2 ;
    if (offset) offset < midLength ?
      this.paginationScrollableElement.nativeElement.scrollLeft = 0 :
      this.paginationScrollableElement.nativeElement.scrollLeft = offset - midLength;

    this.manageDisplayOfFadingDivs();
  }

  /** Method to trackBy ngFor representationPages */
  trackByPage(index: number, element: DbPageRepresentation) {
    return `${element.page}-${element.style}`;
  }

  /** Method to evaluate if fading divs (on extremities of the pagination component) need to be displayed or not */
  manageDisplayOfFadingDivs() {
    if (this.totalPaginationWidth > this.maxWidthPaginationContainer) {
      this.displayFadingDivs = {left: true, right: true};
      const leftScroll = this.paginationScrollableElement?.nativeElement?.scrollLeft;
      if (leftScroll === 0) {
        this.displayFadingDivs.left = false;
      }
      if (leftScroll >= (this.totalPaginationWidth - this.maxWidthPaginationContainer)) {
        this.displayFadingDivs.right = false;
      }
    } else {
      this.displayFadingDivs = {left: false, right: false};
    }
  }

}

