<app-header></app-header>
<div class="card-board">
  <div class="row">
    <div class="col-8 content">
      <div class="title text-uppercase">{{'territory_search.choose_territory' | translate}}</div>
      <div class="sub-title">{{'territory_search.sub-label' | translate}}</div>
      <div appClickOutside
        class="search-container d-flex"
           (clickOutside)="hideIfOpen()"
        >
        <app-simple-loader *ngIf="showSimpleLoader"></app-simple-loader>
         <div class="search d-flex">
           <div *ngIf="!showSimpleLoader" class="position-relative dropdown" [ngClass]="{'show': show}">
             <input appOutlineNone class="search-input" type="text"
                    [(ngModel)]="territoryName" (ngModelChange)="search($event)"
                    (click)="hideOrOpen()"
                    id="dropdownList-{{serializedSessionNumber}}" autocomplete="off"/>
             <div class="icon position-absolute" (click)="hideOrOpen()"  aria-haspopup="true" aria-expanded="false"></div>
             <div [hidden]="!filteredList.length" class="dropdown-menu no-select" style="margin-top: -52px;" [ngClass]="{'show': show}" aria-labelledby="dropdownList">
               <ng-container *ngFor="let territory of filteredList ; let i=index">
                 <div class="dropdown-item" id="dropdown-item-{{i}}"
                      [ngClass]="{'element-list-active': activeDepartmentIndex === i}"
                      appClickOrEnterConfirmation
                      [itemsList]="filteredList"
                      [index]="i"
                      [activeIndex]="activeDepartmentIndex"
                      (clickMouseOrPressEnter)="onSelectOrEnterPressed(territory)"
                      (arrowUpOrDown)="onArrowKeyPress($event,'dropdownList-' + serializedSessionNumber)"
                      (mouseEnters)="onMouseEnter($event)"
                 >
                   <span> {{territory.code}} - {{territory.name}} </span>
                 </div>
               </ng-container>
             </div>
           </div>
           <button *ngIf="!showSimpleLoader"
                   class="fw-bold text-uppercase"
                   (click)="onSubmitTerritory()"
                   [disabled]="!selectedUid"
                   [ngClass]="selectedUid ? 'focus' : 'inactive'">
             {{'territory_search.submit' | translate}}
           </button>
         </div>
       </div>
     </div>
     <div class="col-4 d-flex justify-content-end">
       <img src="assets/images/map-france.svg" alt="map-france">
     </div>
   </div>
 </div>

