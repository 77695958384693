<div class="form-steps-container align-middle no-select">

  <div class="h-100 d-flex">
    <ng-content select="[topLeftPart]" class="cancel-element"></ng-content>
  </div>

  <div class="d-flex justify-content-center middle-part">
    <div class="steps-container">

      <div class="funnel-context-container d-table">
        <div class="funnel-icon d-table-cell align-middle">
          <img src="{{funnelIconUrl}}" alt="funnel-img">
        </div>
        <div [style.padding-left]="(funnelContext === FunnelContextEnum.CREATION) ? '12px' : '0'" class="text d-table-cell align-middle">
          {{ 'alert.' + funnelContext | translate | uppercase }}
        </div>
      </div>

      <ng-container *ngFor="let step of steps; let i = index; trackBy: trackByStep">

        <div *ngIf="i !== 4"
             [ngClass]="{'success': datumRetrieved && isSuccessStyleList[i],
                       'errored': datumRetrieved && isErroredStyleList[i] && !isSuccessStyleList[i] && (currentStep?.stepIndex !== step.stepIndex),
                       'current': currentStep?.stepIndex === step.stepIndex,
                       'enabled': datumRetrieved && isEnabledStyle[i] && (currentStep?.stepIndex !== step.stepIndex) && !isSuccessStyleList[i] && !isErroredStyleList[i],
                       'other': (currentStep?.stepIndex !== step.stepIndex) && !isSuccessStyleList[i] && !isErroredStyleList[i] && !isEnabledStyle[i]
                  }">

          <div (click)="onStepClick(step)"
               class="step stepper-button d-flex"
               [ngClass]="{ 'step-complete': step?.isComplete,
                          'step-incomplete': currentStep && (currentStep.stepIndex < step.stepIndex) && !isEnabledStyle[i]}">

            <div class="step-bubble align-self-center">
              <img
                *ngIf="datumRetrieved && isErroredStyleList[i] && !isSuccessStyleList[i] && (currentStep?.stepIndex !== step.stepIndex) ; else indexLabel"
                src="assets/images/danger-500-icon.svg" alt="danger-icon" class="danger-500">

              <ng-template #indexLabel>
                <div class="align-self-center">
                  {{ step.stepIndexLabel }}
                </div>
              </ng-template>
            </div>

            <div class="step-name align-self-center">
              {{ step.stepName | translate }}
            </div>

          </div>

        </div>

      </ng-container>


    </div>
  </div>

  <div class="steps-top-right-part">

    <!--Aperçu de l'alerte - dernière étape traitée séparément -->
    <div data-cy="digest-overview-button" class="last-step-container d-flex justify-content-center align-items-center">
      <div [ngClass]="{'overview-button': true,
                       'overview-selected': currentStep?.stepIndex === 4,
                       'overview-enabled': datumRetrieved && isEnabledStyle[4] && (currentStep?.stepIndex !== 4) && !isSuccessStyleList[4] && !isErroredStyleList[4],
                      }"
           ngbTooltip="{{'alert.overview-step-tooltip' | translate}}">
        <div (click)="onStepClick((steps ?? [])[4])"
             class="step d-flex"
             [ngClass]="{'step-complete': ((steps ?? [])[4])?.isComplete,
                         'step-incomplete': currentStep && (currentStep.stepIndex < 4) && !isEnabledStyle[4]}">
          <ex-icon-and-text [badgeColor]="'none'"
                            [iconName]="(currentStep?.stepIndex === 4) ? 'eye-basic-800' : 'eye-primary-500'"
                            isBadge
                            [classIcon]="'w-100 h-100'"
                            [text]="''">
          </ex-icon-and-text>

        </div>
      </div>
    </div>

    <!--Enregistrer l'alerte-->
    <ng-content select="[topRightPart]"></ng-content>
  </div>
</div>

