import { SearchHelper } from '../../shared/helpers/search.helper';
import { ApiService } from '../../shared/services/api/api.service';
import { first } from 'rxjs/operators';
import { TerritoryForAccountInfo } from '../../models/territory/territory-utils';

const EXCLUDED_DEPARTMENTS = ['971', '972', '973', '974', '975', '976', '977', '978', '986', '987', '988']

export abstract class TerritorySearch {
  territoryName!: string;
  selectedUid!: string;
  territoriesList: Array<TerritoryForAccountInfo> = [];
  filteredList: Array<TerritoryForAccountInfo> = [];
  activeDepartmentIndex = 0;
  show = false;
  showSimpleLoader = false;

  // Boolean used to prevent triggering of (mouseenter) when using arrow key navigation.
  isMouseHoverEventAllowed = true;

  protected constructor(protected apiService: ApiService) {
    this.showSimpleLoader = true;
    this.apiService.exStatistics.AccountInfoReplaySubject
      .pipe(first())
      .subscribe((accountInfo) => {
        this.showSimpleLoader = false;
        this.territoriesList = accountInfo.territories.filter((t) => !EXCLUDED_DEPARTMENTS.includes(t.code));
        this.filteredList = JSON.parse(JSON.stringify(this.territoriesList));
      });
  }

  search(value: string) {
    this.show = true;
    this.selectedUid = '';
    this.filteredList = this.territoriesList.filter((v: TerritoryForAccountInfo) => SearchHelper.searchRegExp(v.code + v.name, value));
    this.activeDepartmentIndex = 0;
  }

  hideOrOpen() {
    this.show = !this.show;
  }

  hideIfOpen() {
    if (this.show) {
      this.show = !this.show;
    }
  }

  onItemClicked(territory: TerritoryForAccountInfo, disabled?: boolean) {
    if (disabled) {
      return;
    }
    this.hideIfOpen();
    this.territoryName = `${territory.code} - ${territory.name}`;
    this.selectedUid = territory.kind + territory.code;
  }

  onMouseEnter(indexItemHovered: number) {
    if (this.isMouseHoverEventAllowed) {
      this.activeDepartmentIndex = indexItemHovered;
    }
  }

  onArrowKeyPress(isArrowDownPressed: boolean, inputId: string) {
    document.getElementById(inputId)?.focus();
    if (!this.show) {
      this.show = true;
      return;
    }

    // Blocking mouseHover event for 100 ms.
    this.isMouseHoverEventAllowed = false;
    setTimeout(() => this.isMouseHoverEventAllowed = true, 100);

    setTimeout(() => {
      this.updateActiveDepartmentIndex(isArrowDownPressed);

      const element = document.querySelector('#dropdown-item-' + this.activeDepartmentIndex);
      if (element) {
        element.scrollIntoView({block: 'nearest', inline: 'nearest'});
      }
    }, 10);

  }

  updateActiveDepartmentIndex(isArrowDownPressed: boolean) {
    const index = this.activeDepartmentIndex;
    this.activeDepartmentIndex = isArrowDownPressed ? // cases DOWN or UP on vertical dropdown list.
      (index + 1 >= this.filteredList.length) ? 0 : (index + 1) : // DOWN > if at the end, go first elem, else increment.
      (index - 1 === -1) ? (this.filteredList.length - 1) : (index - 1); // UP > if at the begining, go last elem, else decrement.
  }

  checkItemSelected(any: any) {
    // In general case, only onne item can be selected (e.g. territory on search home page) >> so returns false.
    // This method has to be redefined on extended class allowing multiple selection.
    return false;
  }
}
